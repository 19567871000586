import {useLocation} from "react-router-dom";
import React from 'react';
import {DEFAULT_CITY} from "./const";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function useCity() {
    const query = useQuery();
    return  parseInt(query.get("cityId")) || DEFAULT_CITY;
}

export {useQuery, useCity};