
import {LAT_INDEX, LNG_INDEX} from "./const";
import _ from "lodash";

function latLngIsEqual(a, b) {
    return a[LAT_INDEX] === b[LAT_INDEX] && a[LNG_INDEX] === b[LNG_INDEX]
}

const findCityCenter = (cityList, cityId) => {
    return _.find(cityList, {id: cityId}).center;
}

const findObjectsByLatLng = (latLng, geoObjects) => {
    const objects = [];
    geoObjects.forEach(geoObject => {
        if (latLngIsEqual([geoObject.lat, geoObject.lng], latLng)) {
            objects.push(geoObject)
        }

        if (geoObject.hasOwnProperty('addressData')
            && latLngIsEqual([geoObject.addressData.lat, geoObject.addressData.lng], latLng)) {
            objects.push(geoObject)
        }
    })
    return objects;
}

export {latLngIsEqual, findCityCenter, findObjectsByLatLng}