import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createRoute, fetchRoutes, updateRoute} from "./routeApi";
import _ from 'lodash'

const initialState = {
    drivesInRoutes: [],
    saveRouteInProcess: false,
    routeInSaveProcess: [],
    routeIds: [],
    loadRoutesInProcess: false
};

export const loadRoutesAsync = createAsyncThunk(
    'drycleaning/routes',
    async (params= {}) => {
        const response = await fetchRoutes(params);
        return response.data;
    }
);

export const createRouteAsync = createAsyncThunk(
    'drycleaning/route/create',
    async (params= {}) => {
        const response = await createRoute(params);
        return response.data;
    }
);

export const updateRouteAsync = createAsyncThunk(
    'drycleaning/route/update',
    async (params= {}) => {
        const response = await updateRoute(params);
        return response.data;
    }
);


export const routeSlice = createSlice({
    name: 'route',
    initialState,
    reducers: {
        addRouteInSaveProcess(state, action) {
            state.routeInSaveProcess = action.payload
        },
        updateRouteIdInRoutesInSaveProcess(state, action) {
            state.routeInSaveProcess.forEach((driveInRouteInSaveProcess) => {
                const driveInRoute = _.find(state.drivesInRoutes, {drive: driveInRouteInSaveProcess.drive});
                driveInRoute.route_id = action.payload.route_id;
            })
        },
        clearRouteInSaveProcess(state, action) {
            state.routeInSaveProcess = [];
        },
        updateDriveInRoute(state, action) {
            const driveInRouteInState = _.find(state.drivesInRoutes, (driveInRoute) => {
                return driveInRoute.drive.id === action.payload.drive.id
            })
            if (typeof driveInRouteInState !== "undefined") {
                _.each(action.payload.attributes, (value, key) => {
                    driveInRouteInState.drive[key] = value;
                })
            }
        },
        addToRoute: (state, action) => {
            const payload = action.payload;
            const exist = _.find(state.drivesInRoutes, function (driveInRoute) {
                return _.isEqual(driveInRoute.drive.id, payload.drive.id)
            });

            if (typeof exist !== "undefined") {
                return;
            }

            if (payload.courier) {
                state.drivesInRoutes.push(payload);
            }
        },
        removeFromRoute(state, action) {
            let driveInRouteInState = _.find(state.drivesInRoutes, function (driveInRoute) {
                return _.isEqual(driveInRoute.drive.id, action.payload.drive.id)
            });
            if (typeof driveInRouteInState !== "undefined") {
                state.drivesInRoutes = _.without(state.drivesInRoutes, driveInRouteInState);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadRoutesAsync.pending, (state, action) => {
                state.loadRoutesInProcess = true;
            });
        builder
            .addCase(loadRoutesAsync.rejected, (state, action) => {
                state.loadRoutesInProcess = false;
            });
        builder
            .addCase(loadRoutesAsync.fulfilled, (state, action) => {
                state.loadRoutesInProcess = false;
                state.drivesInRoutes = action.payload;
                state.routeIds = [];
                _.each(state.drivesInRoutes, (driveInRoute) => {
                    const routeId = _.find(state.routeIds, {id: driveInRoute.route_id});
                    if (typeof routeId === "undefined") {
                        state.routeIds.push({employeeId: driveInRoute.courier, id: driveInRoute.route_id})
                    }
                })
            });
        builder
            .addCase(createRouteAsync.pending, (state, action) => {
                state.saveRouteInProcess = true;
            });
        builder
            .addCase(updateRouteAsync.pending, (state, action) => {
                state.saveRouteInProcess = true;
            });
        builder
            .addCase(createRouteAsync.fulfilled, (state, action) => {
                state.saveRouteInProcess = false;
                const employeeId = state.routeInSaveProcess[0]?.courier
                if (typeof employeeId !== "undefined" || employeeId !== null) {
                    state.routeIds.push({employeeId: employeeId, id: action.payload.route_id})
                }
                routeSlice.caseReducers.updateRouteIdInRoutesInSaveProcess(state, action)
                routeSlice.caseReducers.clearRouteInSaveProcess(state, action)
            });
        builder
            .addCase(createRouteAsync.rejected, (state, action) => {
                state.saveRouteInProcess = false;
                routeSlice.caseReducers.clearRouteInSaveProcess(state, action)
                alert(action.payload)
            });
        builder
            .addCase(updateRouteAsync.fulfilled, (state, action) => {
                state.saveRouteInProcess = false;
                routeSlice.caseReducers.updateRouteIdInRoutesInSaveProcess(state, action)
                routeSlice.caseReducers.clearRouteInSaveProcess(state, action)
            });
        builder
            .addCase(updateRouteAsync.rejected, (state, action) => {
                state.saveRouteInProcess = false;
                routeSlice.caseReducers.clearRouteInSaveProcess(state, action)
                alert(action.payload)
            });
    },
});

export const selectDrivesInRoutes = (state) => state.drycleaningRoute.drivesInRoutes;
export const selectSaveRouteInProcess = (state) => state.drycleaningRoute.saveRouteInProcess;
export const selectRouteIds = (state) => state.drycleaningRoute.routeIds;
export const selectLoadRoutesInProcess = (state) => state.drycleaningRoute.loadRoutesInProcess;
export const {addToRoute, removeFromRoute, addRouteInSaveProcess, updateDriveInRoute} = routeSlice.actions;

export default routeSlice.reducer;