import {combineReducers, configureStore} from '@reduxjs/toolkit';
import employeeReducer from '../features/employee/employeeSlice';
import drycleaningEmployeeReducer from '../features/drycleaning/employee/employeeSlice';
import cleaningEmployeeReducer from '../features/cleaning/employee/employeeSlice';
import drycleaningMapObjectReducer from '../features/drycleaning/object/drycleaningMapObjectSlice';
import cleaningMapObjectReducer from '../features/cleaning/object/cleaningMapObjectSlice';
import drycleaningFilterReducer from '../features/drycleaning/filter/filterSlice';
import cleaningFilterReducer from '../features/cleaning/filter/filterSlice';
import drycleaningRouteReducer from '../features/drycleaning/route/routeSlice';
import cleaningRouteReducer from '../features/cleaning/route/routeSlice';
import drycleaningCentralPanelReducer from "../features/drycleaning/panel/centralPanelSlice";
import cleaningCentralPanelReducer from "../features/cleaning/panel/centralPanelSlice";
import wsReducer from '../features/ws/wsSlice'

export const store = configureStore({
  reducer: {
    employee: combineReducers({employeeReducer, drycleaningEmployeeReducer, cleaningEmployeeReducer}),
    drycleaningMapObject: drycleaningMapObjectReducer,
    cleaningMapObject: cleaningMapObjectReducer,
    drycleaningFilter: drycleaningFilterReducer,
    cleaningFilter: cleaningFilterReducer,
    drycleaningRoute: drycleaningRouteReducer,
    cleaningRoute: cleaningRouteReducer,
    drycleaningCentralPanel: drycleaningCentralPanelReducer,
    cleaningCentralPanel: cleaningCentralPanelReducer,
    ws: wsReducer
  }
});
