import moment from "moment";

const courierIsLate = (driveInRoute) => {
    const start = moment(driveInRoute.drive.date + ' ' + driveInRoute.drive.time.split(' - ')[1], 'DD-MM-YYYY HH:mm');
    const end = moment().subtract('10', 'minutes');
    return moment.duration(start.diff(end)).asMinutes() <= 10
        && driveInRoute.drive.allowedChange === true
        && driveInRoute.drive.isTransportation === false;
}

export {courierIsLate}