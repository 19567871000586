import React from 'react';
import './App.css';
import DrycleaningApp from './features/drycleaning/App'
import CleaningApp from './features/cleaning/App'
import {Route, BrowserRouter, Routes} from "react-router-dom";
import ZoneEditor from "./features/editor/geo/GeoEditor";
import {CITY_LIST} from "./app/utils/const";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Routes>
                    <Route path={"/drycleaning/map"} element={<DrycleaningApp/>}/>
                    <Route path={"/cleaning/map"} element={<CleaningApp/>}/>
                    <Route path={"/cleaning/zones"} element={<ZoneEditor categoriesApiUrl={'/cleaning/job/api/v1/category'} serviceId={2} cityList={CITY_LIST}/>}/>
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
