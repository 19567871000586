import styles from "./Window.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

function Info(props) {
    if (props.show) {
        // const container = document.getElementById('window-container')
        // if (container !== null) {
        //     container.scroll({top: 0, left: 0})
        // }
        return (
            <div id={'window-container'} style={{
                top: typeof props.y === "function" ? props.y() : props.y,
                right: typeof props.x === "function" ? props.x() : props.x,
                width: typeof props.width === "function" ? props.width() : props.width,
            }}
                 className={styles.Window}>
                <span className={styles.CloseIcon} onClick={props.onClick}>
                    <FontAwesomeIcon icon={faClose}/>
                </span>
                {props.children}
            </div>
        );
    }
    return null;
}

export default Info;