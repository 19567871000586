import axios from "axios";
import {useEffect, useState} from "react";
import {Input} from "@material-ui/core";
import {Alert, Col, Row} from "react-bootstrap";
import styles from './Content.module.css'
import _ from 'lodash'

function DistributionLog() {

    const [orderId, setOrderId] = useState('');
    const [driveId, setDriveId] = useState('');
    const [log, setLog] = useState([])
    const [isGroup, setIsGroup] = useState(false)

    const searchRequest = (endpoint, params) => {
        setLog([])
        axios.get('/lsystem/api/v1/drive-in-route-log' + '/' + endpoint, {
            params: Object.assign(params)
        }).then((response) => {
            setLog(response.data);
        });
    }

    useEffect(() => {
        if (orderId) {
            searchByOrderId()
        } else {
            searchByDriveId()
        }
    }, [orderId, driveId])

    const searchByDriveId = () => {
        setIsGroup(false);
        searchRequest('search-by-drive-id', {
            driveId: driveId
        })
    }

    const searchByOrderId = () => {
        setIsGroup(true);
        searchRequest('search-by-order-id', {
            orderId: orderId,
            driveId: driveId
        })
    }

    const template = _.map(log, (logItem, key) => {
        if (isGroup === false) {
            return (
                <div className={styles.LogGroup} key={key}>
                    <Alert variant={"info"} transition={false}>
                        {logItem.message}
                        <br/>
                        {logItem.datetime}
                    </Alert>
                </div>
            )
        } else {
            return (
                <div className={styles.LogGroup} key={key}>
                    <h5>{logItem[0]?.type} #{logItem[0]?.id}</h5>
                    {logItem.filter((log) => log !== null && !log.hasOwnProperty('type')).map((log, logKey) => (
                        <Alert variant={"info"} key={'group' + logKey + '-' + key} transition={false}>
                            {log.message}
                            <br/>
                            {log.datetime}
                        </Alert>
                    ))}
                </div>
            )
        }
    })

    return (
        <div className={styles.ContentBody}>
            <div style={{paddingRight: '12px'}}>
                <Row>
                    <Col md={6}>
                        <Input placeholder={'№ выезда'} onInput={(e) => setDriveId(e.target.value)}/>
                    </Col>
                    <Col md={6}>
                        <Input placeholder={'№ заказа'} onInput={(e) => setOrderId(e.target.value)}/>
                    </Col>
                </Row>
            </div>
            <div className={styles.ScrollWrap}>
                {template}
            </div>
        </div>
    )
}

export default DistributionLog;