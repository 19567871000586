import classNames from "classnames";
import styles from './Balloon.module.css';

function ClientDriveBalloon(props) {
    const drive = props.drive;
    return (
        <div className={styles.BalloonBody}>
            <span className={classNames({'label': true, [styles.LabelInBalloon]: true, [drive.label]: true})}>
                {drive.type}
            </span>
            <br/>
            Заказ - <a href={'/drycleaning/' + drive.orderNumber} target="_blank" rel="noreferrer" >{drive.orderNumber}</a>
            <br/>
            Время - {drive.time}
            <br/>
        </div>
    );
}

export default ClientDriveBalloon;