import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    menuIsOpen: false
};

export const centralPanelSlice = createSlice({
    name: 'centralPanel',
    initialState,
    reducers: {
        toggleMenuState: (state, action) => {
            state.menuIsOpen = action.payload;
        }
    }
});

export const {toggleMenuState} = centralPanelSlice.actions;
export const selectMenuIsOpen = (state) => state.drycleaningCentralPanel.menuIsOpen;

export default centralPanelSlice.reducer;