import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    authKey: null
};

export const wsSlice = createSlice({
    name: 'ws',
    initialState,
    reducers: {
        updateAuthKey: (state, action) => {
            state.authKey = action.payload;
        }
    }
});

export const {updateAuthKey} = wsSlice.actions;
export const selectAuthKey = (state) => state.ws.authKey;

export default wsSlice.reducer;