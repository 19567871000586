import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import axios from "axios";
import moment from 'moment';
import momentTimezone from 'moment-timezone'
import {updateAuthKey} from "./features/ws/wsSlice";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

moment.locale('ru');
momentTimezone.tz.setDefault('Europe/Moscow');

if (typeof process.env.REACT_APP_ENVIRONMENT !== "undefined") {
    Sentry.init({
        dsn: "https://924422fcc05e4aba8431ea3be1659f0a@sentry.airotech.ru/22",
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.REACT_APP_ENVIRONMENT
    });
}

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
const updateUser = () => {
    axios.get('/admin/user/options').then((response) => {
        store.dispatch(updateAuthKey(response.data.auth_key))
    }).catch((e) => {
        if (e.code === "ERR_NETWORK") {
            window.location.href = process.env.REACT_APP_API_BASE_URL + '/user/login'
        }
    })
}

updateUser();
setInterval(() => updateUser(), 5000);

ReactDOM.render(
    // <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    // </React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
