import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchEmployees, getSchedules} from "./employeeApi";

import _ from "lodash";

const initialState = {
    activeItem: null,
    employees: [],
    searchText: ''
};

export const loadEmployeesAsync = createAsyncThunk(
    'employees/list',
    async (params= {}) => {
        const response = await fetchEmployees(params);
        return response.data;
    }
);

export const loadEmployeesSchedules = createAsyncThunk(
    'syncEmployeesSchedules',
    async (params= {}) => {
        const response = await getSchedules(params);
        return response.data;
    }
);


export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        activateItem: (state, action) => {
            state.activeItem = action.payload;
        },
        deactivateActiveItem: (state) => {
            state.activeItem = null;
        },
        removeEmployee: (state, action) => {
            state.employees = _.without(state.employees, _.find(state.employees, {id: action.payload.id}))
        },
        setSearchText: (state, action) => {
            state.searchText = action.payload
        },
        updateEmployee: (state, action) => {
            const employee = _.find(state.employees, {
                id: action.payload.id
            });
            if (typeof employee !== "undefined") {
                _.each(action.payload.attributes, (value, key) => {
                    employee[key] = value;
                })
            }
        },
        syncEmployeesSchedules: (state, action) => {
            console.log('syncEmployeesSchedules');
            let schedules = action.payload;
            if (schedules.length === 0) {
                return true;
            }
            for (const schedule of schedules) {
                let cleaner = state.employees.find(cleaner => cleaner.id === schedule.cleanerId );
                if (!cleaner) {
                    continue;
                }
                let cleanerSchedule = cleaner.schedule.find(clSchedule => clSchedule.id === schedule.id  )
                if (!cleanerSchedule) {
                    continue;
                }
                if (cleanerSchedule.interval !== schedule.interval) {
                    cleanerSchedule.interval = schedule.interval;
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadEmployeesAsync.fulfilled, (state, action) => {
                state.employees = action.payload;
                state.filteredEmployees = [];
            })
            .addCase(loadEmployeesSchedules.fulfilled, (state, action) => {
                employeeSlice.caseReducers.syncEmployeesSchedules(state, action)
            });
    },
});

export const selectActiveItem = (state) =>  state.employee.employeeReducer.activeItem;
export const selectEmployees = (state) => state.employee.employeeReducer.employees;
export const selectSearchText = (state) => state.employee.employeeReducer.searchText;

export const { activateItem, deactivateActiveItem, updateEmployee, removeEmployee, setSearchText } = employeeSlice.actions;
export default employeeSlice.reducer;