import { useState, useEffect } from 'react';
import useWebSocket  from 'react-use-websocket';
import _ from 'lodash'
import {useDispatch, useSelector} from "react-redux";
import {updateDrive} from "../object/cleaningMapObjectSlice";
import {
    addToRoute, removeFromRoute,
    selectDrivesInRoutes,
    updateDriveInRoute
} from "../route/routeSlice";
import {selectAuthKey} from "../../ws/wsSlice";

const WsClient = () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const authKey = useSelector(selectAuthKey)
    const [socketUrl] = useState(baseUrl.replace('https', 'wss') + '/lsystem-socket?auth_key=' + authKey);
    const dispatch = useDispatch();
    const {lastMessage} = useWebSocket(socketUrl, {shouldReconnect: () => true});
    const drivesInRoutes = useSelector(selectDrivesInRoutes);

    const eventHandlers = {
        routeItemsEvent: {
            add(data) {
                _.each(data.entities, (drive) => {
                    dispatch(updateDrive({drive: drive, attributes: drive}))
                    dispatch(updateDriveInRoute({drive: drive, attributes: drive}))
                    const routeId = _.property('id')(drive.route);
                    const formattedDriveInRoute = {
                        cleanerId: drive.cleanerId,
                        scheduleId: drive.scheduleId,
                        drive: drive
                    };

                    const routeExist = _.find(drivesInRoutes, {id: routeId});
                    if (typeof routeExist !== "undefined") {
                        dispatch(addToRoute(formattedDriveInRoute))
                    }
                })
            },
            change(data) {
                _.each(data.entities, (drive) => {
                    dispatch(updateDrive({drive: drive, attributes: drive}))
                    const driveInRoute = _.find(drivesInRoutes, (driveInRoute) => {
                        return driveInRoute.drive.id === drive.id
                    });
                    if (typeof driveInRoute !== "undefined") {
                        dispatch(updateDriveInRoute({drive: drive, attributes: drive}))
                    } else {
                        this.routeItemsEvent['add'](data);
                    }
                });
            },
            remove(data) {
                _.each(data.entities, (drive) => {
                    dispatch(updateDrive({drive: drive, attributes: drive}))
                    dispatch(updateDriveInRoute({drive: drive, attributes: drive}))
                    const driveInRoute = _.find(drivesInRoutes, (driveInRoute) => {
                        return driveInRoute.drive.id === drive.id
                    });
                    if (typeof driveInRoute !== "undefined" && drive.cleanerId === null) {
                        dispatch(removeFromRoute(driveInRoute))
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (lastMessage !== null) {
            const wsEvent = JSON.parse(lastMessage.data);
            const eventHandler = eventHandlers[wsEvent.type];
            if (typeof eventHandler !== "undefined" && wsEvent.owner === 'clsystem') {
                eventHandler[wsEvent.event].bind(eventHandlers, wsEvent.data, wsEvent.channel)();
            }
        }
    }, [lastMessage]);

    return null;
}

export default WsClient