import classNames from "classnames";

import styles from './AddressInput.module.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faClose } from '@fortawesome/free-solid-svg-icons'

import { useState } from "react";

import YandexSuggestInput from './YandexSuggestInput';

const AddressSearchInput = ({ addAddressSearchResultPlacemarkFunc,removeAddressSearchResultPlacemarkFunc }) => {

    const [addressSearchInputIsShown, setAddressSearchInputIsShown] = useState(false);


    return (
        <div className={classNames({
            [styles.AddressInputComboContainer]: true,
            [styles.Collapsed]: addressSearchInputIsShown === false,
            [styles.Opened]: addressSearchInputIsShown === true,
        })}>

            <YandexSuggestInput
                addressSearchInputIsShown={addressSearchInputIsShown}
                addAddressSearchResultPlacemarkFunc={addAddressSearchResultPlacemarkFunc}
                removeAddressSearchResultPlacemarkFunc={removeAddressSearchResultPlacemarkFunc}
            />

            <button
                type="button"
                className="btn btn-default"
                title="Найти адрес"
                onClick={() => setAddressSearchInputIsShown(!addressSearchInputIsShown)}
            >
                <FontAwesomeIcon icon={addressSearchInputIsShown ? faClose : faSearch} />
            </button>
        </div>
    )
}

export default AddressSearchInput;