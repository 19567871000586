import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    fetchCategories,
    fetchCategoriesViaMaps,
    fetchMaps
} from "./centralPanelApi";

const initialState = {
    categories: [],
    categoriesViaMaps: [],
    maps: [],
    currentMapId: null
};

export const loadCategoriesAsync = createAsyncThunk(
    'cleaning/categories',
    async (params= {}) => {
        const response = await fetchCategories(params);
        return response.data;
    }
);

export const loadMapsAsync = createAsyncThunk(
    'cleaning/maps',
    async (params= {}) => {
        const response = await fetchMaps(params);
        return response.data;
    }
);

export const loadCategoriesViaMapsAsync = createAsyncThunk(
    'cleaning/categories-via-maps',
    async (params= {}) => {
        const response = await fetchCategoriesViaMaps(params);
        return response.data;
    }
);

export const cleaningCentralPanelSlice = createSlice({
    name: 'centralPanel',
    initialState,
    reducers: {
        setCurrentMapId(state, action) {
            state.currentMapId = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadCategoriesAsync.fulfilled, (state, action) => {
                state.categories = action.payload;
            });
        builder
            .addCase(loadMapsAsync.fulfilled, (state, action) => {
                state.maps = action.payload.models;
            });
        builder
            .addCase(loadCategoriesViaMapsAsync.fulfilled, (state, action) => {
                state.categoriesViaMaps = action.payload.models;
            });
    }
});

export const {setCurrentMapId} = cleaningCentralPanelSlice.actions;
export const selectCategories = (state) => state.cleaningCentralPanel.categories;
export const selectCategoriesViaMaps = (state) => state.cleaningCentralPanel.categoriesViaMaps;
export const selectMaps = (state) => state.cleaningCentralPanel.maps;
export const selectCurrentMapId = (state) => state.cleaningCentralPanel.currentMapId;

export default cleaningCentralPanelSlice.reducer;