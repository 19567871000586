import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faSave} from "@fortawesome/free-solid-svg-icons";
import styles from './Column.module.css'
import {useState} from "react";
import {TextField} from "@material-ui/core";

const EditableTextColumn = ({defaultValue, onSave}) => {
    const [isEditableState, setEditableState] = useState(false)
    const [newValue, setNewValue] = useState(defaultValue)

    return (
        <Container className={styles.EditableTextColumnContainer}>
            <Row className={styles.EditableTextColumnRow}>
                <Col md={11} className={styles.EditableTextColumnValue}>
                    {isEditableState === false ? newValue : <TextField
                        onInput={(e) => setNewValue(e.target.value)}
                        defaultValue={newValue}/>}
                </Col>
                <Col md={1} className={styles.EditableTextColumnIcon}>
                    {isEditableState === false ?
                        <FontAwesomeIcon onClick={(e) => setEditableState(true)}
                                         className={styles.Icon} icon={faEdit}/> :
                        <FontAwesomeIcon onClick={(e) => {
                            setEditableState(false)
                            onSave(newValue)
                        }}
                        className={styles.SaveIcon} icon={faSave}/>}
                </Col>
            </Row>
        </Container>
    )
}

export default EditableTextColumn;