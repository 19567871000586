import axios from "axios";
import _ from 'lodash';
import {useEffect, useState} from "react";
import {Input} from "@material-ui/core";
import {Alert} from "react-bootstrap";
import styles from './Content.module.css'

function NotificationsHistory() {

    const [notificationsHistory, setNotificationsHistory] = useState([]);
    const [notificationsHistorySearch, setNotificationsHistorySearch] = useState([]);
    const [searchMessage, setSearchMessage] = useState(null);
    const currentHistory = searchMessage ? notificationsHistorySearch : notificationsHistory

    useEffect(() => {
        setNotificationsHistory([]);
        setNotificationsHistorySearch([]);
        showMore(0);
    }, [])

    useEffect(() => {
        if (!searchMessage) {
            setNotificationsHistorySearch([]);
        } else {
            showMore(0, searchMessage, false)
        }
    }, [searchMessage])

    const showMore = (lastId, like, withUnion) => {
        axios.get('/lsystem/api/v1/message', {
            params: {
                lastID: lastId,
                like: like
            }
        }).then((response) => {
            if (typeof like !== "undefined" && like !== null) {
                if (withUnion === true) {
                    setNotificationsHistorySearch(_.union(notificationsHistorySearch, response.data));
                } else {
                    setNotificationsHistorySearch(response.data);
                }
            } else {
                setNotificationsHistory(_.union(notificationsHistory, response.data));
            }
        });
    }

    const getLastId = () => {
      return Math.min(..._.map(currentHistory, 'id'));
    }

    return (
        <div className={styles.ContentBody}>
            <div style={{paddingRight: '12px'}}>
                <Input placeholder={'Поиск'} onInput={(e) => setSearchMessage(e.target.value)}/>
            </div>
            <div className={styles.ScrollWrap}>
                {currentHistory.map((notification, key) => (
                    <Alert variant={'success'} key={key} transition={false}>
                        {notification.message}
                        <br/>
                        {notification.create_at}
                    </Alert>
                ))}
            <span className={styles.MoreHistory} onClick={(e) => showMore(getLastId(), searchMessage, true)}>
                ниже
            </span>
            </div>
        </div>
    )
}

export default NotificationsHistory;