import {useDispatch, useSelector} from "react-redux";

import { useState, useMemo } from "react";

import _ from 'lodash'
import classNames from "classnames";

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from "react-bootstrap";

import styles from './Route.module.css'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faClock, faEdit } from "@fortawesome/free-solid-svg-icons";

import {
    addRouteInSaveProcess,
    createRouteAsync,
    removeFromRoute,
    selectDrivesInRoutes,
    updateDriveInRoute,
    selectRouteIds,
    selectSaveRouteInProcess,
    updateRouteAsync
} from "./routeSlice";

import {
    loadGeoObjectsInInfo,
    selectDrives,
    selectGeoObjectsInInfoWindow,
    selectWaypoints
} from "../object/drycleaningMapObjectSlice";
import {findObjectsByLatLng} from "../../../app/utils/latLng";
import {courierIsLate} from "../utils/time";

const RouteBody = ({employee}) => {

    const SELECT_FONTSIZE = '14px';

    const dispatch = useDispatch();
    const drivesInRoutes = useSelector(selectDrivesInRoutes);
    const geoObjectsInInfoWindow = useSelector(selectGeoObjectsInInfoWindow);
    const driveGeoObjects = useSelector(selectDrives)
    const waypointGeoObjects = useSelector(selectWaypoints)
    const saveRouteInProcess = useSelector(selectSaveRouteInProcess);
    const routeIds = useSelector(selectRouteIds);
    const filteredDrivesInRoutes = _.filter(drivesInRoutes, {courier: employee.id})
    const drivesInFilteredDrivesInRoutes = _.map(filteredDrivesInRoutes, 'drive');

    const [waypointsRerenderTrigger, setWaypointsRerenderTrigger] = useState(1);

    const [waypointsInRouteIsEditable, setWaypointsInRouteIsEditable] = useState([]);

    const waypoints = useMemo(() => {
        return _.uniqBy(_.flatten(_.map(drivesInFilteredDrivesInRoutes, 'wayPoints')), (wayPoint) => {
            return wayPoint.id;
        });
    }, [filteredDrivesInRoutes, waypointsRerenderTrigger]);

    const isActive = (driveInRoute) => {
        let inInfoWindow = false;
        _.each(geoObjectsInInfoWindow, (geoObject) => {
            if (inInfoWindow === false && geoObject.id === driveInRoute.drive.id) {
                inInfoWindow = true;
            }
        })
        return inInfoWindow;
    }

    const drivesInRoutesByWayPoint = (wayPoint) => {
        let route = _.reject(filteredDrivesInRoutes, function (item) {
            return !_.find(item.drive.wayPoints, {id: wayPoint.id});
        });
        return _.sortBy(route,
            (driveInRoute) => {
                return driveInRoute.drive.time.replace(/\D+/g, "");
            }
        )
    }

    const removeButton = (driveInRoute) => {
        return (<FontAwesomeIcon
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(removeFromRoute(driveInRoute))
                    }}
                    className={styles.RemoveRouteItemBtn} icon={faClose}
                />);
    }

    const address = (driveInRoute) => {
        return driveInRoute.drive.isTransportation
            ? 'На фабрику ' + driveInRoute.drive.transportation.finishWayPoint.name
            : driveInRoute.drive.address;
    }

    const toggleWaypointInRouteIsEditable = (wpId) => {
        let editableWPs = waypointsInRouteIsEditable;
        if (waypointsInRouteIsEditable.indexOf(wpId) === -1) {
            editableWPs.push(wpId);
        } else {
            editableWPs = editableWPs.filter((id) => id !== wpId);
        }
        setWaypointsInRouteIsEditable(editableWPs);
        setWaypointsRerenderTrigger(Math.random(10000)); // костыль для ререндера, на изменение массива не реагирует
    }

    const waypointInRoutIsEditable = (wpId) => {
        return waypointsInRouteIsEditable.indexOf(wpId) !== -1;
    }

    const driveStatus = (driveInRoute) => {
        return (
            <div className={classNames({ [
                        styles.DriveStatus]: true,
                        'label': true,
                        [driveInRoute.drive.statusLabel]: true
                    })}>
                {driveInRoute.drive.status}
            </div>
        );
    }

    const handleSaveClick = () => {
        const routeData = _.find(routeIds, { employeeId: employee.id });
        if (typeof routeData !== "undefined") {
            dispatch(addRouteInSaveProcess(filteredDrivesInRoutes))
            dispatch(updateRouteAsync({
                routeId: routeData.id,
                route: filteredDrivesInRoutes
            }))
        } else if (filteredDrivesInRoutes.length) {
            dispatch(addRouteInSaveProcess(filteredDrivesInRoutes))
            dispatch(createRouteAsync({
                date: employee.date,
                route: filteredDrivesInRoutes
            }))
        }
    }

    const replaceWaypointInDrive = (drive, oldWaypointId, newWaypointId) => {
        let driveWapoints = drive.wayPoints;
        if (!driveWapoints) return;

        let oldWaypoint = driveWapoints.find((wp) => wp.id === oldWaypointId );
        if (oldWaypoint) {
            driveWapoints = _.without(driveWapoints, oldWaypoint);
        }

        let newWaypoint = waypointGeoObjects.find((wp) => wp.id === newWaypointId );
        driveWapoints.push(newWaypoint);
        dispatch(updateDriveInRoute({ drive: drive, attributes: { wayPoints: driveWapoints } }));
    }

    const wpSelectItems = waypointGeoObjects.map((waypoint) => {
        return (<MenuItem value={waypoint.id} key={waypoint.id} style={{ fontSize: "12px"}}>{waypoint.name}</MenuItem>);
    });

    const routeBody = waypoints.map((waypoint, waypointIndex) => {
        return (
            <div className={styles.WaypointWrap} key={waypointIndex}>
                <span className={classNames({ 'label': true, 'label-primary': true, [styles.WaypointName]: true })}>
                    {waypoint.name}
                </span>
                <span className="label label-primary"
                    title="Изменить фабрику назначения"
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleWaypointInRouteIsEditable(waypoint.id);
                    }}
                >
                    <FontAwesomeIcon icon={waypointInRoutIsEditable(waypoint.id) ? faClose : faEdit} />

                </span>
                {drivesInRoutesByWayPoint(waypoint).map((driveInRoute, driveInRouteIndex) => (
                    <div key={driveInRouteIndex} className={
                        classNames(
                            {
                                [styles.RouteItemWrap]: true,
                                [styles.New]: isActive(driveInRoute),
                                [styles.RouteItemIsActive]: isActive(driveInRoute),
                                [styles.Late]: courierIsLate(driveInRoute)
                            }
                        )}>
                        <div className={styles.RouteItemInfo}
                            onClick={(e) => {
                                e.stopPropagation();
                                const geoObjects = findObjectsByLatLng(
                                    [driveInRoute.drive.lat, driveInRoute.drive.lng],
                                    _.union(driveGeoObjects, waypointGeoObjects)
                                );
                                dispatch(loadGeoObjectsInInfo(geoObjects))
                            }}>
                            {driveInRouteIndex + 1})&nbsp;
                            {driveInRoute.drive.type}
                            <span className="label label-success" style={{marginLeft: "4px"}}>
                                #{driveInRoute.drive.orderNumber || driveInRoute.drive.id}
                            </span>
                            <span style={{marginLeft: '4px',}}>
                                <FontAwesomeIcon icon={faClock} /> {driveInRoute.drive.time}
                            </span>
                            {
                                driveInRoute.drive.allowedChange
                                ? removeButton(driveInRoute)
                                : null
                            }
                            <br />
                            {address(driveInRoute)}
                            <br />
                            {
                                !driveInRoute.drive.allowedChange
                                ?
                                driveStatus(driveInRoute)
                                : null
                            }
                            {
                                waypointInRoutIsEditable(waypoint.id)
                                    ?
                                    <FormControl variant="standard" fullWidth>
                                        <Select
                                            id={("select-wp-" + waypoint.id)}
                                            value={waypoint.id}
                                            onChange={(e) => replaceWaypointInDrive(driveInRoute.drive, waypoint.id, e.target.value)}
                                            style={{
                                                fontSize: SELECT_FONTSIZE,
                                                padding: "0 4px"
                                            }}
                                        >
                                            {wpSelectItems}
                                        </Select>
                                    </FormControl>
                                    : null
                            }
                        </div>
                    </div>
                ))}
            </div>
        )
    });



    return (
        <div>
            {routeBody}
            <Button variant={'success'} size={"sm"}
                    disabled={saveRouteInProcess}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleSaveClick()
                    }}>
                Сохранить
            </Button>
        </div>
    )
}

export default RouteBody;