import axios from "axios";

export function fetchDrives(params = {}) {
    return axios.get('/lsystem/api/v1/drives', {params: params})
}

export function fetchWaypoints(params = {}) {
    return axios.get('/lsystem/api/v1/way-point', {params: params})
}

export function fetchZones(params = {}) {
    return axios.get('/admin/map/get-work-zones', {params: params})
}