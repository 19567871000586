import styles from './Layout.module.css'
import {Oval} from "react-loader-spinner";

const BlockLayout = () => {
  return (
      <div>
          <div className={styles.BlockLayout}></div>
          <Oval
              height={200}
              width={200}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass={styles.BlockLayoutSpinner}
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
          />
      </div>
  )
}

export default BlockLayout