import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchFilters} from "./filterApi";
import _ from 'lodash'
import moment from "moment";

const defaultFiltersValue = {
    couriers: [],
    timeslots: [],
    zones: [],
    orderNumber: null,
    status: 0,
    hasCarpets: null,
    hasCurtains: null,
    hasBabyBuggy: null,
    transportation: null,
    onlyDelivery: null,
    onlyCollect: null,
    default: true,
    date: moment().format('YYYY-MM-DD')
};

const initialState = {
    filtersData: {
        couriers: [],
        statuses: [],
        timeslots: [],
        zones: [],
    },
    filtersValue: Object.assign({}, defaultFiltersValue),
    isRefreshed: false
};

export const loadFiltersAsync = createAsyncThunk(
    'filters/fetch',
    async (params = {}) => {
        const response = await fetchFilters(params);
        return response.data;
    }
);

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        modifyFilterValue(state, action) {
            if (action.payload.strategy) {
                const strategy = action.payload.strategy;
                const value = action.payload.value;
                let newState;
                if (strategy === 'without') {
                    newState = _.without(
                        state.filtersValue[action.payload.key],
                        ...(_.isArray(value) ? value : [value])
                    )
                } else if (strategy === 'union') {
                    newState = _.union(
                        state.filtersValue[action.payload.key],
                        value
                    )
                }
                state.filtersValue[action.payload.key] = newState;
            } else {
                state.filtersValue[action.payload.key] = action.payload.value;
            }
        },
        refreshFiltersValue(state) {
            state.filtersValue = Object.assign({}, defaultFiltersValue);
        },
        toggleIsRefreshedState(state, action) {
            state.isRefreshed = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadFiltersAsync.fulfilled, (state, action) => {
                state.filtersData = action.payload;
            });
    },
});

export const {modifyFilterValue, refreshFiltersValue, toggleIsRefreshedState} = filterSlice.actions;
export const selectFiltersData = (state) => state.drycleaningFilter.filtersData;
export const selectFiltersValueDate = (state) => state.drycleaningFilter.filtersValue.date;
export const selectFiltersValueZones = (state) => state.drycleaningFilter.filtersValue.zones;
export const selectFiltersValue = (state) => state.drycleaningFilter.filtersValue;
export const selectIsRefreshed = (state) => state.drycleaningFilter.isRefreshed;
export default filterSlice.reducer;