import {style} from "../../app/utils/load";
import {useDispatch, useSelector} from "react-redux";
import {
    Clusterer,
    Map,
    Placemark,
    Polygon,
    Polyline,
    YMaps
} from "react-yandex-maps";
import {useCity} from "../../app/utils/use";
import {
    findCityCenter,
    findObjectsByLatLng,
    latLngIsEqual
} from "../../app/utils/latLng";
import {CITY_LIST, CLEANING_SERVICE} from "../../app/utils/const";
import {createRef, useEffect, useRef, useState} from "react";
import FilterPanel from "./filter/FilterPanel";
import {
    clearDrives,
    clearZones,
    loadDrivesAsync,
    loadZonesAsync,
    selectDrives,
    selectZones,
    updateDrive
} from "./object/cleaningMapObjectSlice";
import {
    deactivateActiveItem, loadEmployeesAsync, selectActiveItem, selectEmployees, setSearchText, loadEmployeesSchedules
} from "../employee/employeeSlice";

import CentralPanel from "./panel/CentralPanel";
import {
    selectCategories,
    selectCategoriesViaMaps
} from "./panel/centralPanelSlice";
import _ from "lodash";
import {
    filterEmployeesByCategory,
    filterEmployeesByName,
    loadAllEmployeesAsync, selectAllEmployees,
    selectFilteredEmployees,
    selectFilterIsApply
} from "./employee/employeeSlice";

import EmployeeBody from "../cleaning/employee/EmployeeBody";
import EmployeeList from "../employee/EmployeeList";
import {loadRoutesAsync, selectDrivesInRoutes} from "./route/routeSlice";
import WsClient from "./ws/WsClient";
import {selectAuthKey} from "../ws/wsSlice";
import {addToRoute} from "./route/routeSlice";
import moment from "moment";
import routeStyles from './route/Route.module.css'
import {renderToStaticMarkup} from "react-dom/server";
import DriveBalloon from "./balloon/DriveBalloon";
import {
    selectGeoObjectsInInfoWindow
} from "./object/cleaningMapObjectSlice";
import Info from "../window/Info";
import {
    loadGeoObjectsInInfo
} from "./object/cleaningMapObjectSlice";
import CleaningContent from "./window/content/CleaningContent";
import axios from "axios";

console.log('CleaningLogisticApp ver: 1.1.20230522');

function CleaningApp() {
    style('//maxcdn.bootstrapcdn.com/bootswatch/3.3.7/paper/bootstrap.min.css', 'boostrap-css');

    const dispatch = useDispatch();
    const [ymaps, setYmaps] = useState(null);
    const [currentMapId, setCurrentMapId] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [zonePolygons, setZonePolygons] = useState([])
    const [drivePlacemarks, setDrivePlacemarks] = useState([])
    const [pickUpAndDeliveryKeyPlacemarks, setPickUpAndDeliveryKeyPlacemarks] = useState([])
    const [showEmployeesByType, setShowEmployeesByType] = useState(null);
    const [filterDrivesByIds, setFilterDrivesByIds] = useState([]);
    const [polyline, setPolyline] = useState(null)
    const categoriesViaMaps = useSelector(selectCategoriesViaMaps);

    const drivesInRoutes = useSelector(selectDrivesInRoutes);
    const authKey = useSelector(selectAuthKey);
    const employeeFilterIsApply = useSelector(selectFilterIsApply);
    const activeEmployee = useSelector(selectActiveItem);
    const geoObjectsInInfoWindow = useSelector(selectGeoObjectsInInfoWindow)

    const baseEmployeesList = _.filter(useSelector(selectEmployees), (employee) => {
        const categoriesByCurrentMap = _.filter(categoriesViaMaps, {mapId: currentMapId});
        const externalCategoryIds = _.map(categoriesByCurrentMap, 'externalCategoryId');
        const categoriesIncludeMap =  _.filter(employee.categories, (categoryId) => {
            return _.includes(externalCategoryIds, categoryId)
        });
        return categoriesIncludeMap.length;
    });

    const filteredEmployeesList = useSelector(selectFilteredEmployees)
    const allEmployees = useSelector(selectAllEmployees)
    const employees = _.sortBy(employeeFilterIsApply ? filteredEmployeesList : baseEmployeesList, (employee) => {
        return [employee.name];
    })

    const mapRef = useRef(null);
    const clustererRef = createRef(null)
    const cityId = useCity();
    const cityCenter = findCityCenter(CITY_LIST, cityId);

    const zones = useSelector(selectZones);
    const drives = useSelector(selectDrives);
    const categories = useSelector(selectCategories);

    const [zoneIdsPickedInFilter, setZoneIdsPickedInFilter] = useState([]);

    useEffect(() => {
        if (baseEmployeesList.length > 0 && selectedCategories.length) {
            dispatch(filterEmployeesByCategory({categories: selectedCategories, employees: baseEmployeesList}))
        }
    }, [baseEmployeesList.length])

    useEffect(() => {
        setDrivePlacemarks([]);
        setPickUpAndDeliveryKeyPlacemarks([]);
        if (drives.length && zonePolygons.length && selectedCategories.length) {
            const categoryIds = _.map(selectedCategories, 'categoryId');

            let drivePlacemarks = [];
            let pickUpAndDeliveryKeyPlacemarks = [];

            const filteredZones = zones.filter((zone) => {
                if (currentMapId !== zone.mapId) {
                    return false;
                }

                if (zoneIdsPickedInFilter.length > 0 && zoneIdsPickedInFilter.indexOf(zone.zoneId) === -1) {
                    return false
                }
                return true
            });

            _.each(filteredZones, (zone) => {
                let drivesInZone = _.filter(drives, (drive) => {
                    return drive.addressData.zone !== null && drive.addressData.zone.zoneId === zone.zoneId
                })
                if (categoryIds[0] !== null) {
                    drivesInZone = _.filter(drivesInZone, (drive) => {
                        return _.includes(categoryIds, drive.cleaningCategoryId)
                    })
                }
                if (filterDrivesByIds.length) {
                    drivesInZone = _.filter(drivesInZone, (drive) => {
                        return _.includes(filterDrivesByIds, drive.id)
                    })
                }
                _.each(drivesInZone, (drive) => {
                    drivePlacemarks.push(<Placemark
                        options={{
                            iconLayout: 'default#image',
                            iconImageHref: '/icon/cleaning/' + drive.icon,
                            iconImageSize: [30, 42],
                            iconImageOffset: [-15, -42],
                            zIndex: 2
                        }}
                        geometry={[drive.addressData.lat, drive.addressData.lng]}
                        properties={{
                            icon: drive.icon, status: drive.status, driveId: drive.id
                        }}
                        key={'drive-' + drive.id}
                    />);

                    const createKeyPlacemark = (icon, lat, lng, driveId) => {
                        return <Placemark
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: '/icon/cleaning/' + icon,
                                    iconImageSize: [40, 30],
                                    iconImageOffset: [-16, -7],
                                    zIndex: 2
                                }}
                                geometry={[lat, lng]}
                                properties={{driveId: driveId}}
                                key={'pick-up-key-' + driveId}
                            />
                    }

                    let deliveryAndPickUpKeyIsEqual = false;
                    if (drive.order.deliveryKeyService !== null && drive.order.pickUpKeyService !== null) {
                        const deliveryKeyServiceAddress = drive.order.deliveryKeyService.addressData;
                        const pickUpKeyServiceAddress = drive.order.pickUpKeyService.addressData;
                        if (latLngIsEqual(
                            [deliveryKeyServiceAddress.lat, deliveryKeyServiceAddress.lng],
                            [pickUpKeyServiceAddress.lat, pickUpKeyServiceAddress.lng])
                        ) {
                            deliveryAndPickUpKeyIsEqual = true;
                            pickUpAndDeliveryKeyPlacemarks.push(
                                createKeyPlacemark(
                                    'pick-up-and-delivery-key.png',
                                    deliveryKeyServiceAddress.lat,
                                    deliveryKeyServiceAddress.lng,
                                    drive.id
                                )
                            )
                        }
                    }

                    if (drive.order.deliveryKeyService !== null && deliveryAndPickUpKeyIsEqual === false) {
                        pickUpAndDeliveryKeyPlacemarks.push(createKeyPlacemark(
                            'delivery-key.png',
                            drive.order.deliveryKeyService.addressData.lat,
                            drive.order.deliveryKeyService.addressData.lng,
                            drive.id
                        ))
                    }

                    if (drive.order.pickUpKeyService !== null && deliveryAndPickUpKeyIsEqual === false) {
                        pickUpAndDeliveryKeyPlacemarks.push(createKeyPlacemark(
                            'pick-up-key.png',
                            drive.order.pickUpKeyService.addressData.lat,
                            drive.order.pickUpKeyService.addressData.lng,
                            drive.id
                        ))
                    }
                })
            })
            setDrivePlacemarks(drivePlacemarks);
            setPickUpAndDeliveryKeyPlacemarks(pickUpAndDeliveryKeyPlacemarks);
        }
    }, [zonePolygons, drives, selectedCategories, filterDrivesByIds]);

    let employeesAddressesPlacemarks = [];
    if (showEmployeesByType) {
        const employeesByType = showEmployeesByType === 1 ? allEmployees : baseEmployeesList;
        const selectedCategoriesWithoutEmpty = _.reject(selectedCategories, {categoryId: null});
        let employeesByTypeAndCategories = [];
        if (selectedCategoriesWithoutEmpty.length) {
            _.each(selectedCategoriesWithoutEmpty, (category) => {
                employeesByTypeAndCategories = _.union(employeesByTypeAndCategories, employeesByType.filter((employee) => {
                    return _.includes(employee.categories, category.categoryId);
                }))
            })
        } else {
            employeesByTypeAndCategories = employeesByType;
        }
        employeesAddressesPlacemarks = _.map(employeesByTypeAndCategories.filter((employee) => employee.addressBook !== null), (employee) => {
            return (
                <Placemark
                    options={{
                        zIndex: 2,
                        preset: "islands#blueStretchyIcon",
                    }}
                    properties={{
                        iconContent: employee.name,
                    }}
                    geometry={[employee.addressBook.lat, employee.addressBook.lng]}
                    key={'cleaner-' + employee.id}
                />
            )
        })
    }

    useEffect((e) => {
        setZonePolygons([]);
        if (zones.length && currentMapId) {
            const filteredZones = zones.filter((zone) => zone.isVisible && currentMapId === zone.mapId);
            const polygons = [];
            _.each(filteredZones, (zone, key) => {
                polygons.push(<Polygon
                    options={{
                        fillColor: zone.color, opacity: 0.5, strokeColor: '#000000', strokeWidth: 2,
                    }}
                    geometry={[zone.geometry.points.map((latLng) => [latLng.lat, latLng.lng]), []]}
                    key={'zone' + key}
                />)
            })
            setZonePolygons(polygons)
        }
    }, [currentMapId, zones])

    useEffect(() => {
        dispatch(clearZones())
        dispatch(deactivateActiveItem())
        dispatch(loadZonesAsync({cityId: cityId, serviceId: CLEANING_SERVICE}));
        dispatch(setSearchText(''));
        dispatch(loadAllEmployeesAsync({
            url: 'clsystem/api/v1/cleaner/all-by-date', params: {
                cityId: cityId, serviceId: CLEANING_SERVICE
            }
        }))
    }, [cityId])



    // IT-4320 START периодическое обновление отображения интервалов клинеров при изменении в админке

    const [cleanersSchedulesOnDate, setCleanersSchedulesOnDate] = useState(null);
    const cleanersSchedulesOnDateRef = useRef();
    useEffect(() => {
        cleanersSchedulesOnDateRef.current = cleanersSchedulesOnDate;
    }, [cleanersSchedulesOnDate]);


    const employeesRef = useRef();
    useEffect(() => {
        employeesRef.current = employees;
    }, [employees]);

    const cleanersSchedulesUpdateTimer = useRef();
    const CLEANERS_SCHEDULES_REFRESH_TIME = 30000;

    useEffect(() => {
        initCleanersScheduleRefresh();
    }, []);

    const initCleanersScheduleRefresh = () => {
        if (cleanersSchedulesUpdateTimer.current) {
            return;
        }
        cleanersSchedulesUpdateTimer.current = setInterval(() => {refreshCleanersSchedules();}, CLEANERS_SCHEDULES_REFRESH_TIME);
    }
    const getCurrentDate = () => {
        return cleanersSchedulesOnDateRef.current;
    }

    const getCurrentEmployees = () => {
        return employeesRef.current;
    }

    const refreshCleanersSchedules = () => {
        let date = getCurrentDate();
        if (date === null) {
            console.log('no date picked');
            return;
        }
        let currentEmployees = getCurrentEmployees();
        if (parseInt(currentEmployees.length) < 1) {
            console.log('no employess');
            return;
        }
        let cleanersIds = _.map(currentEmployees, (cleaner) => {
            return cleaner.id;
        });
        dispatch(loadEmployeesSchedules({
                url: 'clsystem/api/v1/cleaner/get-schedules', params: {
                cityId: cityId, date: date, cleanersIds: JSON.stringify(cleanersIds),
            }
        }))
    }

    // IT-4320 STOP периодическое обновление отображения интервалов клинеров при изменении в админке


    const loadEmployees = (filtersValue) => {
        setCleanersSchedulesOnDate(filtersValue.date);
        dispatch(loadEmployeesAsync({
            url: 'clsystem/api/v1/cleaner', params: {
                cityId: cityId, date: filtersValue.date, serviceId: CLEANING_SERVICE
            }
        }))
        dispatch(loadRoutesAsync({
            cityId: cityId, expand: 'drive', date: filtersValue.date
        }))
    }

    const loadDrives = (filtersValue) => {
        dispatch(clearDrives())
        setZoneIdsPickedInFilter(filtersValue.zones)
        dispatch(loadDrivesAsync({
            cityId: cityId, expand: 'order,client', filter: JSON.stringify(filtersValue)
        }));
    }

    const handleClickAddToRoute = (drive) => {
        const employee = _.find(baseEmployeesList, {id: activeEmployee})

        if (typeof employee === "undefined") {
            return;
        }

        if (!employee.categories.includes(drive.cleaningCategoryId)) {
            alert('Категории заказа и клинера не совпадают');
            return;
        }

        const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
        let scheduleItems = _.filter(employee.schedule, (scheduleItem) => {
            let driveInterval = drive.time.split(' - ');
            let driveStartTime = moment(drive.date + ' ' + driveInterval[0], DATE_TIME_FORMAT);
            let driveEndTime = moment(drive.date + ' ' + driveInterval[1], DATE_TIME_FORMAT);
            let scheduleInterval = scheduleItem.interval.split(' - ');
            let scheduleStartTime = moment(drive.date + ' ' + scheduleInterval[0], DATE_TIME_FORMAT);
            let scheduleEndTime = moment(drive.date + ' ' + scheduleInterval[1], DATE_TIME_FORMAT);
            return (driveStartTime.isBetween(scheduleStartTime, scheduleEndTime) || driveStartTime.isSame(scheduleStartTime)) && (driveEndTime.isBetween(scheduleStartTime, scheduleEndTime) || driveEndTime.isSame(scheduleEndTime));
        })

        if (scheduleItems.length === 0) {
            alert('Время данного заказа не входит в рабочий интервал клинера');
            return;
        }

        dispatch(addToRoute({
            courier: activeEmployee, drive: drive, scheduleId: _.head(scheduleItems).id
        }))
    }

    const closeBalloon = (e) => {
        e.getSourceEvent().originalEvent.map.balloon.close();
    }

    const removePolyline = () => {
        setPolyline(null)
    }

    const renderPolyLine = (geoObjects) => {
        const geometry = [];
        geoObjects.map((geoObject) => {
            geometry.push([geoObject.addressData.lat, geoObject.addressData.lng])
        })
        setPolyline(<Polyline geometry={geometry} options={{
            balloonCloseButton: false,
            strokeColor: '#ff4545',
            strokeWidth: 3,
            strokeOpacity: 1
        }} />)
    }

    const openInfoPanel = (e) => {
        const latLng = e.getSourceEvent().originalEvent.target.geometry.getCoordinates();
        let objects = findDrivesByOptions(latLng);
        if (objects.length === 0) {
            objects = findObjectsByLatLng(latLng, drives);
        }
        dispatch(loadGeoObjectsInInfo(objects))
        const options = findOptionsByDrives(objects);
        renderPolyLine(_.union(objects, options))
    }

    const findOptionsByDrives = (drives) => {
        const options = []
        _.each(drives, (drive) => {
            if (drive.order.pickUpKeyService !== null) {
                options.push(drive.order.pickUpKeyService)
            }

            if (drive.order.deliveryKeyService !== null) {
                options.push(drive.order.deliveryKeyService)
            }
        })
        return options;
    }

    const findOptionsByLatLng = (latLng) => {
        let pickUpKeysOptions = _.map(drives, (drive) => {
            return drive.order.pickUpKeyService;
        });
        pickUpKeysOptions = _.reject(pickUpKeysOptions, (option) => option === null);
        const pickUpKeys = findObjectsByLatLng(latLng, pickUpKeysOptions);

        let deliveryKeysOptions = _.map(drives, (drive) => {
            return drive.order.deliveryKeyService;
        });
        deliveryKeysOptions = _.reject(deliveryKeysOptions, (option) => option === null);
        const deliveryKeys = findObjectsByLatLng(latLng, deliveryKeysOptions);

        return {pickUpKeys, deliveryKeys}
    }

    const findDrivesByOptions = (latLng) => {
        const options = findOptionsByLatLng(latLng);
        const orderNumbers = _.union(_.map(options.pickUpKeys, 'orderNumber'), _.map(options.deliveryKeys, 'orderNumber'));
        return _.filter(drives, (drive) => {
            return _.includes(orderNumbers, drive.orderNumber);
        })
    }

    const openShortBalloon = (e) => {
        const latLng = e.getSourceEvent().originalEvent.target.geometry.getCoordinates();
        const map = e.getSourceEvent().originalEvent.map;

        let objects = findDrivesByOptions(latLng);
        if (objects.length === 0) {
            objects = findObjectsByLatLng(latLng, drives);
        }

        const components = objects.map((object, key) => {
            const orderCategory = _.find(categories, {id: object.cleaningCategoryId})
            return renderToStaticMarkup(<DriveBalloon key={'drive-balloon-' + key}
                                                      category={orderCategory}
                                                      drive={object}/>);
        })

        let offset = [-1, -32]
        if (objects.length > 1) {
            offset = [0, -10]
        }

        if (objects.length > 0) {
            map.balloon.open(
                [objects[0].addressData.lat, objects[0].addressData.lng],
                components.join('<hr/>'), {
                    offset: offset
                }
            );
        }
    }

    useEffect(() => {
        if (mapRef.current !== null) {
            mapRef.current.events.remove('click');
            mapRef.current.geoObjects.events.remove('click');
            mapRef.current.events.add('click', function () {
                setFilterDrivesByIds([])
            });
            mapRef.current.geoObjects.events.add('click', function (e) {
                if (e.get('target').geometry.getType() === 'Polygon') {
                    setFilterDrivesByIds([])
                }
            });
        }
    }, [mapRef.current?.geoObjects])


    const closeInfoWindow = () => {
        removePolyline();
        dispatch(loadGeoObjectsInInfo([]))
    }

    return (<div>
            <EmployeeList
                onFilterEmployees={(name) => {
                    dispatch(filterEmployeesByName({name: name, employees: baseEmployeesList}))
                }}
                itemHeadRight={(employee) => {
                    const filteredDrivesInRoutes = _.filter(drivesInRoutes, {courier: employee.id})
                    return filteredDrivesInRoutes.length ?
                        <span
                            onClick={(e) => {
                                e.stopPropagation();
                                setFilterDrivesByIds(_.map(filteredDrivesInRoutes, 'drive.id'))
                            }}
                            className={routeStyles.RouteLength}>{filteredDrivesInRoutes.length}</span> : null
                }}
                onEmployeeOpen={() => {}}
                itemClassName={(employee) => {}}
                itemBody={(employee, isActive) => (
                    <EmployeeBody mapRef={mapRef} employee={employee} isActive={isActive}/>)}
                employees={employees}/>
            <YMaps>
                <Map width="100%" height="100vh"
                     instanceRef={mapRef}
                     onLoad={(ymaps) => {
                         setYmaps(ymaps)
                     }}
                     defaultState={{
                         center: [cityCenter.lat, cityCenter.lng], zoom: 10
                     }}>
                    {zonePolygons}
                    {employeesAddressesPlacemarks}
                    <Clusterer
                        onMouseenter={(e) => openShortBalloon(e)}
                        onMouseleave={(e) => closeBalloon(e)}
                        onClick={(e) => openInfoPanel(e)}
                        instanceRef={clustererRef}
                        options={{
                            groupByCoordinates: true,
                            disableClickZoom: true,
                            hasBalloon: false,
                            zIndex: 1
                        }}>
                        {drivePlacemarks}
                        {pickUpAndDeliveryKeyPlacemarks}
                    </Clusterer>
                    {polyline}
                </Map>
            </YMaps>
            {authKey !== null ? <WsClient/> : null}
            <Info show={geoObjectsInInfoWindow.length > 0}
                  x={'20%'}
                  y={'10px'}
                  width={'400px'}
                  onClick={() => {
                      closeInfoWindow()
                  }}>
                {geoObjectsInInfoWindow.map((geoObject, key) => {
                    const orderCategory = _.find(categories, {id: geoObject.cleaningCategoryId})
                    return <CleaningContent
                        onAddToRoute={(drive) => handleClickAddToRoute(drive)}
                        onOrderUpdate={(drive, props) => {
                            axios.put('/clsystem/api/v1/order/update?id=' + drive.orderNumber, props)
                                .then((response) => {
                                    dispatch(updateDrive({drive: drive, attributes: {order: response.data}}));
                                });
                        }}
                        drive={geoObject} key={key} category={orderCategory}/>
                })}
            </Info>
            <CentralPanel
                onCategoriesChange={(e) => {
                    setSelectedCategories(e);
                    dispatch(filterEmployeesByCategory({categories: e, employees: baseEmployeesList}))
                }}
                drives={drives}
                onMapChange={(e) => {
                    setCurrentMapId(e.currentMapId)
                }}/>
            <FilterPanel
                onShowCleaners={(type) => {
                    setShowEmployeesByType(type)
                }}
                onApply={(filtersValue) => {
                    closeInfoWindow();
                    loadEmployees(filtersValue);
                    loadDrives(filtersValue);
                    dispatch(deactivateActiveItem());
            }}/>
        </div>)
}

export default CleaningApp;