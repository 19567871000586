import axios from "axios";

export function fetchRoutes(params = {}) {
    return axios.get('/clsystem/api/v1/drives-in-route', {params: params})
}

export function updateRoute(params = {}) {
    //Адаптер для старого апи
    const route = params.route.map((driveInRoute) => (
        {data: driveInRoute.drive, courier: driveInRoute.courier, scheduleId: driveInRoute.scheduleId}
    ))
    return axios.put('/clsystem/api/v1/drives-in-route/' + params.routeId, {route: route})
}

export function createRoute(params = {}) {
    //Адаптер для старого апи
    const route = params.route.map((driveInRoute) => (
        {data: driveInRoute.drive, courier: driveInRoute.courier, scheduleId: driveInRoute.scheduleId}
    ))
    return axios.post('/clsystem/api/v1/drives-in-route', {date: params.date, route: route})
}