
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import styles from "./Employee.module.css"
import classNames from "classnames";
import _ from 'lodash';

const EmployeeItem = ({isActive, onClick, employee, headRight, body, className}) => {
    const classes = _.merge({[styles.EmployeeItem]: true, [styles.Active]: isActive}, className);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    return (
        <div className={classNames(classes)} onClick={onClick}>
            <div className={styles.EmployeeItemHead}>
                <div className={styles.EmployeeItemHeadLeft}>
                    <div className={styles.EmployeeIcon}/>
                    <h5 className={styles.EmployeeName} onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(employee.name)
                        }}>
                        <span>{employee.name}</span>
                        <a href={baseUrl + '/logistics/courier/' + employee.id} target="_blank" rel="noreferrer" className={styles.EmployeeEditIcon}>
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </a>
                    </h5>
                    <span className={styles.EmployeePhone} onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(employee.phone)
                        }}>
                        {employee.phone}
                    </span>
                </div>
                <div className={styles.EmployeeItemHeadRight}>
                    {headRight}
                </div>
            </div>
            <div className="clearfix"/>
            <div>
                {body}
            </div>
        </div>
    );
}

export default EmployeeItem;