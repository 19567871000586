import {Button, Col, Container, Row} from "react-bootstrap";
import styles from './Column.module.css'
import {useState} from "react";
import {ChromePicker} from "react-color";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faCancel} from "@fortawesome/free-solid-svg-icons";

const EditableColorColumn = ({defaultValue, onSave}) => {

    const [pickerIsOpen, setPickerIsOpen] = useState(false);
    const [color, setColor] = useState(defaultValue);

    return (
        <Container style={{padding: 0}}>
            <Row>
                <Col md={12} style={{position: 'relative'}}>
                    <div className={styles.EditableColorColumnPreview}
                         onClick={(e) => setPickerIsOpen(true)}
                         style={{backgroundColor: color}}/>
                    { pickerIsOpen ? <div className={styles.EditableColorColumnPicker}>
                        <ChromePicker color={color}
                                      onChangeComplete={(e) => {setColor('rgb(' + e.rgb.r + ', ' + e.rgb.g + ', ' + e.rgb.b + ')')}}/>
                        <hr/>
                        <div style={{textAlign: 'right'}}>
                            <Button size={"sm"} style={{marginRight: '10px'}}
                                    onClick={(e) => {
                                        onSave(color);
                                        setPickerIsOpen(false)
                                    }}
                                    variant={"success"}><FontAwesomeIcon icon={faSave}/></Button>
                            <Button size={"sm"}
                                    onClick={(e) => {
                                        setColor(defaultValue)
                                        setPickerIsOpen(false)
                                    }}
                                    variant={"danger"}><FontAwesomeIcon icon={faCancel}/></Button>
                        </div>
                    </div> : null }
                </Col>
            </Row>
        </Container>
    )
}

export default EditableColorColumn;