import {Breadcrumb, Col, Row} from "react-bootstrap";
import styles from './ZoneEditor.module.css'
import {CITY_LIST, SERVICES} from "../../../app/utils/const";
import _ from 'lodash'
import MapsTable from "./MapsTable";
import {useState} from "react";
import ZonesTable from "./ZonesTable";
import {style} from "../../../app/utils/load";
import PolygonDraw from "./PolygonDraw";

const GeoEditor = ({categoriesApiUrl, serviceId}) => {

    const service = _.find(SERVICES, {id: serviceId});
    const [maps, setMaps] = useState([]);
    const [drafts, setDrafts] = useState([]);
    const [mapId, setMapId] = useState(null);
    const [currentCity, setCurrentCity] = useState(null);
    const [renderIndex, setRenderIndex] = useState(0);
    const [editableZone, setEditableZone] = useState(null);
    let city = null;
    if (currentCity !== null) {
        city = _.find(CITY_LIST, {id: currentCity});
    }

    const incrementRenderIndex = () => {
        setRenderIndex(renderIndex + 1)
    }

    style('//cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css', 'bootstrap-css');
    return (
        <div className={styles.ZoneEditor}>
            <Breadcrumb>
                <Breadcrumb.Item>{service.name}</Breadcrumb.Item>
                <Breadcrumb.Item active={true}>
                    Редактор рабочих зон
                </Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col md={6}>
                    <MapsTable service={service} categoriesApiUrl={categoriesApiUrl}
                               onCityChange={(e) => setCurrentCity(e)}
                               onMapsLoad={(maps) => {
                                   setMaps(maps);
                                   setMapId(_.head(maps).mapId)
                               }}/>
                    <br/>
                    {maps.length && mapId ? <ZonesTable
                        onDraftsLoaded={(data) => {
                            setDrafts(data.drafts);
                            setMapId(data.currentMapId);
                        }}
                        onDelete={() => {
                            incrementRenderIndex();
                        }}
                        activeTab={mapId}
                        onStartEdit={(zone) => setEditableZone(zone)}
                        maps={maps}
                        key={renderIndex}
                        service={service}/> : null}
                </Col>
                <Col md={6} className={styles.DrawContainer}>
                    { city !== null && mapId ? <PolygonDraw
                        drafts={drafts.filter(draft => draft.isVisible)}
                        service={service}
                        mapId={mapId}
                        editable={editableZone}
                        onInput={(e) => {
                            incrementRenderIndex();
                        }}
                        city={city}/> : null}
                </Col>
            </Row>
        </div>
    );
}

export default GeoEditor