import {useDispatch, useSelector} from "react-redux";
import {
    selectWaypoints,
    updateDrive
} from "../../object/drycleaningMapObjectSlice";
import {useState} from "react";
import styles from './Content.module.css';
import {Input} from "@material-ui/core";
import Select from "react-select";
import DateFnsUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import {Button} from "react-bootstrap";
import _ from 'lodash'
import axios from "axios";
import {updateDriveInRoute} from "../../route/routeSlice";

const Transportation = ({drive}) => {

    const dispatch = useDispatch();
    const waypoints = useSelector(selectWaypoints)
    const transportation = drive.transportation;
    const [transportationDate, setTransportationDate] = useState(moment(transportation.date, 'DD-MM-YYYY'));
    const [transportationStartWayPoint, setTransportationStartWayPoint] = useState(transportation.startWayPoint);
    const [transportationFinishWayPoint, setTransportationFinishWayPoint] = useState(transportation.finishWayPoint);
    const [isSaved, setIsSaved] = useState(false);

    const save = () => {
        const request = axios.put("/lsystem/api/v1/transportation/" + transportation.id, {
            order_id: transportation.orderNumber,
            drive_id: drive.id,
            start_way_point_id: transportationStartWayPoint.id,
            finish_way_point_id: transportationFinishWayPoint.id,
            date: transportationDate.format('DD-MM-YYYY'),
        })

        request.then((response) => {
            setIsSaved(true);
            dispatch(updateDrive({
                drive: drive,
                attributes: {
                    transportation: response.data
                }
            }))
            dispatch(updateDriveInRoute({
                drive: drive,
                attributes: {
                    transportation: response.data
                }
            }));
        })
    }

    return (
        <div key={drive.id} className={styles.ContentBody}>
            <h5>Перевозка с фабрики</h5>
            {isSaved ? <span style={{"color": "green", "display": "block"}}>Перевозка сохранена</span> : null}
            <label htmlFor="">Номер заказа</label>
            <div className="clearfix"></div>
            <Input defaultValue={transportation.orderNumber} readOnly={true}/>
            <div className="clearfix"></div>
            <label htmlFor="">Откуда</label>
            <div className="clearfix"></div>
            <Select
                value={{value: transportationStartWayPoint.id, label: transportationStartWayPoint.name}}
                options={waypoints.map((waypoint) => ({value: waypoint.id, label: waypoint.name}))}
                onChange={(e) => {
                    setTransportationStartWayPoint(_.find(waypoints, {id: e.value}))
                }}
            />
            <label htmlFor="">Куда</label>
            <div className="clearfix"></div>
            <Select
                value={{value: transportationFinishWayPoint.id, label: transportationFinishWayPoint.name}}
                options={waypoints.map((waypoint) => ({value: waypoint.id, label: waypoint.name}))}
                onChange={(e) => {
                    setTransportationFinishWayPoint(_.find(waypoints, {id: e.value}))
                }}
            />
            <label htmlFor="">Дата</label>
            <div className="clearfix"></div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={'ru'}>
                <DatePicker
                    value={transportationDate.format("YYYY-MM-DD")}
                    onChange={(e) => {
                        setTransportationDate(e);
                    }}
                    disableToolbar
                    autoOk
                    variant={'inline'}
                    format={'YYYY-MM-DD'}
                />
            </MuiPickersUtilsProvider>
            <div className="clearfix"></div>
            <label htmlFor="">Вещи</label>
            <div className="clearfix"></div>
            {transportation.goods.map((good, key) => (
                <Button style={{"margin": "0 5px 5px 0"}}
                        key={key}
                        size={'sm'} variant={"primary"}>{good.name} ({good.qty})</Button>
            ))}
            <div className="clearfix"></div>
            <br/>
            <Button size={'sm'} variant={"success"} onClick={save}>Сохранить</Button>
        </div>
    );
}

export default Transportation;