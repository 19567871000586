import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import _ from "lodash";
import {fetchAllEmployees} from "./employeeApi";

export const loadAllEmployeesAsync = createAsyncThunk(
    'employees/list-all',
    async (params= {}) => {
        const response = await fetchAllEmployees(params);
        return response.data;
    }
);

export const cleaningEmployeeSlice = createSlice({
    name: 'cleaningEmployee',
    initialState: {
        filteredEmployees: [],
        filterIsApply: false,
        positions: [],
        allEmployees: []
    },
    reducers: {
        filterEmployeesByName: (state, action) => {
            if (action.payload.name !== '') {
                state.filterIsApply = true;
                state.filteredEmployees = action.payload.employees.filter((employee) => {
                    return employee.name.toLowerCase().indexOf(action.payload.name.toLowerCase()) !== -1;
                })
            } else {
                state.filterIsApply = false;
                state.filteredEmployees = [];
            }
        },
        filterEmployeesByCategory: (state, action) => {
            const selectedCategoriesWithoutEmpty = _.reject(action.payload.categories, {categoryId: null});
            if (selectedCategoriesWithoutEmpty.length) {
                state.filterIsApply = true;
                let filteredEmployees = [];
                _.each(selectedCategoriesWithoutEmpty, (category) => {
                    filteredEmployees = _.union(filteredEmployees, action.payload.employees.filter((employee) => {
                        return _.includes(employee.categories, category.categoryId);
                    }))
                })
                state.filteredEmployees = filteredEmployees
            } else {
                state.filterIsApply = false;
                state.filteredEmployees = [];
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadAllEmployeesAsync.fulfilled, (state, action) => {
                state.allEmployees = action.payload;
                state.filteredEmployees = [];
            });
    },
});

export const {filterEmployeesByName, filterEmployeesByCategory} = cleaningEmployeeSlice.actions;
export const selectFilteredEmployees = (state) => state.employee.cleaningEmployeeReducer.filteredEmployees;
export const selectFilterIsApply = (state) => state.employee.cleaningEmployeeReducer.filterIsApply;
export const selectAllEmployees = (state) => state.employee.cleaningEmployeeReducer.allEmployees;
export default cleaningEmployeeSlice.reducer;