import { useState, useEffect } from 'react';
import useWebSocket  from 'react-use-websocket';
import _ from 'lodash'
import {useDispatch, useSelector} from "react-redux";
import {
    removeDrive,
    updateDrive
} from "../object/drycleaningMapObjectSlice";
import {
    addToRoute, removeFromRoute,
    selectDrivesInRoutes,
    updateDriveInRoute
} from "../route/routeSlice";
import {
    removeEmployee,
    selectEmployees,
    updateEmployee
} from "../../employee/employeeSlice";
import {
    selectFiltersValue,
} from "../filter/filterSlice";
import styles from './Ws.module.css'
import {Alert} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faClose } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import {selectAuthKey} from "../../ws/wsSlice";
import {selectPositions, setPositions} from "../employee/employeeSlice";

const WsClient = () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const authKey = useSelector(selectAuthKey)
    const [socketUrl] = useState(baseUrl.replace('https', 'wss') + '/lsystem-socket?auth_key=' + authKey);
    const dispatch = useDispatch();
    const {lastMessage, sendJsonMessage} = useWebSocket(socketUrl, {shouldReconnect: () => true});
    const drivesInRoutes = useSelector(selectDrivesInRoutes);
    const employees = useSelector(selectEmployees);
    const filtersValue = useSelector(selectFiltersValue);
    const [messages, addMessage] = useState([]);
    const currentPositions = useSelector(selectPositions);

    const mergeMessages = (message) => {
        if (message !== null) {
            addMessage(_.union(messages, [message]));
        }
    }

    const dismissMessage = (message) => {
        const messagesWithText = _.reject(messages, (messageWithText) => {
            return messageWithText.text !== message.text;
        });
        addMessage(_.without(messages, ...messagesWithText));
    }

    const updateDriveAttributes = (drive) => {
        dispatch(updateDrive({
            drive: drive,
            attributes: {...drive}
        }))
    }

    const eventHandlers = {
        routeItemsEvent: {
            add(data) {
                _.each(data.entities, (drive) => {
                   updateDriveAttributes(drive);
                    const routeId = _.property('id')(drive.route);
                    const formattedDriveInRoute = {
                        courier: drive.courierId,
                        drive: drive
                    };

                    const routeExist = _.find(drivesInRoutes, {id: routeId});
                    if (typeof routeExist !== "undefined") {
                        dispatch(addToRoute(formattedDriveInRoute))
                    }

                    if (filtersValue && filtersValue.default === true) {
                        // убирает с карты после добавления в маршрут и сохранения
                        // IT-3899 После распределения выезд исчезает с карты (логика):
                        // если на карте отображены все выезды, то после распределения не скрывать их;
                        // если на карте отображены только нераспределенные выезды, то после распределения скрывать их.
                        dispatch(removeDrive({id: drive.id}));
                    }
                    mergeMessages(data.msg)
                })
            },
            change(data) {
                _.each(data.entities, (drive) => {
                    updateDriveAttributes(drive);
                    const driveInRoute = _.find(drivesInRoutes, (driveInRoute) => {
                        return driveInRoute.drive.id === drive.id
                    });
                    if (typeof driveInRoute !== "undefined") {
                        dispatch(updateDriveInRoute({drive: drive, attributes: drive}))
                        mergeMessages(data.msg)
                    }
                });
            },
            remove(data) {
                _.each(data.entities, (drive) => {
                    updateDriveAttributes(drive);
                    const driveInRoute = _.find(drivesInRoutes, (driveInRoute) => {
                        return driveInRoute.drive.id === drive.id
                    });
                    if (typeof driveInRoute !== "undefined") {
                        dispatch(removeFromRoute(driveInRoute))
                        mergeMessages(data.msg)
                    }
                });
            }
        },
        notificationEvent: {
            add(data) {
                mergeMessages(data.msg)
            },
            dismiss(data, channel) {
                if (channel !== authKey && channel !== null) {
                    return;
                }
                dismissMessage(data.msg);
            }
        },
        courierEvent: {
            change(data) {
                _.each(data.entities, function (entity) {
                    const employee = _.find(employees, {
                        id: entity.id,
                        date: entity.date
                    });
                    if (typeof employee !== "undefined") {
                        dispatch(updateEmployee({id: employee.id, attributes: employee}))
                    }
                });
            },
            remove(data) {
                _.each(data.entities, function (entity) {
                    const employee = _.find(employees, {
                        id: entity.id,
                        date: entity.date
                    });
                    if (typeof employee !== "undefined") {
                        dispatch(removeEmployee({id: employee.id}))
                        mergeMessages(data.msg)
                    }
                });
            }
        },
        positionEvent: {
            add(data) {
                const currentPositionsWithoutCourier = _.without(currentPositions, _.find(currentPositions, {courier: data.courier}))
                dispatch(setPositions(_.union(currentPositionsWithoutCourier, [data])))
            }
        }
    };

    useEffect(() => {
        if (lastMessage !== null) {
            const wsEvent = JSON.parse(lastMessage.data);
            const eventHandler = eventHandlers[wsEvent.type];
            if (typeof eventHandler !== "undefined" && wsEvent.owner === 'lsystem') {
                eventHandler[wsEvent.event].bind(eventHandlers, wsEvent.data, wsEvent.channel)();
            }
        }
    }, [lastMessage]);

    return (
        <div className={styles.WsWrap}>
            {messages.filter((message) => {
                return message.text && (message.showAlert === true || typeof message.showAlert === 'undefined')
            }).map((message, key) => (
                <Alert transition={false} variant={'success'} className={styles.Alert} key={key}>
                    {message.text}
                    <FontAwesomeIcon
                        onClick={(e) => {
                            axios.post('/lsystem/api/v1/message/dismiss', { msg: message.text})
                            _.each(['lsystem', 'admin.notificator'], (owner) => {
                                sendJsonMessage({
                                    type: "notificationEvent",
                                    event: "dismiss",
                                    channel: authKey,
                                    owner: owner,
                                    data: message
                                });
                            });
                            dismissMessage(message)
                        }}
                        className={styles.CloseBtn}
                        icon={faClose}/>
                </Alert>
            ))}
        </div>
    )
}

export default WsClient