import {createSlice} from "@reduxjs/toolkit";
import _ from "lodash";

export const drycleaningEmployeeSlice = createSlice({
    name: 'drycleaningEmployee',
    initialState: {
        filteredEmployees: [],
        filterIsApply: false,
        positions: []
    },
    reducers: {
        setPositions: (state, action) => {
            state.positions = action.payload;
        },
        filterEmployeesByName: (state, action) => {
            if (action.payload.name !== '') {
                state.filterIsApply = true;
                state.filteredEmployees = action.payload.employees.filter((employee) => {
                    return employee.name.toLowerCase().indexOf(action.payload.name.toLowerCase()) !== -1;
                })
            } else {
                state.filterIsApply = false;
                state.filteredEmployees = [];
            }
        },
        filterEmployeesByTime: (state, action) => {
            if (action.payload.timeslots.length) {
                state.filterIsApply = true;
                state.filteredEmployees = action.payload.employees.filter((employee) => {
                    let includeTimeslots = false;
                    _.each(employee.workTime.all, function (time) {
                        if (_.includes(action.payload.timeslots, time) && !includeTimeslots) {
                            includeTimeslots = true;
                        }
                    });
                    return includeTimeslots
                });
            } else {
                state.filterIsApply = false;
                state.filteredEmployees = [];
            }
        }
    }
});

export const {filterEmployeesByTime, filterEmployeesByName, setPositions, setPositionPlacemarks} = drycleaningEmployeeSlice.actions;
export const selectFilteredEmployees = (state) => state.employee.drycleaningEmployeeReducer.filteredEmployees;
export const selectFilterIsApply = (state) => state.employee.drycleaningEmployeeReducer.filterIsApply;
export const selectPositions = (state) => state.employee.drycleaningEmployeeReducer.positions;
export default drycleaningEmployeeSlice.reducer;