
import {useCity} from "../../../app/utils/use";
import {useDispatch, useSelector} from "react-redux";
import {CLEANING_SERVICE, CITY_LIST} from "../../../app/utils/const";
import {useEffect, useState} from "react";
import {
    loadCategoriesAsync, loadCategoriesViaMapsAsync,
    loadMapsAsync,
    selectCategories,
    selectCategoriesViaMaps,
    selectCurrentMapId,
    selectMaps,
    setCurrentMapId
} from "./centralPanelSlice";
import styles from "./Panel.module.css";
import {Button, Col, Dropdown, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBars,
    faCircleCheck,
    faCity
} from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash'
import classNames from "classnames";
import OutsideClickHandler
    from "react-outside-click-handler/esm/OutsideClickHandler";

const CentralPanel = ({onMapChange, onCategoriesChange, drives}) => {

    const cityId = useCity();
    const dispatch = useDispatch();
    const city = _.find(CITY_LIST, {id: cityId});
    const maps = useSelector(selectMaps);
    const categories = useSelector(selectCategories);
    const categoriesViaMaps = useSelector(selectCategoriesViaMaps);
    const currentMapId = useSelector(selectCurrentMapId);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [counterContentIsOpen, setCounterContentIsOpen] = useState(null);
    const [categoriesMenuIsOpen, setCategoriesMenuIsOpen] = useState(false);

    useEffect(() => {
        dispatch(loadMapsAsync({
            cityIds: [cityId],
            serviceIds: [CLEANING_SERVICE]
        }))
    }, [cityId]);

    useEffect(() => {
       if (categoriesViaMaps.length) {
           dispatch(loadCategoriesAsync({
               cityId: cityId,
               serviceId: CLEANING_SERVICE
           }));
       }
    }, [categoriesViaMaps]);


    useEffect(() => {
        if (maps.length > 0) {
            dispatch(setCurrentMapId(maps[0].mapId));
            dispatch(loadCategoriesViaMapsAsync({
                mapIds: _.map(maps, 'mapId')
            }))
        }
    }, [maps]);

    const filterCategoriesViaMaps = (mapId, categories) => {
        const categoriesByCurrentMap = _.filter(categoriesViaMaps, {mapId: mapId});
        const externalCategoryIds = _.map(categoriesByCurrentMap, 'externalCategoryId');
        return _.filter(categories, (category) => {
            return _.includes(externalCategoryIds, category.id)
        })
    }

    const updateCategories = (categories) => {
        const selectedCategoriesWithoutEmpty = _.reject(categories, {categoryId: null});
        if (selectedCategoriesWithoutEmpty.length === 0) {
            setSelectedCategories([{categoryId: null, name: 'Все категории'}])
        } else {
            setSelectedCategories(selectedCategoriesWithoutEmpty)
        }
    }

    const findUndistributedDrives = (mapId) => {
        return _.filter(drives, (drive) => {
            return drive.addressData.zone !== null &&
                drive.addressData.zone.mapId === mapId &&
                _.includes(['Не распределён', 'Ожидает подтверждения'], drive.order.status.name)
        })
    }

    const renderCounter = (mapId) => {
        const drives = findUndistributedDrives(mapId);
        if (drives.length === 0) {
            return null;
        }
        return (
            <OutsideClickHandler
                onOutsideClick={() => {
                    setCounterContentIsOpen(null)
                }}>
                <div className={styles.CounterWrap} onBlur={(e) => console.log(e)}>
                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        setCounterContentIsOpen(mapId)
                    }}
                    className={styles.Count}><span className={styles.InternalCount}>{drives.length}</span></span>
                    {counterContentIsOpen === mapId ? <div className={styles.CounterContent}>
                        {_.map(drives, (drive, key) => (
                            <div className={styles.CounterContentRow} key={'counter-content-' + key}>
                                <Row>
                                    <Col md={3}><Button size={'sm'} variant={"success"}>{drive.orderNumber}</Button></Col>
                                    <Col md={4} className={styles.CounterContentItem}>
                                        <Row>
                                            <Col md={12}>
                                                <span>Исполнитель:</span>
                                            </Col>
                                            <Col md={12}>
                                                {drive.cleanerTrimName}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={5}  className={styles.CounterContentItem}>
                                        <Row>
                                            <Col md={12}>
                                                <span>Статус заказа:</span>
                                            </Col>
                                            <Col md={12}>
                                            <span className={classNames({[styles.Status]: true, [drive.statusLabel]: true})}>
                                                {drive.order.status.name}
                                            </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </div> : null}
                </div>
            </OutsideClickHandler>
        )
    }

    useEffect(() => {
        if (selectedCategories.length > 0) {
            onCategoriesChange(selectedCategories);
        }
    }, [selectedCategories]);

    useEffect(() => {
        onMapChange({currentMapId: currentMapId});
        setSelectedCategories([]);
        updateCategories([])
    }, [currentMapId]);

    return (
        <div className={styles.CentralPanel}>
            <div className={styles.CentralPanelBody}>
                <Row>
                    <Dropdown className={styles.Dropdown} id={styles['CityDropdown']}>
                        <Dropdown.Toggle variant="light">
                            <FontAwesomeIcon icon={faCity}/> {city.name}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {CITY_LIST.map((city) => (
                                <Dropdown.Item href={'/cleaning/map/?cityId=' + city.id} key={'city-link-' + city.id}>
                                    {city.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    {maps.map((item) => (
                        <div className={styles.MenuButtonWrap} key={'map-btn-' + item.mapId}>
                            <Button variant={'light'}
                                    onClick={(e) => dispatch(setCurrentMapId(item.mapId))}
                                    className={classNames({[styles.MenuButton]: true, [styles.MenuButtonActive]: currentMapId === item.mapId})}>
                                {item.name}

                            </Button>
                            {renderCounter(item.mapId)}
                        </div>
                    ))}
                    <Dropdown show={categoriesMenuIsOpen}
                              autoClose={false} id={styles['CategoriesDropdown']}
                              className={classNames({[styles.Dropdown]: true})}>
                        <Dropdown.Toggle id={'categories-button'} variant="light">
                            <FontAwesomeIcon icon={faBars}/> Категории
                        </Dropdown.Toggle>
                        <OutsideClickHandler onOutsideClick={(e) => {
                            if (e.target.id === 'categories-button') {
                                setCategoriesMenuIsOpen(!categoriesMenuIsOpen)
                            } else {
                                setCategoriesMenuIsOpen(false)
                            }
                        }}>
                            <Dropdown.Menu>
                                <Col md={10}>Все категории</Col>
                                <Col md={2}><Form.Check
                                    checked={typeof _.find(selectedCategories, {categoryId: null}) !== "undefined"}
                                    onChange={(e) => {
                                        const checked = e.target.checked;
                                        if (checked === true) {
                                            updateCategories([{
                                                categoryId: null,
                                                name: 'Все категории'
                                            }])
                                        }
                                    }} type="checkbox"/></Col>
                                {categories.length ? filterCategoriesViaMaps(currentMapId, categories).map((category) => (
                                    <div className={styles.DropdownWrap} key={'category-link-' + category.id}>
                                        <Col md={10}>{category.name}</Col>
                                        <Col md={2}><Form.Check
                                            checked={typeof _.find(selectedCategories, {categoryId: category.id}) !== "undefined"}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                if (checked === true) {
                                                    updateCategories(_.union(selectedCategories, [{
                                                        categoryId: category.id,
                                                        name: category.name
                                                    }]))
                                                } else {
                                                    const without = [];
                                                    _.each(selectedCategories, (selectedCategory) => {
                                                        if (selectedCategory.categoryId !== category.id) {
                                                            without.push(selectedCategory);
                                                        }
                                                    })
                                                    updateCategories(without)
                                                }
                                            }} type="checkbox"/></Col>
                                    </div>
                                )) : null}
                            </Dropdown.Menu>
                        </OutsideClickHandler>
                    </Dropdown>
                    <div className={styles.CategoriesWrap}>
                        {selectedCategories.map((category) => (
                            <span key={category.categoryId}><FontAwesomeIcon icon={faCircleCheck}/> {category.name}</span>
                        ))}
                    </div>
                </Row>
            </div>
        </div>
    )
}

export default CentralPanel;