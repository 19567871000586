import styles from "./ZoneEditor.module.css";
import {Button, Modal, Tab, Tabs} from "react-bootstrap";
import {CITY_LIST, DEFAULT_CITY} from "../../../app/utils/const";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faEdit, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import {Input} from "@material-ui/core";
import Select from "react-select";
import {useEffect, useState} from "react";

const MapsTable = ({service, categoriesApiUrl, onMapsLoad, onCityChange}) => {
    const [currentCity, setCurrentCity] = useState(DEFAULT_CITY);
    const [currentMaps, setCurrentMaps] = useState([]);
    const [mapsTable, setMapsTable] = useState(null);
    const [categories, setCategories] = useState([]);
    const [mapInModal, setMapInModal] = useState(null);

    useEffect(() => {
        axios.get(categoriesApiUrl).then((response) => {
            setCategories(response.data.models)
        })
    }, [])

    const updateMap = (map) => {
        setMapInModal(map);
    }

    const deleteMap = (map) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Удалить карту?") === true) {
            axios.post('/geo/api/v1/map/delete', {mapId: map.mapId}).then(() => {
                refreshMaps();
            })
        }
    }

    const mapsTableColumns = [
        { text: "ID", dataField: "mapId" },
        {
            text: "Название",
            dataField: "name",
            style: () => {
                return {
                    width: '120px'
                }
            }
        },
        {
            text: "Категории",
            dataField: "categories",
            style: () => {
                return {
                    textAlign: 'left'
                }
            },
            formatter: (cell, row) => {
                return row.categories.map((category, index) => {
                    const categoryInCatalog = _.find(categories, {categoryId: category.externalCategoryId})
                    if (categoryInCatalog) {
                        return (
                            <div key={index} className={styles.CategoryLabel}>{categoryInCatalog.name}</div>
                        )
                    } else {
                        return (
                            <div key={Math.random()} className={styles.CategoryLabel}>неизвестная категория id:{category.externalCategoryId}</div>
                        );
                    }
                })
            }
        },
        {
            text: "",
            dataField: "update",
            style: () => {
                return {
                    width: '50px'
                }
            },
            formatter: (cell, row) => {
                return <FontAwesomeIcon onClick={(e) => updateMap(row)}
                                        className={styles.ActionIcon} icon={faEdit}/>
            }
        },
        {
            text: "",
            dataField: "delete",
            style: () => {
                return {
                    width: '50px'
                }
            },
            formatter: (cell, row) => {
                return <FontAwesomeIcon onClick={(e) => deleteMap(row)}
                                        className={styles.CloseIcon} icon={faClose}/>
            }
        }
    ]

    useEffect(() => {
        refreshMaps();
        onCityChange(currentCity)
    }, [currentCity])

    const refreshMaps = () => {
        const params = {
            params: {
                cityIds: [currentCity],
                serviceIds: [service.id]
            }
        };
        axios.get('/geo/api/v1/map', params).then((mapsResponse) => {
            const maps = mapsResponse.data.models;
            axios.get('/geo/api/v1/map/get-categories', {params: {mapIds: _.map(maps, 'mapId')}}).then((categoriesResponse) => {
                _.each(maps, (map) => {
                    map.categories = _.filter(categoriesResponse.data.models, {mapId: map.mapId});
                })
                setCurrentMaps(maps);
                onMapsLoad(maps);
            });
        })
    }

    const persistMap = () => {
        const request = axios.post('/geo/api/v1/map/persist', mapInModal);
        request.then((response) => {
            refreshMaps();
            setMapInModal(null);
        })
    }

    useEffect(() => {
        setMapsTable(<BootstrapTable keyField='mapId' data={currentMaps} columns={mapsTableColumns} />)
    }, [currentMaps]);

    const renderModal = () => {
        if (mapInModal !== null) {
            return (
                <Modal centered show={mapInModal !== null} onHide={(e) => setMapInModal(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{mapInModal.mapId
                            ? 'Обновление карты ' + mapInModal.name
                            : 'Создание новой карты'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div><label><strong>Название карты</strong></label></div>
                        <Input defaultValue={mapInModal.name} onInput={(e) => mapInModal.name = e.target.value}/>
                        <div className="clearfix"></div>
                        <br/>
                        <div style={{marginBottom: '10px'}}><label><strong>Выберите категории услуг</strong></label></div>
                        <Select
                            closeMenuOnSelect={false}
                            noOptionsMessage={() => "Категорий больше нет"}
                            placeholder={'Категорий ещё нет'}
                            isMulti defaultValue={mapInModal.categories.map((category) => {
                            const categoryInCatalog = _.find(categories, {categoryId: category.externalCategoryId})
                            return {
                                value: categoryInCatalog.categoryId,
                                label: categoryInCatalog.name
                            }
                        })}
                            options={categories.map((category) => ({value: category.categoryId, label: category.name}))}
                            onChange={(categories) => {mapInModal.categories = categories.map((category) => {
                                return {
                                    externalCategoryId: category.value
                                }
                            })}}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={(e) => setMapInModal(null)}>
                            Отменить
                        </Button>
                        <Button variant="warning" onClick={(e) => persistMap()}>
                            Сохранить
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        }
        return null;
    }

  return (
      <div>
          {renderModal()}
          <div className={styles.Label}><label>Город</label></div>
          <Tabs defaultActiveKey={currentCity} onSelect={(e) => setCurrentCity(parseInt(e))} className="mb-3">
              {CITY_LIST.map((city) => (
                  <Tab title={city.name} key={city.id} eventKey={city.id}>
                      {mapsTable}
                  </Tab>
              ))}
          </Tabs>
          <div className={styles.AddMapIconContainer} onClick={(e) => {
              setMapInModal({
                  name: '',
                  categories: [],
                  serviceId: service.id,
                  cityId: currentCity
              })
          }}>
              <FontAwesomeIcon className={styles.AddMapIcon} icon={faPlusCircle}/> Добавить карту
          </div>
      </div>
  )
}

export default MapsTable;