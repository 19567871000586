import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchDrives, fetchZones} from "./mapObjectAPI";
import _ from "lodash";

const initialState = {
    drives: [],
    zones: [],
    geoObjectsInInfoWindow: []
};

export const loadDrivesAsync = createAsyncThunk(
    'drycleaning/drives',
    async (params= {}) => {
        const response = await fetchDrives(params);
        return response.data;
    }
);

export const loadZonesAsync = createAsyncThunk(
    'drycleaning/zones',
    async (params= {}) => {
        const response = await fetchZones(params);
        return response.data;
    }
);

export const cleaningMapObjectSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        clearDrives(state) {
            state.drives = [];
        },
        clearZones(state) {
            state.zones = [];
        },
        loadGeoObjectsInInfo(state, action) {
            state.geoObjectsInInfoWindow = action.payload
        },
        removeDrive(state, action) {
            state.drives = _.without(state.drives, _.find(state.drives, (drive) => (
                drive.id === action.payload.id
            )));
            state.geoObjectsInInfoWindow = _.without(state.geoObjectsInInfoWindow, _.find(state.geoObjectsInInfoWindow, (geoObject) => (
                geoObject.id === action.payload.id
            )));
        },
        updateDrive(state, action) {
            const driveInState = _.find(state.drives, (drive) => (
                drive.id === action.payload.drive.id
            ))

            const driveInInfoWindow = _.find(state.geoObjectsInInfoWindow, (geoObject) => (
                geoObject.id === action.payload.drive.id
            ))

            if (typeof driveInState !== "undefined") {
                const date = action.payload.attributes.date;
                if (typeof date !== "undefined" && date !== driveInState.date) {
                    state.drives = _.without(state.drive, driveInState);
                }
                _.each(action.payload.attributes, (value, key) => {
                    driveInState[key] = value;
                })
            }

            if (typeof driveInInfoWindow !== "undefined") {
                _.each(action.payload.attributes, (value, key) => {
                    driveInInfoWindow[key] = value;
                })
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadDrivesAsync.fulfilled, (state, action) => {
                state.drives = action.payload;
            });
        builder
            .addCase(loadZonesAsync.fulfilled, (state, action) => {
                state.zones = action.payload.models;
            });
    },
});

export const {clearZones, clearDrives, updateDrive, removeDrive, loadGeoObjectsInInfo} = cleaningMapObjectSlice.actions;

export const selectDrives = (state) => state.cleaningMapObject.drives;
export const selectZones = (state) => state.cleaningMapObject.zones;
export const selectGeoObjectsInInfoWindow = (state) => state.cleaningMapObject.geoObjectsInInfoWindow;

export default cleaningMapObjectSlice.reducer;