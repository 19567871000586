import styles from "./Balloon.module.css";
import {Col, Row} from "react-bootstrap";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faClock
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import balloonStyles from "./Balloon.module.css";

const DriveBalloon = ({drive, category}) => {
    return (
        <div className={styles.BalloonBody}>
            <Row>
                <Col md={12}>
                    <span className={styles.OrderNumber}>
                        <a target="_blank"
                           rel="noreferrer"
                           href={axios.defaults.baseURL + '/cleaning/' + drive.orderNumber}
                        >
                            {drive.orderNumber}
                        </a>
                    </span>
                </Col>
                <Col md={12}>
                    <span className={styles.Category}>{category.name}</span>
                </Col>
                <Col md={12}>
                    <span className={classNames({[styles.Status]: true, [drive.statusLabel]: true})}>
                        {drive.order.status.name}
                    </span>
                </Col>
                <Col md={12}>
                    <span className={styles.Time}>
                        <FontAwesomeIcon icon={faClock}/> {drive.time}
                    </span>
                </Col>
                {drive.cleanerTrimName ? <Col md={12}>
                    <span className={balloonStyles.Cleaner}>
                        Клинер - {drive.cleanerTrimName}
                    </span>
                </Col> : null}
            </Row>
        </div>
    );
}

export default DriveBalloon;