import RightPanel from "../../panel/RightPanel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faFilter, faClose } from '@fortawesome/free-solid-svg-icons'
import styles from './Filter.module.css'
import {useEffect, useState} from "react";
import {Button, Col, FormCheck, Row} from "react-bootstrap";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/moment';
import "moment/locale/ru";
import Select from 'react-select';
import {
    modifyFilterValue,
    loadFiltersAsync,
    selectFiltersData,
    selectFiltersValueDate,
    selectFiltersValueZones,
    selectFiltersValue,
    selectIsRefreshed,
    refreshFiltersValue,
    toggleIsRefreshedState
} from "./filterSlice";
import {useDispatch, useSelector} from "react-redux";
import {useCity} from "../../../app/utils/use";
import _ from 'lodash'
import {Input} from "@material-ui/core";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import moment from "moment";
import {filterEmployeesByTime} from "../employee/employeeSlice";
import {selectEmployees} from "../../employee/employeeSlice";

const MapFilter = ({onApply, onCityChange}) => {

    const [filterIsOpen, toggleFilterOpen] = useState(false)
    const [filterApplied, setFilterApplied] = useState(false)
    const [filterVersion, updateFilterVersion] = useState(moment().valueOf())
    const dateInValue = useSelector(selectFiltersValueDate);
    const zonesInValue = useSelector(selectFiltersValueZones);
    const employeesList = useSelector(selectEmployees);
    const filtersData = useSelector(selectFiltersData);
    const filtersValue = useSelector(selectFiltersValue);
    const dispatch = useDispatch();
    const cityId = useCity();
    const employees = useSelector(selectEmployees);
    const isRefreshed = useSelector(selectIsRefreshed);
    const icon = !filterIsOpen ? faFilter : faClose;
    let statuses = Object.keys(filtersData.statuses).map((id) => ({value: id, label: filtersData.statuses[id]}))
    statuses = statuses.filter(status => !_.includes([2, 11, 10], parseInt(status.value)))

    let timeslots = filtersData.timeslots.map((timeslot) => (timeslot));
    timeslots = _.chunk(timeslots, Math.ceil(timeslots.length / 2));

    const timeslotsFirstCheckboxGroup = timeslots.map((timeslotGroup, key) => (
        timeslotGroup.map((timeslot) => (
            <FormCheck
                inline
                label={timeslot}
                name={'group-' + key}
                key={'check-' + timeslot}
                onChange={(e) => {
                    dispatch(modifyFilterValue({
                        key: 'timeslots',
                        value: e.target.checked ? [timeslot] : timeslot,
                        strategy: e.target.checked ? 'union' : 'without'
                    }))
                }}
            />
        ))
    ))

    useEffect(() => {
        dispatch(filterEmployeesByTime({
            timeslots: filtersValue.timeslots,
            employees: employees
        }))
    }, [filtersValue.timeslots])

    useEffect(() => {
        if (filterApplied === true) {
            onApply(filtersValue);
            setFilterApplied(false);
        }
    }, [filterApplied])

    useEffect(() => {
        if (isRefreshed === true) {
            updateFilterVersion(moment().valueOf());
            setFilterApplied(true)
            dispatch(toggleIsRefreshedState(false));
        }
    }, [isRefreshed])

    useEffect(() => {
        dispatch(loadFiltersAsync({cityId: cityId}))
        dispatch(refreshFiltersValue());
        dispatch(modifyFilterValue({
            key: 'date', value: dateInValue
        }))
        updateFilterVersion(moment().valueOf());
        onApply(filtersValue);
        onCityChange(filtersValue);
    }, [cityId])

    let filteredZones = [];
    Object.keys(filtersData.zones).forEach((groupName) => {
        if (!_.includes(['Общее', 'Специалист'], groupName.trim())) {
            filteredZones[groupName] = filtersData.zones[groupName];
        }
    })

    const zones = Object.keys(filteredZones).map(groupName => {
        const childIds = _.map(filteredZones[groupName], 'id');
        const groupCheckbox = <FormCheck
            inline
            key={'group-' + groupName}>
            <FormCheckInput onChange={(e) => {
                dispatch(modifyFilterValue({
                    key: 'zones',
                    value: e.target.checked ? childIds : childIds,
                    strategy: e.target.checked ? 'union' : 'without'
                }))
            }}/>
            <FormCheckLabel><strong style={{fontSize: '16px'}}>{groupName}</strong></FormCheckLabel>
        </FormCheck>

        const child = _.chunk(
            filteredZones[groupName] || [],
            Math.ceil(filteredZones[groupName].length / 2)
        );

        const childCheckboxes = child.map((childGroup) => (
            childGroup.map((child, key) => (
                <FormCheck
                    inline
                    label={child.name}
                    checked={_.includes(zonesInValue, child.id)}
                    name={groupName + '-' + key}
                    key={groupName + '-' + child.id}
                    onChange={(e) => {
                        dispatch(modifyFilterValue({
                            key: 'zones',
                            value: e.target.checked ? [child.id] : child.id,
                            strategy: e.target.checked ? 'union' : 'without'
                        }))
                    }}
                />
            ))
        ))
        return [groupCheckbox, childCheckboxes]
    })

    return (
        <div key={filterVersion}>
            <div className={styles.ToggleFilterBtn} onClick={() => {toggleFilterOpen(!filterIsOpen)}}>
                <FontAwesomeIcon icon={icon}/>
            </div>
            <RightPanel isOpen={filterIsOpen} width={'18%'}>
                <div className={styles.FiltersWrap}>
                    <Button variant={'success'}
                            onClick={(e) => {
                                dispatch(modifyFilterValue({
                                    key: 'default', value: false
                                }))
                                setFilterApplied(true);
                                }}>Применить</Button>
                    <br/>
                    <br/>
                    <label htmlFor=""><h6>Дата</h6></label>
                    <br/>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={'ru'}>
                        <DatePicker
                            value={dateInValue}
                            onChange={(date) => dispatch(modifyFilterValue({
                                key: 'date', value: date.format('YYYY-MM-DD')
                            }))}
                            disableToolbar
                            autoOk
                            variant={'inline'}
                            format={'YYYY-MM-DD'}
                        />
                    </MuiPickersUtilsProvider>
                    <br/>
                    <label htmlFor=""><h6>Курьер</h6></label>
                    <Select
                        isMulti
                        options={employeesList.map((courier) => ({value: courier.id, label: courier.name, id: courier.id}))}
                        onChange={(couriers) => dispatch(modifyFilterValue({
                            key: 'couriers', value: couriers
                        }))}
                    />
                    <br/>
                    <label htmlFor=""><h6>Таймслот</h6></label>
                    <Row style={{marginRight: 0}}>
                        <Col md={6}>
                            {timeslotsFirstCheckboxGroup[0]}
                        </Col>
                        <Col md={6}>
                            {timeslotsFirstCheckboxGroup[1]}
                        </Col>
                    </Row>
                    <br/>
                    <label htmlFor=""><h6>Номер заказа</h6></label>
                    <br/>
                    <Input onChange={(e) => dispatch(modifyFilterValue({
                        key: 'orderNumber', value: e.target.value
                    }))}/>
                    <br/>
                    <label htmlFor=""><h6>Статус</h6></label>
                    <Select
                        options={statuses}
                        onChange={(status) => {
                            dispatch(modifyFilterValue({
                                key: 'status', value: status.value
                            }))
                        }}
                    />
                    <br/>
                    <Row>
                        <Col md={6}>
                            <FormCheck
                                inline
                                label={'Ковры'}
                                onChange={(e) => {
                                    dispatch(modifyFilterValue({
                                        key: 'hasCarpets',
                                        value: e.target.checked
                                    }))
                                }}
                            />
                            <FormCheck
                                inline
                                label={'Шторы'}
                                onChange={(e) => {
                                    dispatch(modifyFilterValue({
                                        key: 'hasCurtains',
                                        value: e.target.checked
                                    }))
                                }}
                            />
                        </Col>
                        <Col md={6}>
                            <FormCheck
                                inline
                                label={'Коляски'}
                                onChange={(e) => {
                                    dispatch(modifyFilterValue({
                                        key: 'hasBabyBuggy',
                                        value: e.target.checked
                                    }))
                                }}
                            />
                            <FormCheck
                                inline
                                label={'Перевозки'}
                                onChange={(e) => {
                                    dispatch(modifyFilterValue({
                                        key: 'transportation',
                                        value: e.target.checked
                                    }))
                                }}
                            />
                        </Col>
                    </Row>
                    <hr/>
                    <FormCheck
                        inline
                        label={'Сборы'}
                        onChange={(e) => {
                            dispatch(modifyFilterValue({
                                key: 'onlyCollect',
                                value: e.target.checked
                            }))
                        }}
                    />
                    <FormCheck
                        inline
                        label={'Доставки'}
                        onChange={(e) => {
                            dispatch(modifyFilterValue({
                                key: 'onlyDelivery',
                                value: e.target.checked
                            }))
                        }}
                    />
                    <hr/>
                    <label htmlFor=""><h6>Рабочая зона</h6></label>
                    {zones.map((group, key) =>(
                        <div key={key}>
                            {group[0]}
                            <Row style={{marginRight: 0}}>
                                {group[1].map((child, childKey) => (
                                    <Col md={6} key={childKey}>
                                        {child}
                                    </Col>
                                ))}
                            </Row>
                            {(key + 1) !== group.length ? <hr/> : null}
                        </div>
                    ))}
                </div>
            </RightPanel>
        </div>
    )
}

export default MapFilter;