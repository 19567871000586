const LAT_INDEX = 0;
const LNG_INDEX = 1;

const CLEANING_SERVICE = 2;

const DEFAULT_CITY = 1;
const CITY_LIST = [
    {
        id: 1,
        name: "Москва",
        center: {"lat": 55.751244, "lng": 37.618423}
    },
    {
        id: 2,
        name: "Санкт-Петербург",
        center: {"lat": 59.9342802, "lng": 30.3350986}
    }
];

const SERVICES = [
    {
        id: CLEANING_SERVICE,
        name: 'Клининг'
    }
]

export {
    SERVICES,
    DEFAULT_CITY,
    CITY_LIST,
    LAT_INDEX,
    LNG_INDEX,
    CLEANING_SERVICE
}