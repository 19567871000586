import _ from 'lodash'
import {useEffect, useState} from "react";
import axios from "axios";
import {Button} from "react-bootstrap";
import styles from './Content.module.css'
import {useCity} from "../../../../app/utils/use";

function DrivesCounter (props) {

    const [drives, setDrives] = useState([]);
    const cityId = useCity();

    useEffect(() => {
        loadDrives()
    }, [cityId])

    const loadDrives = () => {
        setDrives([]);
        axios.get(props.apiUrl, {
            params: {
                cityId: cityId
            }
        }).then(function (response) {
            setDrives(response.data);
        })
    }

    const timeSlotsByDate = (date) => {
        let filteredDrives = _.reject(drives, function (drive) {
            return drive.date !== date;
        });
        return _.uniq(_.map(filteredDrives, 'time'))
    }

    const countBySlotAndDate = (date, time) => {
        return findDrivesByDateAndTime(date, time).length;
    }

    const showOrders = (date, time) => {
        let drives = findDrivesByDateAndTime(date, time);
        let url = axios.defaults.baseURL + '/admin/order/index?per-page=25&';
        url += 'OrderSearch[byOrderNumbers]=' + _.map(drives, 'orderNumber').join(',');
        url += '&sort=-order_id';
        let win = window.open(url, '_blank');
        win.focus();
    }

    const findDrivesByDateAndTime = (date, time) => {
        return _.reject(drives, function (drive) {
            return drive.date !== date || drive.time !== time;
        });
    }

    const dates = _.uniq(_.map(drives, 'date'));

    const template = dates.map((date, index) => (
        <div className={styles.UnBox} key={index}>
            <span className={'label label-danger'}>
                {index === 0 || date.length === 1 ? 'Сегодня' : 'Завтра'}
            </span>
            {timeSlotsByDate(date).map((timeslot, timeslotIndex) => (
                <div key={timeslotIndex}>
                        <span className={"label label-success"}
                              style={{cursor: 'pointer', marginRight: '5px'}}
                              onClick={(e) => showOrders(date, timeslot)}>
                            показать
                        </span>
                    <span className="label label-primary">{timeslot}</span> :
                    <span><em><strong> {countBySlotAndDate(date, timeslot)}</strong></em></span>
                </div>
            ))}
        </div>
    ))

    return (
        <div className={styles.ContentBody}>
            <h5>
                {props.header}
            </h5>
            {template}
            <Button variant={'success'} onClick={(e) => loadDrives()}>Обновить</Button>
        </div>
    )
}

export default DrivesCounter;