import {useDispatch, useSelector} from "react-redux";
import {
    selectDrivesInRoutes, selectRouteIds,
    selectSaveRouteInProcess,
    addRouteInSaveProcess,
    createRouteAsync,
    updateRouteAsync
} from "./routeSlice";
import _ from "lodash";
import styles from "./Route.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Badge, Button, Col, Row} from "react-bootstrap";
import ReactSwitch from "react-switch";
import {updateDriveInRoute} from "./routeSlice";
import {removeFromRoute} from "./routeSlice";
import axios from "axios";

const RouteBody = ({employee, mapRef}) => {

    const dispatch = useDispatch();
    const drivesInRoutes = useSelector(selectDrivesInRoutes);
    const saveRouteInProcess = useSelector(selectSaveRouteInProcess);
    const routeIds = useSelector(selectRouteIds);
    const filteredDrivesInRoutes = _.filter(drivesInRoutes, {courier: employee.id})
    const drives = _.map(filteredDrivesInRoutes, 'drive');

    const routeBody = drives.map((drive, driveIndex) => {
        const driveInRoute = _.find(filteredDrivesInRoutes, (data) => {
            return data.drive.id === drive.id
        })
        return (
            <div key={driveIndex} className={styles.DriveWrap}>
                <span className={styles.DriveIndex}>{driveIndex + 1}</span>
                <span className={styles.DriveTime}><FontAwesomeIcon icon={faClock}/> {drive.time}</span>
                <span><Badge style={{"backgroundColor": "#28a745"}} onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(drive.orderNumber)
                }}>{drive.orderNumber}</Badge></span>
                <div className="clearfix"></div>
                <span>{drive.addressData.expandedAddress}</span>
                <div className="clearfix"></div>
                <div onClick={(e) => e.stopPropagation()}>
                    <Row>
                        <Col md={3}>
                            <ReactSwitch
                                checked={drive.visibleInApp}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                onChange={e => {
                                    let api = '/clsystem/api/v1/drives-in-route';
                                    axios.put(api + '/' + drive.id + '/switch-visible', {
                                        visible_in_app: e
                                    }).then((response) => {
                                        dispatch(updateDriveInRoute({drive: drive, attributes: {visibleInApp: e}}));
                                    }).catch((error) => {
                                        alert(error.response.data.message);
                                    });
                                }}/>
                        </Col>
                        <Col md={5}>
                            <span className={'label ' + drive.order.status.color}>{drive.order.status.name}</span>
                        </Col>
                        <Col md={2}>
                            {drive.allowedChange ? <FontAwesomeIcon
                                onClick={(e) => {
                                    dispatch(removeFromRoute(driveInRoute))
                                }}
                                style={{"fontSize": "16px", 'marginTop': '5px'}} icon={faTrash}/> : null}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    })

    const handleSaveClick = () => {
        const routeData = _.find(routeIds, {employeeId: employee.id});
        if (typeof routeData !== "undefined") {
            dispatch(addRouteInSaveProcess(filteredDrivesInRoutes))
            dispatch(updateRouteAsync({
                routeId: routeData.id,
                route: filteredDrivesInRoutes
            }))
        } else if (filteredDrivesInRoutes.length) {
            dispatch(addRouteInSaveProcess(filteredDrivesInRoutes))
            dispatch(createRouteAsync({
                date: employee.date,
                route: filteredDrivesInRoutes
            }))
        }
    }

    return (
       <div className={styles.RouteWrap}>
           {routeBody}
           <Button variant={'success'} size={"sm"}
                   disabled={saveRouteInProcess}
                   onClick={(e) => {
                       e.stopPropagation();
                       handleSaveClick()
                   }}>
               Сохранить
           </Button>
       </div>
    )
}

export default RouteBody;