import axios from "axios";

export function fetchCategories(params = {}) {
    return axios.get('/clsystem/api/v1/cleaner/categories', {params: params})
}

export function fetchCategoriesViaMaps(params = {}) {
    return axios.get('/geo/api/v1/map/get-categories', {params: params})
}

export function fetchMaps(params = {}) {
    return axios.get('/geo/api/v1/map', {params: params})
}