import {
    DatePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import {
    modifyFilterValue,
    selectFiltersValueDate,
    loadFiltersAsync,
    refreshFiltersValue,
    selectFiltersValue,
    selectFiltersData,
    selectFiltersValueZones, defaultFiltersValue
} from "./filterSlice";
import {useDispatch, useSelector} from "react-redux";
import "moment/locale/ru";
import styles from './FilterPanel.module.css'
import {Button, Col, FormCheck, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faRefresh} from "@fortawesome/free-solid-svg-icons";
import RightPanel from "../../panel/RightPanel";
import {useEffect, useState} from "react";
import moment from "moment";
import {useCity} from "../../../app/utils/use";
import Select from "react-select";
import _ from 'lodash'
import FormCheckInput from "react-bootstrap/FormCheckInput";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";

const FilterPanel = ({onApply, onShowCleaners, onRefresh}) => {

    const dispatch = useDispatch();

    const dateInValue = useSelector(selectFiltersValueDate);
    const filtersValue = useSelector(selectFiltersValue);
    const filtersData = useSelector(selectFiltersData);
    const zonesInValue = useSelector(selectFiltersValueZones);
    const cityId = useCity();

    const [filterVersion, updateFilterVersion] = useState(moment().valueOf())
    const [filtersIsOpen, setFiltersIsOpen] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const [filterCleanersByType, setFilterCleanersByType] = useState(null);
    const [countAppliedFilters, setCountAppliedFilters] = useState(0);

    useEffect(() => {
        dispatch(loadFiltersAsync({cityId: cityId, date: filtersValue.date}))
        dispatch(refreshFiltersValue());
        updateFilterVersion(moment().valueOf());
        onApply(filtersValue);
    }, [cityId])

    useEffect(() => {
        onShowCleaners(filterCleanersByType);
    }, [filterCleanersByType]);

    useEffect(() => {
        let countFilters = 0;
        _.each(defaultFiltersValue, (defaultFilterValue, key) => {
            if (key !== 'date' && !_.isEqual(defaultFilterValue, filtersValue[key])) {
                countFilters++;
            }
        })
        setCountAppliedFilters(countFilters)
    }, [filtersValue]);


    useEffect(() => {
        if (filterApplied === true) {
            onApply(filtersValue);
            setFilterApplied(false);
        }
    }, [filterApplied])

    let filteredZones = [];
    filtersData.zones.map((zone) => {
        if (typeof filteredZones[zone.group] === "undefined") {
            filteredZones[zone.group] = [];
        }
        filteredZones[zone.group].push(zone);
    })

    const allZoneIds = _.map(filtersData.zones, 'id');
    const commonZoneCheckbox = <FormCheck inline>
        <FormCheckInput onChange={(e) => {
            dispatch(modifyFilterValue({
                key: 'zones',
                value: e.target.checked ? allZoneIds : allZoneIds,
                strategy: e.target.checked ? 'union' : 'without'
            }))
        }}/>
        <FormCheckLabel>Все зоны</FormCheckLabel>
    </FormCheck>

    const zones = Object.keys(filteredZones).map(groupName => {
        const childIds = _.map(filteredZones[groupName], 'id');
        const childInValues = _.filter(zonesInValue, (zoneId) => {
            return _.includes(childIds, zoneId);
        })

        const groupCheckbox = <FormCheck
            inline
            name={groupName}
            key={'group-' + groupName}>
            <FormCheckInput
                checked={childIds.length === childInValues.length}
                onChange={(e) => {
                dispatch(modifyFilterValue({
                    key: 'zones',
                    value: e.target.checked ? childIds : childIds,
                    strategy: e.target.checked ? 'union' : 'without'
                }))
            }}/>
            <FormCheckLabel>Группа зон&nbsp;<strong>{groupName === "" ? "Отсутствует" : groupName}</strong></FormCheckLabel>
        </FormCheck>

        const child = _.chunk(
            filteredZones[groupName] || [],
            Math.ceil(filteredZones[groupName].length / 2)
        );

        const childCheckboxes = child.map((childGroup) => (
            childGroup.map((child, key) => (
                <FormCheck
                    size={1}
                    inline
                    label={child.name}
                    checked={_.includes(zonesInValue, child.id)}
                    name={groupName + '-' + key}
                    key={groupName + '-' + child.id}
                    onChange={(e) => {
                        dispatch(modifyFilterValue({
                            key: 'zones',
                            value: e.target.checked ? [child.id] : child.id,
                            strategy: e.target.checked ? 'union' : 'without'
                        }))
                    }}
                />
            ))
        ))
        return [groupCheckbox, childCheckboxes]
    })

    return (
        <div className={styles.FilterPanelWrap} key={filterVersion}>
            <div className={styles.DateWrap}>
                <Row>
                    <Col md={2}>
                        <Button variant={"success"} onClick={(e) => {onApply(filtersValue)}} size={'sm'}>
                            <FontAwesomeIcon icon={faRefresh}/>
                        </Button>
                    </Col>
                    <Col md={10} style={{"paddingTop": "10px"}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={'ru'}>
                            <DatePicker
                                value={dateInValue}
                                onChange={(date) => {
                                    dispatch(loadFiltersAsync({cityId: cityId, date: date.format('YYYY-MM-DD')}))
                                    dispatch(refreshFiltersValue());
                                    updateFilterVersion(moment().valueOf());
                                    dispatch(modifyFilterValue({
                                        key: 'date',
                                        value: date.format('YYYY-MM-DD')
                                    }))
                                    setFilterApplied(true);
                                }}
                                disableToolbar
                                autoOk
                                variant={'inline'}
                                format={'YYYY-MM-DD'}
                            />
                        </MuiPickersUtilsProvider>
                    </Col>
                </Row>
            </div>
            <div className={styles.FiltersBtn}
                 onClick={(e) => setFiltersIsOpen(true)}>
                <FontAwesomeIcon icon={faFilter}/> Фильтры
                {countAppliedFilters ? <span className={styles.CountAppliedFilters}>{countAppliedFilters}</span> : null}
            </div>
            <div className={styles.FiltersWrap}>
                <RightPanel width={'100%'} height={"88vh"} isOpen={filtersIsOpen}>
                    <div className={styles.ActionBlock}>
                        <Row>
                            <Col md={5}  style={{"paddingRight": 0}}>
                                <div className={styles.FiltersBtnInWrap}
                                     onClick={(e) => setFiltersIsOpen(false)}>
                                    <FontAwesomeIcon icon={faFilter}/> Фильтры
                                </div>
                            </Col>
                            <Col md={3} style={{"padding": 0}}>
                                <Button variant={'danger'}
                                        onClick={(e) => {
                                            dispatch(refreshFiltersValue());
                                            updateFilterVersion(moment().valueOf());
                                            setFilterApplied(true);
                                        }}
                                        size={'sm'}>Сбросить</Button>
                            </Col>
                            <Col md={3} style={{"padding": 0}}>
                                <Button variant={'success'}
                                        onClick={(e) => setFilterApplied(true)}
                                        size={'sm'}>Применить</Button>
                            </Col>
                        </Row>
                    </div>
                    <label htmlFor=""><h6>Клинеры</h6></label>
                    <Select
                        options={filtersData.cleaners.map((cleaner) => ({value: cleaner.id, label: cleaner.name, id: cleaner.id}))}
                        onChange={(value) => dispatch(modifyFilterValue({
                            key: 'cleanerNumber', value: value.id
                        }))}
                    />
                    <label htmlFor=""><h6>Домашние адреса клинеров</h6></label>
                    <Row style={{marginRight: 0}}>
                        <Col md={6}  style={{"paddingRight": 0}}>
                            <FormCheck
                                checked={filterCleanersByType === 1}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setFilterCleanersByType(1)
                                    } else {
                                        setFilterCleanersByType(null)
                                    }
                                }}
                                inline label={"Все клинеры"}/>
                        </Col>
                        <Col md={6}  style={{"paddingRight": 0}}>
                            <FormCheck
                                checked={filterCleanersByType === 2}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setFilterCleanersByType(2)
                                    } else {
                                        setFilterCleanersByType(null)
                                    }
                                }}
                                inline label={"Активные"}/>
                        </Col>
                    </Row>
                    <hr style={{'margin': '5px 0'}}/>
                    <label htmlFor="">Заказы</label>
                    <Select
                        options={filtersData.orders.map((orderNumber) => ({value: orderNumber, label: orderNumber, id: orderNumber}))}
                        onChange={(value) => dispatch(modifyFilterValue({
                            key: 'orderNumber', value: value.id
                        }))}
                    />
                    <label htmlFor="">Статус</label>
                    <Select
                        options={_.map(filtersData.statuses, (status, id) => ({value: status, label: status, id: parseInt(id)}))}
                        onChange={(value) => dispatch(modifyFilterValue({
                            key: 'status', value: value.id
                        }))}
                    />
                    <label htmlFor="">Рабочие зоны</label>
                    <div className={styles.ZonesWrap}>
                        {commonZoneCheckbox}
                        {zones.map((group, key) =>(
                            <div key={key}>
                                {group[0]}
                                <Row style={{marginRight: 0}}>
                                    {group[1].map((child, childKey) => (
                                        <Col md={6} style={{"paddingRight": 0}} key={childKey}>
                                            {child}
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        ))}
                    </div>
                </RightPanel>
            </div>
        </div>
    )
}

export default FilterPanel;