
import ClientDriveContent from "./ClientDriveContent";
import WaypointContent from "./WaypointContent";
import TransportationContent from "./TransportationContent";

const DynamicContentByGeoObject = ({geoObjects, onWindowExpand, onOpenTransportation}) => {
    let components = [];
    geoObjects.map((object, key) => {
        if (object.isWayPoint) {
            components.push(<WaypointContent waypoint={object} key={'waypoint-content-' + key}/>);
        } else if (object.isTransportation) {
            components.push(<TransportationContent drive={object}
                                                   onOpenTransportation={(drive) => onOpenTransportation(drive)}
                                                   key={'transportation-content-' + key}/>);
        } else {
            components.push(<ClientDriveContent
                onWindowExpand={(state, geoObject) => onWindowExpand(state, geoObject)}
                drive={object}
                key={'client-drive-content-' + key}/>)
        }
    })
    return components
}

export default DynamicContentByGeoObject