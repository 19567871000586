import styles from './Content.module.css'
import {useCity} from "../../../../app/utils/use";
import axios from "axios";
import _ from "lodash";
import {Button} from "react-bootstrap";
import {useEffect, useState} from "react";

function Transportations() {

    const [drives, setDrives] = useState([]);
    const cityId = useCity();

    useEffect(() => {
        loadDrives()
    }, [cityId])

    const loadDrives = () => {
        setDrives([])
        axios.get('/lsystem/api/v1/transportation/transportation-drives', {
            params: {
                cityId: cityId
            }
        }).then(function (response) {
            setDrives(response.data);
        })
    }

    const findDrivesByStatus = (date, status) => {
        return _.reject(drives, function (drive) {
            return drive.date !== date || drive.status !== status;
        });
    }

    const findCompletedDrives = (date) => {
        return _.reject(drives, function (drive) {
            return drive.date !== date || drive.status !== 'Завершен' || _.filter(drive.transportation.goods, { 'active': 0 }).length !== 0;
        });
    }

    const findPartiallyCompletedDrives = (date) => {
        return _.reject(drives, function (drive) {
            return drive.date !== date || drive.status !== 'Завершен' || _.filter(drive.transportation.goods, { 'active': 0 }).length === 0;
        });
    }


    const showOrders = (drives) => {
        let url = axios.defaults.baseURL + '/admin/order/operators?per-page=25&';
        url += 'OrderSearch[byOrderNumbers]=' + _.map(drives, 'orderNumber').join(',');
        url += '&sort=-order_id';
        let win = window.open(url, '_blank');
        win.focus();
    }

    const dates = _.uniq(_.map(drives, 'date'));

    const template = dates.map((date, index) => (
        <div className={styles.UnBox} key={index}>
            <span className={'label label-danger'}>
                {index === 0 ? 'Сегодня' : 'Завтра'}
            </span>
            <br/>
            <span className={"label label-success"}
                  style={{cursor: 'pointer'}}
                  onClick={(e) => showOrders(findDrivesByStatus(date, 'Не распределен'))}>
                Нераспределено
            </span> :
            <span><em><strong> {findDrivesByStatus(date, 'Не распределен').length}</strong></em></span>
            <br/>
            <span className={"label label-success"}
                  style={{cursor: 'pointer'}}
                  onClick={(e) => showOrders(findDrivesByStatus(date, 'Распределён'))}>
                Распределено
            </span> :
            <span><em><strong> {findDrivesByStatus(date, 'Распределён').length}</strong></em></span>
            <br/>
            <span className={"label label-success"}
                  style={{cursor: 'pointer'}}
                  onClick={(e) => showOrders(findCompletedDrives(date))}>
                Завершено
            </span> :
            <span><em><strong> {findCompletedDrives(date).length}</strong></em></span>
            <br/>
            <span className={"label label-success"}
                  style={{cursor: 'pointer'}}
                  onClick={(e) => showOrders(findPartiallyCompletedDrives(date))}>
                Частично завершено
            </span> :
            <span><em><strong> {findPartiallyCompletedDrives(date).length}</strong></em></span>
            <br/>
            <span className={"label label-success"}
                  style={{cursor: 'pointer'}}
                  onClick={(e) => showOrders(findDrivesByStatus(date, 'Отменен'))}>
                Отменено
            </span> :
            <span><em><strong> {findDrivesByStatus(date, 'Отменен').length}</strong></em></span>
            <br/>
        </div>
    ))

    return (
        <div className={styles.ContentBody}>
            <h5>
                Перевозки - статистика
            </h5>
            {template}
            <Button variant={'success'} onClick={(e) => loadDrives()}>Обновить</Button>
        </div>
    )
}

export default Transportations;