import {
    Button,
    FullscreenControl,
    Map,
    ObjectManager,
    YMaps
} from "react-yandex-maps";
import {useRef, useState, useEffect} from "react";
import axios from "axios";
import _ from "lodash";
import {script} from "../../../app/utils/load";

const PolygonDraw = ({city, drafts, service, onInput, mapId, editable}) => {
    const [ymaps, setYmaps] = useState(null)
    const [zonesDrafts, setZonesDrafts] = useState([]);
    let saveAndPublish = null;
    const [objectManager, setObjectManager] = useState(null);

    const mapRef = useRef(null);
    const createZoneButtonRef = useRef(null);
    const cancelEditZoneBtnRef = useRef(null);
    const saveZoneButtonRef = useRef(null);
    const publishZoneButtonRef = useRef(null);

    const [createZoneButton, setCreateZoneButton] = useState(null);
    const [cancelEditZoneBtn, setCancelEditZoneBtn] = useState(null);
    const [saveZoneButton, setSaveZoneButton] = useState(null);
    const [publishZoneButton, setPublishZoneButton] = useState(null);

    const checkoutButtonsState = (state) => {
        switch (state) {
            case "edit":
                createZoneButtonRef.current.options.set('visible', false);
                saveZoneButtonRef.current.options.set('visible', true);
                cancelEditZoneBtnRef.current.options.set('visible', true);
                publishZoneButtonRef.current.options.set('visible', true);
                break;
            case "wait":
                createZoneButtonRef.current.options.set('visible', true);
                saveZoneButtonRef.current.options.set('visible', false);
                cancelEditZoneBtnRef.current.options.set('visible', false);
                publishZoneButtonRef.current.options.set('visible', false);
                break;
        }
    }

    useEffect(() => {
        if (objectManager !== null && ymaps !== null) {
            setTimeout(() => {
                window.ymaps = ymaps;
                window.ymaps.meta = {}
                window.ymaps.meta.coordinatesOrder = 'latlong';
                script("https://cdn.rawgit.com/yandex/mapsapi-area/651748be/build/util.calculateArea.min.js", 'calc', false)
                script("https://github.com/yandex/mapsapi-polylabeler/releases/download/v1.0.2/polylabel.min.js", 'polylabel', false, () => {
                    ymaps.ready(['polylabel.create']).then(function () {
                        try {
                            new ymaps.polylabel.create(mapRef.current, objectManager);
                        } catch (e) {
                          console.error(e);
                        }
                    });
                }, 1000)
            })
        }
    }, [objectManager, ymaps])

    const defaultPolygonOptions = {
        labelDefaults: 'dark',
        openHintOnHover: false,
        labelTextSize: {'0_15': 14, '15_30': 16},
        labelForceVisible: {'0_100': 'label'},
        labelLayout: '<div>{{properties.name}}</div>',
        editorDrawingCursor: "crosshair",
        opacity: 0.5,
        strokeColor: '#000000',
        strokeWidth: 2,
        editorMinPoints: 3,
        editorMenuManager: function (items) {
            return items.filter(item => {
                return item.id === "removeVertex";
            });
        },
        editorDblClickHandler() {}
    }

    const countParents = (zoneDraft) => {
        return _.filter(zonesDrafts, {zoneId: zoneDraft.parentZoneId}).length
    }

    useEffect(() => {
       if (editable !== null) {
           mapRef.current.geoObjects.each(geoObject => {
               if (geoObject.options.get('id') === editable.zoneId) {
                   cancelEditCurrentZone();
                   geoObject.editor.startDrawing();
               }
           })
       }
    }, [editable])

    useEffect(() => {
        if (mapRef.current !== null) {
            mapRef.current.geoObjects.removeAll();
        }

        if (drafts.length > 0) {
            setZonesDrafts(drafts);
        } else {
            setZonesDrafts([])
        }
    }, [drafts])

    useEffect(() => {
        if (mapRef.current !== null && typeof city?.center !== "undefined") {
            mapRef.current.setCenter([city.center.lat, city.center.lng])
        }
    }, [city?.center, mapRef.current]);

    const refreshSaveAndPublish = () => {
        saveAndPublish = null;
    }

    const createEmptyZone = (e) => {
        let zone = {
            name: 'Зона без имени',
            color: 'rgb(0, 0, 0)',
            cityId: city.id,
            serviceId: service.id,
            isVisible: true,
            isActive: e.saveAndPublish === true,
            mapId: mapId,
            geometry: {
                points: []
            }
        };
        _.each(e.geometry[0], (point) => {
            zone.geometry.points.push({lat: point[0], lng: point[1]})
        })

        axios.post('/geo/api/v1/zone/create', zone).then((response) => {
            axios.post('/geo/api/v1/zone-draft/create', {
                zoneId: response.data[0].zoneId,
                geometry: zone.geometry,
                isPublished: e.saveAndPublish
            }).then((response) => {
                onInput(response.data);
            }).catch(error => {
                alert(error.response.data.message);
            })
        }).catch((error) => {
            alert(error.response.data.message);
        })
    }

    const updateZone = (e) => {
        let points = [];
        _.each(e.geometry[0], (point) => {
            points.push({lat: point[0], lng: point[1]})
        })
        e.zoneDraft.geometry.points = points;
        axios.post('/geo/api/v1/zone-draft/update', e.zoneDraft).then((response) => {
            if (e.saveAndPublish) {
                axios.post('/geo/api/v1/zone-draft/publish', {
                    zoneDraftId: e.zoneDraft.zoneDraftId
                }).then((response) => {
                    onInput(response.data);
                }).catch(error => {
                    alert(error.response.data.message);
                })
            } else {
                onInput(response.data);
            }
        }).catch(error => {
            alert(error.response.data.message);
        })
    }

    const cancelEditCurrentZone = () => {
        mapRef.current.geoObjects.each(geoObject => {
            if (geoObject.hasOwnProperty('editor') && geoObject.editor.state.get('editing')) {
                let isNew = geoObject.options.get('isNew');
                if (isNew === true) {
                    mapRef.current.geoObjects.remove(geoObject)
                } else {
                    geoObject.geometry.setCoordinates(
                        [geoObject.options.get('originGeometry')]
                    )
                    geoObject.editor.stopEditing();
                }
            }
        })
    }

    useEffect(() => {
        if (mapRef.current !== null) {
            const successBtnLayout = ymaps.templateLayoutFactory.createClass(`
              <button class='btn btn-success'>
                   {{data.content}}
              </button>
            `);

            const dangerBtnLayout = ymaps.templateLayoutFactory.createClass(`
              <button class='btn btn-danger'>
                   {{data.content}}
              </button>
            `);

            setCreateZoneButton(<Button
                data={{
                    content: "Создать зону"
                }}
                options={{
                    maxWidth: [28, 150, 178],
                    selectOnClick: false,
                    layout: successBtnLayout
                }}
                onClick={(e) => {
                    checkoutButtonsState('edit');
                    let emptyPolygon = new ymaps.Polygon([], {}, Object.assign(
                        {fillColor: '#000000', isNew: true}, defaultPolygonOptions
                    ));
                    mapRef.current.geoObjects.add(emptyPolygon);
                    let stateMonitor = new ymaps.Monitor(emptyPolygon.editor.state);
                    stateMonitor.add("drawing", function (state) {
                        emptyPolygon.options.set("strokeColor", state ? '#FF0000' : '#000000');
                        if (state === false) {
                            checkoutButtonsState('wait');
                            mapRef.current.geoObjects.each(geoObject => {
                                if (geoObject === emptyPolygon) {
                                    setTimeout(function () {
                                        emptyPolygon.editor.stopEditing();
                                        createEmptyZone({
                                            geometry: emptyPolygon.geometry.getCoordinates(),
                                            saveAndPublish: saveAndPublish
                                        })
                                        refreshSaveAndPublish();
                                    }, 50);
                                }
                            });
                        }
                    });
                    emptyPolygon.editor.startDrawing();
                }}
                instanceRef={createZoneButtonRef}
            />);

            setCancelEditZoneBtn(<Button
                data={{
                    content: "Выйти без сохранения"
                }}
                options={{
                    maxWidth: [28, 150, 178],
                    selectOnClick: false,
                    float: 'left',
                    layout: dangerBtnLayout,
                    visible: false
                }}
                onClick={(e) => {
                    cancelEditCurrentZone()
                }}
                instanceRef={cancelEditZoneBtnRef}
            />)

            setSaveZoneButton(<Button
                data={{
                    content: "Сохранить и выйти"
                }}
                options={{
                    maxWidth: [28, 150, 178],
                    selectOnClick: false,
                    float: 'left',
                    layout: successBtnLayout,
                    visible: false
                }}
                onClick={(e) => {
                    mapRef.current.geoObjects.each(geoObject => {
                        if (geoObject.hasOwnProperty('editor') && geoObject.editor.state.get('editing')) {
                            saveAndPublish = false;
                            geoObject.editor.stopEditing();
                        }
                    })
                }}
                instanceRef={saveZoneButtonRef}
            />)

            setPublishZoneButton(<Button
                data={{
                    content: "Опубликовать"
                }}
                options={{
                    maxWidth: [28, 150, 178],
                    selectOnClick: false,
                    float: 'right',
                    layout: successBtnLayout,
                    visible: false
                }}
                onClick={(e) => {
                    mapRef.current.geoObjects.each(geoObject => {
                        if (geoObject.hasOwnProperty('editor') && geoObject.editor.state.get('editing')) {
                            saveAndPublish = true;
                            geoObject.editor.stopEditing();
                        }
                    })
                }}
                instanceRef={publishZoneButtonRef}
            />)

            const objectManager = new ymaps.ObjectManager();
            _.each(zonesDrafts, (zoneDraft) => {
                let geometry = [];
                _.each(zoneDraft.geometry.points, (point) => {
                    geometry.push([point.lat, point.lng]);
                });

                let options = Object.assign({
                    fillColor: zoneDraft.color || defaultPolygonOptions.strokeColor,
                    isNew: false,
                    originGeometry: geometry,
                    id: zoneDraft.zoneId,
                    zIndex: countParents(zoneDraft)
                }, defaultPolygonOptions)

                let polygon = new ymaps.Polygon([geometry], {}, options);
                objectManager.add({
                    type: 'Feature',
                    id: zoneDraft.zoneId,
                    geometry: {
                        type: 'Polygon',
                        coordinates: [geometry]
                    },
                    properties: {
                        name: zoneDraft.name || 'Зона без имени'
                    },
                    options: options
                });

                mapRef.current.geoObjects.add(polygon);
                const stateMonitor = new ymaps.Monitor(polygon.editor.state);
                stateMonitor.add("drawing", function (state) {
                    polygon.options.set("strokeColor", state ? '#FF0000' : '#000000');
                    if (state === false) {
                        checkoutButtonsState('wait');
                        polygon.editor.stopEditing();
                        if (polygon.geometry.getCoordinates()[0] !== polygon.options.get('originGeometry') || saveAndPublish) {
                            setTimeout(function () {
                                updateZone({
                                    zoneDraft: zoneDraft,
                                    geometry: polygon.geometry.getCoordinates(),
                                    saveAndPublish: saveAndPublish
                                })
                                refreshSaveAndPublish();
                            }, 50);
                        } else {
                            refreshSaveAndPublish();
                        }
                    } else {
                        checkoutButtonsState('edit');
                    }
                });
                polygon.events.add(['click'], (e) => {
                    polygon.editor.startDrawing();
                })
            });
            setObjectManager(objectManager)
        }
    }, [mapRef.current, zonesDrafts.length, mapId])

    return (
        <YMaps>
            <Map width="100%" height="100vh"
                 modules={[
                     "meta",
                     "ObjectManager",
                     "Polygon",
                     "geoObject.addon.editor",
                     "Monitor",
                     "templateLayoutFactory"
                 ]}
                 instanceRef={mapRef}
                 onLoad={(ymaps) => {
                     setYmaps(ymaps)
                 }}
                 defaultState={{
                     center: [1, 1],
                     zoom: 10
                 }}>
                <FullscreenControl/>
                <ObjectManager/>
                {createZoneButton}
                {cancelEditZoneBtn}
                {saveZoneButton}
                {publishZoneButton}
            </Map>
        </YMaps>
    )
}

export default PolygonDraw;