import classNames from "classnames";
import styles from './Panel.module.css'

function RightPanel(props) {
    return (
        <div
            style={{zIndex: props.zIndex || 1, width: props.width, height: props.height || '100vh'}}
            className={classNames({[styles.RightPanelWrap]: true, [styles.Closed]: !props.isOpen})}>
            {props.children}
        </div>
    )
}

export default RightPanel;