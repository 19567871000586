import {Col, Container, Form, Row} from "react-bootstrap";

const EditableTextColumn = ({defaultValue, options, onSave}) => {
    return (
        <Container style={{padding: 0}}>
            <Row>
                <Col md={12}>
                    <Form.Select defaultValue={defaultValue}
                                 onInput={(e) => onSave(e.target.value)}>
                        <option value={null}>Отсутствует</option>
                        {options.map(option => <option key={option.zoneId} value={option.zoneId}>{option.name}</option>)}
                    </Form.Select>
                </Col>
            </Row>
        </Container>
    )
}

export default EditableTextColumn;