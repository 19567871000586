import classNames from "classnames";
import styles from './Balloon.module.css';

function TransportationDriveBalloon(props) {
    const drive = props.drive;
    return (
        <div className={styles.BalloonBody}>
            <span className={classNames({'label': true, [styles.LabelInBalloon]: true, [drive.label]: true})}>
                {drive.type}
            </span>
            <br/>
            Заказ - <a href="'/drycleaning/' + content.orderNumber" target="_blank">{drive.orderNumber}</a>
            <br/>
        </div>
    );
}

export default TransportationDriveBalloon;