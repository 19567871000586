
const script = (url, id, async, callback) => {
    const existingScript = document.getElementById(id);
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = url;
        script.id = id;
        script.type = "text/javascript"
        script.async = async
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();
        };
    }
    if (existingScript && callback) {
        callback();
    }
};

const style = (url, id) => {
    if (document.getElementById(id) === null) {
        const head = document.head;
        const link = document.createElement("link");
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = url;
        link.id = id;
        head.appendChild(link);
    }
};


export {script, style}