import EmployeeItem from './EmployeeItem'
import styles from './Employee.module.css'
import {
    activateItem,
    deactivateActiveItem,
    selectActiveItem, selectSearchText, setSearchText
} from "./employeeSlice";
import {useDispatch, useSelector} from "react-redux";
import {Input} from "@material-ui/core";
import $ from "jquery";
import {useEffect, useRef} from "react";
import {useCity} from "../../app/utils/use";

const EmployeeList = ({
                          employees,
                          itemBody,
                          itemHeadRight,
                          onFilterEmployees,
                          itemClassName,
                          onEmployeeOpen,
                          date
                      }) => {
    const dispatch = useDispatch();
    const activeItem = useSelector(selectActiveItem)
    const containerRef = useRef(null);
    const searchText = useSelector(selectSearchText)
    const cityId = useCity();

    const handleEmployeeItemClick = (employeeId) => {
        if (activeItem === employeeId) {
            dispatch(deactivateActiveItem());
        } else {
            dispatch(deactivateActiveItem());
            dispatch(activateItem(employeeId));
            onEmployeeOpen();
        }
    }

    useEffect(() => {
        if (activeItem !== null && containerRef.current !== null) {
            const activeItem = $('div[class*="RouteItemIsActive"]');
            if (activeItem.length > 0) {
                const top = activeItem.offset().top - 100;
                if (top < -150 || top > 150) {
                    $(containerRef.current).animate({scrollTop: $(containerRef.current).scrollTop() + top}, 500);
                }
            }
        }
    }, [activeItem])

    useEffect(() => {
        if (containerRef.current !== null) {
            $(containerRef.current).animate({scrollTop: 0}, 0)
        }
    }, [cityId, date])

    const items = employees.map((employee) => {
        return (
            <EmployeeItem employee={employee}
                          className={itemClassName(employee)}
                          headRight={itemHeadRight(employee)}
                          body={itemBody(employee, activeItem === employee.id)}
                          isActive={activeItem === employee.id}
                          onClick={() => handleEmployeeItemClick(employee.id)}
                          key={employee.id}>
            </EmployeeItem>
        )
    })

    return (
        <div className={styles.EmployeeList} ref={containerRef}>
            <div className={styles.EmployeeListFilter}
                 onInput={(e) => {
                     onFilterEmployees(e.target.value);
                 }}>
                <Input value={searchText}
                       onInput={(e) => dispatch(setSearchText(e.target.value))}
                       placeholder={'Поиск курьера'} size={'lg'} fullWidth={true}/>
            </div>
            {items}
        </div>
    );
}

export default EmployeeList;