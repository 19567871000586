import RightPanel from "../../panel/RightPanel";
import styles from './Panel.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const ClosableRightPanel = ({onCloseRightPanel, isOpen, children, zIndex, width}) => {
    return (
        <RightPanel zIndex={zIndex} isOpen={isOpen} width={width}>
            <div onClick={(e) => onCloseRightPanel()}
                 className={styles.HideClosableRightPanelBtn}>
                <FontAwesomeIcon icon={faArrowRight}/>
            </div>
            {children}
        </RightPanel>
    )
}

export default ClosableRightPanel;