import {useDispatch, useSelector} from "react-redux";
import {selectPositions, setPositions} from "./employeeSlice";
import _ from 'lodash'
import {Placemark} from "react-yandex-maps";
import {useEffect} from "react";

const EmployeePosition = ({employees}) => {
    const positions = useSelector(selectPositions);
    const dispatch = useDispatch();
    useEffect(() => {
        return () => {
            dispatch(setPositions([]))
        }
    }, [])

    return _.map(positions, (data, key) => {
        if (typeof _.find(employees, {id: data.courier}) !== "undefined") {
            return (<Placemark
                modules={['geoObject.addon.balloon']}
                properties={
                    {
                        iconContent: data.attributes.name,
                        balloonContent: data.attributes.name + '<br/>(' + data.attributes.phone + ')<br/>' + data.attributes.appVersion
                    }
                }
                options={
                    {
                        hideIconOnBalloonOpen: false,
                        preset: 'islands#blackStretchyIcon',
                        zIndex: 4
                    }
                }
                geometry={[data.lat, data.lng]}
                key={'position-' + key}
            />)
        }
    })
}

export default EmployeePosition