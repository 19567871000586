import balloonStyles from "../../balloon/Balloon.module.css";
import styles from "./Content.module.css"
import classNames from "classnames";
import {Button, Col, Row} from "react-bootstrap";
import axios from "axios";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faEdit} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {activateItem, selectActiveItem} from "../../../employee/employeeSlice";
import {
    addToRoute,
    selectDrivesInRoutes,
    updateDriveInRoute
} from "../../route/routeSlice";
import _ from 'lodash'
import Select from "react-select";
import {
    selectWaypoints,
    updateDrive
} from "../../object/drycleaningMapObjectSlice";

const ClientDriveContent = ({onWindowExpand, drive}) => {
    const dispatch = useDispatch();
    const [additionInfoIsOpen, toggleAdditionalInfo] = useState(false)
    const [additionalInfoTemplate, setAdditionalInfoTemplate] = useState(null)
    const [waypointIsEdit, setWaypointIsEdit] = useState(false)
    const [newWaypoints, setNewWaypoints] = useState([])

    const activeEmployee = useSelector(selectActiveItem);
    const drivesInRoutes = useSelector(selectDrivesInRoutes);
    const waypoints = useSelector(selectWaypoints);
    const driveHasInRoute = _.find(drivesInRoutes, (item) => item.drive.id === drive.id)

    const [additionalInfo, setAdditionalInfo] = useState({
        adminComment: '',
        amount: null,
        countOrders: null,
        goodsInOrder: [],
        orderOptions: []
    });

    const updateAdditionalInfo = () => {
        axios.get('/lsystem/api/v1/drives/additional-info', {
            params: {id: drive.id}
        }).then((response) => {
            setAdditionalInfo(response.data);
        });
    }

    useEffect(() => {
        if (additionInfoIsOpen === true) {
            updateAdditionalInfo()
        } else {
            setAdditionalInfoTemplate(null);
        }
    }, [additionInfoIsOpen])

    useEffect(() => {
        if (typeof driveHasInRoute !== "undefined") {
            dispatch(activateItem(driveHasInRoute.courier))
        }
        if (additionInfoIsOpen === true) {
            updateAdditionalInfo()
        }
    }, [drive.id])

    const clientCrown = (additionalInfo) => {
        if (additionalInfo.isImportantClient) {
            return (
                <span className={styles.ClientCrown}>
                      <img width={'50px'} height={'50px'} src={'/icon/crown.png'}/>
                </span>
            )
        }
        return null;
    }

    useEffect(() => {
        if (additionInfoIsOpen === true) {
            setAdditionalInfoTemplate(
                <Col md={6}>
                    <strong>Стоимость заказа</strong>: {additionalInfo.amount} руб. <br/>
                    <strong>Заказов у клиента</strong>: {additionalInfo.countOrders} шт. <br/>
                    <strong>Средний чек</strong>: {additionalInfo.averageCheck} руб.
                    {clientCrown(additionalInfo)}
                    <hr style={{margin: '5px 0'}}/>
                    <strong>Состав заказа</strong><br/>
                    {additionalInfo.goodsInOrder.map((good, key) => (
                        <div key={key}>{good.name} - {good.qty} {good.unit.name}.</div>
                    ))}
                    {additionalInfo.orderOptions.map((option, key) => (
                        <div key={key}><a href='#'>Опция {option.name}</a></div>
                    ))}
                    <hr style={{margin: '5px 0'}}/>
                    <strong>Комментарий менеджера</strong>:
                    <div className="clearfix"/>
                    {additionalInfo.adminComment}
                    <div className={'clearfix'}/>
                    <strong style={{color: 'red'}}>Важный комментарий</strong>:
                    <div className="clearfix"/>
                    {additionalInfo.urgentComment}
                </Col>
            );
        }
    }, [additionalInfo])

    const btnTemplate = activeEmployee === null && drive.courierId === null ?
        <Button variant={"primary"} size={"sm"}>Выберите курьера</Button> :
        typeof driveHasInRoute !== "undefined" ? null :
        <Button variant={"success"} size={"sm"}
            onClick={(e) => {
                dispatch(addToRoute({courier: activeEmployee, drive: drive}))
            }}>
            Добавить в маршрут
        </Button>

    const waypointsTemplate = drive.wayPoints.map((waypoint, key) => {
        return (<span key={key}
              style={{marginRight: '3px'}}>{waypoint.name} {(key + 1) !== drive.wayPoints.length ? ',' : null}</span>)
    })

    const doneUpdateWaypoint = () => {
        dispatch(updateDriveInRoute({drive: drive, attributes: {wayPoints: newWaypoints}}));
        dispatch(
            updateDrive({drive: drive, attributes: {wayPoints: newWaypoints}})
        );
        setNewWaypoints([]);
        setWaypointIsEdit(false);
    }

    let editWaypointTemplate;
    if (waypointIsEdit) {
        editWaypointTemplate = (
            <div>
                <br/>
                <Select
                    isMulti
                    defaultValue={drive.wayPoints.map((waypoint) => ({value: waypoint.id, label: waypoint.name}))}
                    options={waypoints.map((waypoint) => ({value: waypoint.id, label: waypoint.name}))}
                    onChange={(values) => {
                        setNewWaypoints(_.filter(waypoints, (waypoint) => (_.includes(_.map(values, 'value'), waypoint.id))))
                    }}
                />
                {
                    newWaypoints.length > 0 ?
                        <div>
                            <br/>
                            <Button variant={"success"}
                                    onClick={(e) => {doneUpdateWaypoint()}}
                                    size={"sm"}>
                                Сохранить
                            </Button>
                        </div> : null
                }
            </div>
        )
    }

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    return (
        <div className={classNames({[balloonStyles.BalloonBody]: true, [styles.ContentBody]: true})}>
             <span
                 className={classNames({
                     'label': true,
                     [balloonStyles.LabelInBalloon]: true,
                     [drive.label]: true,
                     [styles.DriveTypeLabel]: true,
                     [styles.ExpandDriveTypeLabel]: additionInfoIsOpen
                })}>
                 {drive.type}
             </span>
            <Row>
                <Col md={additionInfoIsOpen ? 6 : 12}>
                    <strong>Заказ</strong>:&nbsp;
                    <a href={baseUrl + '/drycleaning/' + drive.orderNumber} target="_blank" rel="noreferrer">
                         {drive.orderNumber}
                    </a>
                    <br/>
                    <strong>Статус</strong>: <span className={classNames({'label': true, [drive.statusLabel]: true})}>{drive.status}</span><br/>
                    <strong>Дата</strong>: {drive.date} <br/>
                    <strong>Интервал</strong>: {drive.time}<br/>
                    <strong>Курьер</strong>: {drive.courierName} <br/>
                    <strong>Адрес</strong>: {drive.address} <br/>
                    <strong>Клиент</strong>: {drive.client.name} <br/>
                    <strong>Телефон</strong>: {drive.client.phone} <br/>
                    <div>
                        <span><strong>Фабрика</strong>: {waypointsTemplate}
                            <FontAwesomeIcon onClick={(e) => {
                                setNewWaypoints([])
                                setWaypointIsEdit(!waypointIsEdit)
                            }}
                                             className={styles.EditWaypointBtn} icon={faEdit}/></span>
                        <span className={styles.OpenAdditionalInfoBtn}>
                        <FontAwesomeIcon
                            onClick={(e) => {
                                toggleAdditionalInfo(!additionInfoIsOpen)
                                onWindowExpand(!additionInfoIsOpen, drive)
                            }}
                            icon={faArrowRight}/>
                        </span>
                    </div>
                    {editWaypointTemplate}
                    <br/>
                    {btnTemplate}
                </Col>
                {additionalInfoTemplate}
            </Row>
        </div>
    )
}


export default ClientDriveContent