import {Button} from "react-bootstrap";
import styles from './City.module.css'
import classNames from "classnames";
import {useCity} from "../../../app/utils/use";
import {parseInt} from "lodash";
import {Link} from "react-router-dom";
import {CITY_LIST} from "../../../app/utils/const"

function CitySelect() {
    const cityId = useCity();

    const links = CITY_LIST.map((city) => (
        <Link to={'/drycleaning/map/?cityId=' + city.id} key={'btn-link-' + city.id}>
            <Button
                variant={""}
                className={classNames({[styles.CityBtn]: true, [styles.Active]: parseInt(cityId) === city.id})}
                key={'btn-' + city.id}>
                {city.name}
            </Button>
        </Link>
    ))

    return (
        <div className={styles.CitySelect}>
            {links}
        </div>
    )
}

export default CitySelect;