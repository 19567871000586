import { YMaps, Map, Placemark, Clusterer, Polygon, SearchControl } from "react-yandex-maps";
import EmployeeList from './../employee/EmployeeList'
import {useDispatch, useSelector} from "react-redux";
import {
    loadDrivesAsync,
    loadWaypointsAsync,
    loadZonesAsync,
    selectDrives,
    selectWaypoints,
    selectZones,
    clearWaypoints,
    clearZones,
    clearDrives,
    loadGeoObjectsInInfo,
    selectLoadDrivesInProcess,
    selectGeoObjectsInInfoWindow
} from "./object/drycleaningMapObjectSlice";
import {
    deactivateActiveItem,
    loadEmployeesAsync,
    selectEmployees, setSearchText
} from "../employee/employeeSlice";
import {
    createRef,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import WaypointBalloon from "./balloon/WaypointBalloon";
import {renderToStaticMarkup} from "react-dom/server";
import TransportationDriveBalloon from "./balloon/TransportationDriveBalloon";
import ClientDriveBalloon from "./balloon/ClientDriveBalloon";
import {dynamicPreset} from './cluster/icon/color';
import {
    findCityCenter,
    findObjectsByLatLng
} from "../../app/utils/latLng";
import Info from "../window/Info";
import DynamicContentByGeoObject
    from "./window/content/DynamicContentByGeoObject";
import {useCity} from "../../app/utils/use";
import {CITY_LIST} from "../../app/utils/const";
import MapFilter from "./filter/MapFilter";
import CentralPanel from "./panel/CentralPanel";
import ClosableRightPanel from "./panel/ClosableRightPanel";
import _ from 'lodash'
import EmployeeBody from "./employee/EmployeeBody";
import {
    loadRoutesAsync,
    selectDrivesInRoutes,
    selectLoadRoutesInProcess,
    addToRoute
} from "./route/routeSlice";
import employeeStyles from './employee/Employee.module.css'
import {
    filterEmployeesByName,
    selectFilteredEmployees,
    selectFilterIsApply
} from "./employee/employeeSlice";
import { selectActiveItem } from "../employee/employeeSlice";
import WsClient from "./ws/WsClient";
import {toggleMenuState} from "./panel/centralPanelSlice";
import {selectAuthKey} from "../ws/wsSlice";
import {style} from "../../app/utils/load";
import Transportation from "./panel/content/Transportation";
import BlockLayout from "./layout/BlockLayout";


console.log('DryCleaningLogisticApp ver: 1.1.20230908');

const driveOffset = [-1, -42];
const clusterOffset = [0, -14];
const waypointOffset = [0, -18];


function DrycleaningApp() {

    style('//maxcdn.bootstrapcdn.com/bootswatch/3.3.7/paper/bootstrap.min.css', 'boostrap-css')
    const dispatch = useDispatch();

    const drives = useSelector(selectDrives)
    const drivesInRoutes = useSelector(selectDrivesInRoutes);
    const waypoints = useSelector(selectWaypoints)
    const baseEmployeesList = useSelector(selectEmployees);
    const authKey = useSelector(selectAuthKey);
    const filteredEmployeesList = useSelector(selectFilteredEmployees)
    const employeeFilterIsApply = useSelector(selectFilterIsApply);
    const loadDrivesInProcess = useSelector(selectLoadDrivesInProcess);
    const loadRoutesInProcess = useSelector(selectLoadRoutesInProcess);

    const activeEmployee = useSelector(selectActiveItem);

    const employees = _.sortBy(employeeFilterIsApply ? filteredEmployeesList : baseEmployeesList, (employee) => {
        return [_.head(_.map(employee.regions)).name, employee.sort, employee.name];
    })
    const zones = useSelector(selectZones)
    const geoObjectsInInfoWindow = useSelector(selectGeoObjectsInInfoWindow)

    const [ymaps, setYmaps] = useState(null)
    const [componentInRightPanel, openComponentInRightPanel] = useState(null)
    const [rightPanelWidth, setRightPanelWidth] = useState(0)
    const [infoWindowWidth, setInfoWindowWidth] = useState('400px')
    const [dynamicMapComponent, setDynamicMapComponent] = useState(null);
    const [currentDate, setCurrentDate] = useState(null);

    const [countOpenedAdditionalInfoWindows, setCountOpenedAdditionalInfoWindows] = useState(0)

    const clustererRef = createRef(null)
    const mapRef = useRef(null);
    const cityId = useCity();

    const [addressSearchResultPlacemark, setAddressSearchResultPlacemark] = useState(null);

    const addAddressSearchResultPlacemark = (placemarkData) => {

        if (addressSearchResultPlacemark) {
            mapRef.current.geoObjects.remove(addressSearchResultPlacemark);
        }

        if (!ymaps || mapRef === null || mapRef.current === null) {
            alert('Проблемы инициализации карты. Невозможно добавить метку');
        };

        if (placemarkData.lat && placemarkData.lng) {
            const newPlacemark = new ymaps.Placemark([placemarkData.lat, placemarkData.lng],
                {
                    iconCaption: placemarkData.title
                },
                {
                    preset: 'islands#greenDotIconWithCaption',
                }
            );
            setAddressSearchResultPlacemark(newPlacemark);
            mapRef.current.geoObjects.add(newPlacemark);
            mapRef.current.setCenter([placemarkData.lat, placemarkData.lng], 16);
        }
    }

    const removeAddressSearchResultPlacemark = () => {
        if (addressSearchResultPlacemark === null) return;
        mapRef.current.geoObjects.remove(addressSearchResultPlacemark);
        setAddressSearchResultPlacemark(null);
    }

    const cityCenter = findCityCenter(CITY_LIST, cityId);

    useEffect(() => {
        dispatch(clearWaypoints())
        dispatch(clearZones())
        dispatch(deactivateActiveItem())
        dispatch(loadWaypointsAsync({cityId: cityId}));
        dispatch(loadZonesAsync({cityId: cityId}));
        dispatch(filterEmployeesByName({name: '', employees: baseEmployeesList}))
        dispatch(setSearchText(''))
        closeInfoWindow()
    }, [cityId])

    const loadEmployees = (filtersValue) => {
        dispatch(loadEmployeesAsync({
            url: '/lsystem/api/v1/couriers',
            params: {
                cityId: cityId,
                expand: 'workTime,regions,schedule',
                date: filtersValue.date
            }
        }))
        dispatch(loadRoutesAsync({
            cityId: cityId,
            expand: 'drive',
            date: filtersValue.date
        }))
    }

    const loadDrives = (filtersValue) => {
        dispatch(clearDrives())
        dispatch(loadDrivesAsync({
            cityId: cityId,
            expand: 'client,transportation',
            filter: JSON.stringify(filtersValue)
        }));
    }

    useEffect(() => {
        if (mapRef.current !== null) {
            mapRef.current.setCenter([cityCenter.lat, cityCenter.lng])
        }
    }, [cityCenter])

    useEffect(() => {
        if (clustererRef.current) {
            clustererRef.current.createCluster = function (center, geoObjects) {
                const cluster = ymaps.Clusterer.prototype.createCluster.call(this, center, geoObjects);
                cluster.options.set('preset', dynamicPreset(geoObjects));
                return cluster;
            }
        }
    }, [clustererRef])

    useEffect(() => {
        if (mapRef.current !== null) {
            mapRef.current.events.remove('click');
            mapRef.current.geoObjects.events.remove('click');
            mapRef.current.events.add('click', function () {
                closeInfoWindow();
                dispatch(toggleMenuState(false))
            });
            mapRef.current.geoObjects.events.add('click', function (e) {
                if (e.get('target').geometry.getType() === 'Polygon') {
                    closeInfoWindow();
                    dispatch(toggleMenuState(false))
                }
            });
        }
    }, [mapRef.current?.geoObjects])

    useEffect(() => {
        setInfoWindowWidth(countOpenedAdditionalInfoWindows > 0 ? '600px' : '400px')
    }, [countOpenedAdditionalInfoWindows])

    const getAnimatedPlacemarkLayout = (drive, isSelected, isBouncing) => {

        if (!ymaps) return;

        let animatedLayout = ymaps.templateLayoutFactory.createClass(
            '<div class="placemark" data-order-id="' + drive.orderNumber + '" style="background-image: url(/icon/drycleaning/' + drive.icon + ')"></div>',
            {
                build: function () {
                    animatedLayout.superclass.build.call(this);
                    var element = this.getParentElement().getElementsByClassName('placemark')[0];

                    // виртуальная форма-область для активации поп-апа
                    var commonShape = { type: 'Rectangle', coordinates: [[-15, -42], [15,0]]};
                    var selectedShape = { type: 'Rectangle', coordinates: [[-21, -42], [20,0]]};
                    this.getData().options.set('shape', isSelected ? selectedShape : commonShape );

                    element.classList.remove("selected");
                    element.classList.remove("bounce");

                    if (isBouncing) {
                        element.classList.add("bounce");
                    }

                    if (isSelected) {
                        element.classList.add("selected");
                    }
                }
            }
        );
        return animatedLayout;
    }

    const getIsDriveInCurrentRoute = (drive) => {
        if (activeEmployee) {
            const filteredDrivesInRoutes = _.filter(drivesInRoutes, { courier: activeEmployee })
            const driveInRoute = _.find(filteredDrivesInRoutes, (item) => item.drive.id === drive.id);
            if (typeof driveInRoute !== "undefined") {
                return true;
            }
        }
        return false;
    }

    const getIsDriveInInfoWindow = (drive) => {
        let inInfoWindow = false;
        _.each(geoObjectsInInfoWindow, (geoObject) => {
            if (inInfoWindow === false && geoObject.id === drive.id) {
                inInfoWindow = true;
            }
        })
        return inInfoWindow;
    }

    const getDrivePlacemarks = (drives) => {
        return drives.map((drive, key) => {

            const isSelected = getIsDriveInInfoWindow(drive);
            const isBouncing = getIsDriveInInfoWindow(drive) || getIsDriveInCurrentRoute(drive);

            let icon = drive.icon.replace(isSelected ? ".png" : "_selected.png", isSelected ? "_selected.png" : ".png");

            var placeMarkVar = null;

            let layout = getAnimatedPlacemarkLayout({ ...drive, icon: icon }, isSelected, isBouncing);

            if (layout) {
                placeMarkVar = (<Placemark
                    options={{
                        iconLayout: layout,
                    }}
                    geometry={[drive.lat, drive.lng]}
                    properties={{
                        isTransportation: drive.isTransportation,
                        icon: icon,
                        status: drive.status,
                        driveId: drive.id
                    }}
                    key={'drive' + key}
                />);

            } else {
                placeMarkVar = (<Placemark
                    options={{
                        iconLayout: 'default#image',
                        iconImageHref: '/icon/drycleaning/' + drive.icon,
                        iconImageSize: isSelected ? [40, 40] : [30, 42],
                        iconImageOffset: isSelected ? [-21, -42] : [-15, -42],
                        zIndex: 2
                    }}
                    geometry={[drive.lat, drive.lng]}
                    properties={{
                        isTransportation: drive.isTransportation,
                        icon: drive.icon,
                        status: drive.status,
                        driveId: drive.id
                    }}
                    key={'drive' + key}
                />);
            }
            return placeMarkVar;
        });
    }

    const drivePlacemarks = useMemo(() => {
        return getDrivePlacemarks(drives);
    }, [drives, geoObjectsInInfoWindow, activeEmployee]);

    const closeBalloon = (e) => {
        e.getSourceEvent().originalEvent.map.balloon.close();
    }

    const openInfoPanel = (e) => {
        const latLng = e.getSourceEvent().originalEvent.target.geometry.getCoordinates();
        dispatch(loadGeoObjectsInInfo(findObjectsByLatLng(latLng, _.union(drives, waypoints))))
    }

    const clusterClickHandle = (e) => {
        const latLng = e.getSourceEvent().originalEvent.target.geometry.getCoordinates();
        const objects = findObjectsByLatLng(latLng, _.union(drives, waypoints));

        if (objects.length !== 1) {
            return true;
        }

        for (const object of objects) {

            const driveInRoute = _.find(drivesInRoutes, (item) => item.drive.id === object.id);

            if (activeEmployee && !object.isWayPoint && !object.isTransportation && typeof driveInRoute === "undefined") {
                dispatch(addToRoute({ courier: activeEmployee, drive: object }))
            }
        }
    }

    const openShortCompositeBalloon = (e) => {
        const latLng = e.getSourceEvent().originalEvent.target.geometry.getCoordinates();
        const map = e.getSourceEvent().originalEvent.map;
        const objects = findObjectsByLatLng(latLng, _.union(drives, waypoints));
        const components = objects.map((object, key) => {
            let balloonComponent;
            if (object.isWayPoint) {
                balloonComponent = <WaypointBalloon waypoint={object} key={'waypoint-balloon-' + key}/>;
            } else if (object.isTransportation) {
                balloonComponent = <TransportationDriveBalloon drive={object} key={'transportation-balloon-' + key}/>;
            } else {
                balloonComponent = <ClientDriveBalloon drive={object} key={'client-balloon-' + key}/>
            }
            return renderToStaticMarkup(balloonComponent);
        })

        let offset = driveOffset
        if (objects.length > 1){
            offset = clusterOffset
        } else if (objects[0]?.isWayPoint){
            offset = waypointOffset;
        }
        map.balloon.open(latLng, components.join('<hr/>'), {
            offset: offset
        });
    }

    const getWaypointPlacemarks = (waypoints) => {
        return waypoints.map((waypoint, key) => {
            return (
                <Placemark
                    options={
                        {
                            preset: 'islands#greenHomeCircleIcon',
                            zIndex: 1,
                        }
                    }
                    properties={{
                        isWaypoint: true
                    }}
                    geometry={[waypoint.lat, waypoint.lng]}
                    key={'waypoint' + key}
                />
            )
        });
    }

    const waypointPlacemarks = useMemo(() => getWaypointPlacemarks(waypoints), [waypoints]);

    const zonePolygons = zones.filter((zone) => zone.visible).map((zone, key) => {
        return (
            <Polygon
                options={
                    {
                        fillColor: zone.color,
                        opacity: 0.5,
                        strokeColor: '#000000',
                        strokeWidth: 2,
                        strokeOpacity: 0.8,
                        fillOpacity: 0.35
                    }
                }
                geometry={[zone.coord.map((latLng) => [latLng.lat, latLng.lng]), []]}
                key={'zone' + key}
            />
        )
    })

    const searchOrderOnTheMap = (orderNumber) => {
        let driveByOrderNumber;
        drives.forEach(drive => {
            if (parseInt(drive.orderNumber) === parseInt(orderNumber)) {
                driveByOrderNumber = drive;
            }
        })
        if (typeof driveByOrderNumber !== "undefined") {
            mapRef.current.setCenter([driveByOrderNumber.lat, driveByOrderNumber.lng], 16)
        }
    }

    const closeInfoWindow = () => {
        setInfoWindowWidth('400px');
        setCountOpenedAdditionalInfoWindows(0);
        dispatch(loadGeoObjectsInInfo([]));
    }

    const handleOnEmployeeOpen = () => {
        dispatch(setSearchText(''))
        dispatch(filterEmployeesByName({name: ''}))
        dispatch(loadGeoObjectsInInfo([]));
    }

    return (
        <div>
            {loadDrivesInProcess || loadRoutesInProcess ? <BlockLayout/> : null}
            <YMaps>
                {authKey !== null ? <WsClient/> : null}
                <div className={employeeStyles.EmployeeListWrap}>
                    <EmployeeList
                        date={currentDate}
                        onFilterEmployees={(name) => {
                            dispatch(filterEmployeesByName({name: name, employees: baseEmployeesList}))
                        }}
                        onEmployeeOpen={handleOnEmployeeOpen}
                        itemHeadRight={(employee) => (
                            _.map(employee.regions, (region, key) => (
                                <span className={'label label-primary'}
                                      key={key}
                                      style={{
                                          backgroundColor: region.color,
                                          fontSize: '11px',
                                          display: 'block',
                                          marginBottom: '5px'
                                      }}>
                                {region.name}
                            </span>
                            ))
                        )}
                        itemClassName={(employee) => (
                            {
                                [employeeStyles.RouteAlert]: !employee.atWork && employee.checkAtWork && !employee.needRouteConfirm,
                                [employeeStyles.RouteWarning]: employee.needRouteConfirm
                            }
                        )}
                        itemBody={(employee, isActive) => (<EmployeeBody employee={employee} isActive={isActive}/>)}
                        employees={employees}/>
                </div>
                <Map width="100%" height="100vh"
                     instanceRef={mapRef}
                     onLoad={(ymaps) => {setYmaps(ymaps)}}
                     modules={['templateLayoutFactory']}
                     defaultState={{
                        center: [cityCenter.lat, cityCenter.lng],
                        zoom: 10,
                        controls: []
                        }}>
                    {/* для работы встроенного поиска по карте нужно API-ключ,
                    видимо геокодинг для определения координат для плэйсмарка платный
                    подробнее тут:
                    https://pbe-react-yandex-maps.vercel.app/en/api/controls/search-control/
                    https://yandex.com/dev/jsapi-v2-1/doc/en/v2-1/ref/reference/control.SearchControl
                    <SearchControl options={{ float: "right" }} />
                    */}

                    <Clusterer
                        onMouseenter={(e) => {openShortCompositeBalloon(e)}}
                        onMouseleave={(e) => closeBalloon(e)}
                        onClick={(e) => {
                            clusterClickHandle(e);
                            openInfoPanel(e)
                        }}
                        instanceRef={clustererRef}
                        options={{
                            groupByCoordinates: true,
                            disableClickZoom: true,
                            hasBalloon: false,
                            zIndex: 1
                        }}>
                        {drivePlacemarks}
                        {waypointPlacemarks}
                    </Clusterer>
                    {zonePolygons}
                    {dynamicMapComponent}
                </Map>
            </YMaps>
            <Info show={geoObjectsInInfoWindow.length > 0}
                  x={'20%'}
                  y={'10px'}
                  width={infoWindowWidth}
                  onClick={() => {
                      closeInfoWindow()
                  }}>
                <DynamicContentByGeoObject
                    onOpenTransportation={(drive) => {
                        setRightPanelWidth('18%')
                        openComponentInRightPanel(<Transportation drive={drive}/>)
                    }}
                    onWindowExpand={(state, geoObject) => {
                        if (state === true) {
                            setCountOpenedAdditionalInfoWindows(countOpenedAdditionalInfoWindows + 1)
                        } else {
                            setCountOpenedAdditionalInfoWindows(countOpenedAdditionalInfoWindows - 1)
                        }
                    }}
                    geoObjects={geoObjectsInInfoWindow}/>
            </Info>
            <MapFilter
                onCityChange={(filtersValue) => {
                    loadEmployees(filtersValue);
                }}
                onApply={(filtersValue) => {
                    closeInfoWindow();
                    dispatch(deactivateActiveItem());
                    loadDrives(filtersValue);
                    const dateInEmployees = employees[0]?.date;
                    if (typeof dateInEmployees !== "undefined" && dateInEmployees !== filtersValue.date) {
                        loadEmployees(filtersValue);
                        setCurrentDate(filtersValue.date)
                    }
                }
            }/>
            <CentralPanel
                employees={baseEmployeesList}
                addAddressSearchResultPlacemarkFunc={(e) => addAddressSearchResultPlacemark(e)}
                removeAddressSearchResultPlacemarkFunc={(e) => removeAddressSearchResultPlacemark(e)}
                onRenderMapComponent={(MapComponent) => {
                    if (dynamicMapComponent === null) {
                        setDynamicMapComponent(MapComponent)
                    } else {
                        setDynamicMapComponent(null)
                    }
                }}
                onOpenRightPanel={(ComponentInRightPanel, width) => {
                    setRightPanelWidth(width)
                    openComponentInRightPanel(ComponentInRightPanel)
                }}
                onSearchOrder={(orderNumber) => searchOrderOnTheMap(orderNumber)}
            />
            <ClosableRightPanel
                zIndex={3}
                width={rightPanelWidth}
                onCloseRightPanel={() => {
                    setRightPanelWidth(0)
                    openComponentInRightPanel(null)
                }}
                isOpen={componentInRightPanel !== null}>
                {componentInRightPanel}
            </ClosableRightPanel>
        </div>
    );
}

export default DrycleaningApp;
