import DrivesCounter from "./DrivesCounter";

function UndistributedDrives() {
    return (
        <DrivesCounter
            apiUrl={'/lsystem/api/v1/undistributed-drives'}
            header={'Нераспределённые выезды'}
        />
    )
}

export default UndistributedDrives;