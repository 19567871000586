import _ from 'lodash'


function dynamicPreset(geoObjects) {

    const props = geoObjects.map((geoObject) => (
        geoObject.properties.getAll()
    ));

    const wayPoints = _.filter(props, {isWaypoint: true})
    if (wayPoints.length === geoObjects.length) {
        return 'islands#greenClusterIcons'
    }

    const hasNotDistributedTransportation = _.filter(props, {isTransportation: true, status: 'Не распределен'});
    if (hasNotDistributedTransportation.length > 0) {
       return 'islands#invertedRedClusterIcons'
    }

    const hasDistributedTransportation = _.filter(props, {isTransportation: true, status: 'Распределен'});
    if (hasDistributedTransportation.length > 0) {
        return 'islands#invertedBlueClusterIcons'
    }

    const special = [
        'carpetCollect.png',
        'carpetDelivery.png',
        'curtainsCollect.png',
        'curtainsDelivery.png',
        'smallCarpetDelivery.png',
        'smallCarpetCollect.png',
        'ecoCollect.png',
        'ecoDelivery.png'
    ];

    const hasNotDistributedDrivesWithSpecial = _.filter(props, (prop) => (
        _.includes(special, prop.icon) && prop.status === 'Не распределен'
    ));
    if (hasNotDistributedDrivesWithSpecial.length > 0) {
        return 'islands#invertedBlackClusterIcons'
    }

    const hasDistributedDrivesWithSpecial = _.filter(props, (prop) => (
        _.includes(special, prop.icon) && prop.status === 'Распределен'
    ));
    if (hasDistributedDrivesWithSpecial.length > 0) {
        return 'islands#invertedBlueClusterIcons'
    }

    const hasNotDistributedDrives = _.filter(props, {isTransportation: false, status: 'Не распределен'});
    if (hasNotDistributedDrives.length > 0) {
        return 'islands#invertedGrayClusterIcons'
    }

    const hasDistributedDrives = _.filter(props, {isTransportation: false, status: 'Распределен'});
    if (hasDistributedDrives.length > 0) {
        return 'islands#invertedBlueClusterIcons'
    }

    return 'islands#invertedBlueClusterIcons'
}

export {dynamicPreset};