import balloonStyles from "../../balloon/Balloon.module.css";
import styles from "./Content.module.css";
import {Button, Col, Form, Row} from "react-bootstrap";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faClock, faCommentDots,
    faMapMarkerAlt, faMinus, faPlusCircle, faSave, faUserAlt
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectDrivesInRoutes} from "../../route/routeSlice";
import _ from "lodash";

const CleaningContent = ({drive, category, onOrderUpdate, onAddToRoute}) => {

    const [commentIsOpen, setCommentIsOpen] = useState(false);
    const [newComment, setNewComment] = useState(drive.order.managerComment);
    const drivesInRoutes = useSelector(selectDrivesInRoutes);
    const driveHasInRoute = _.find(drivesInRoutes, (item) => item.drive.id === drive.id)

    useEffect(() => {
        setCommentIsOpen(false)
        setNewComment(drive.order.managerComment)
    }, [drive.id])

    const commentForm = (
        <div className={balloonStyles.CommentForm} key={drive.id}>
            <label htmlFor="">Комментарий клиента</label>
            <Form.Control as={"textarea"}
                          defaultValue={drive.order.clientComment}
                          readOnly={true} style={{height: '50px'}}/>
            <label htmlFor="">Комментарий менеджера</label>
            <Form.Control as={"textarea"}
                          onInput={(e) => {
                              setNewComment(e.target.value);
                          }}
                          defaultValue={newComment} style={{height: '80px'}}/>
            <Row style={{"marginTop": '5px'}}>
                <Col md={7}></Col>
                <Col md={2}>
                    <Button variant={"danger"} onClick={(e) => {
                        setNewComment(drive.order.managerComment)
                        setCommentIsOpen(false);
                    }} size={'sm'}>
                        <FontAwesomeIcon icon={faMinus}/>
                    </Button>
                </Col>
                <Col md={2}>
                    <Button variant={"success"} onClick={(e) => {
                        onOrderUpdate(drive, {manager_comment: newComment})
                        setCommentIsOpen(false);
                        setNewComment(newComment);
                    }} size={'sm'}>
                        <FontAwesomeIcon icon={faSave}/>
                    </Button>
                </Col>
            </Row>
        </div>
    )

    return (
        <div className={styles.WindowWrap}>
            <div>
                <Row>
                    <Col md={12}>
                    <span className={balloonStyles.OrderNumber}>
                        <a target="_blank"
                           rel="noreferrer"
                           href={axios.defaults.baseURL + '/cleaning/' + drive.orderNumber}>
                            {drive.orderNumber}
                        </a>
                    </span>
                    </Col>
                    <Col md={12}>
                        <span
                            className={balloonStyles.Category}>{category.name}</span>
                    </Col>
                    <Col md={12}>
                    <span className={classNames({
                        [balloonStyles.Status]: true,
                        [drive.statusLabel]: true
                    })}>
                        {drive.order.status.name}
                    </span>
                    </Col>
                    <Col md={12}>
                    <span className={balloonStyles.Time}>
                        <FontAwesomeIcon icon={faClock}/> {drive.time}
                    </span>
                    </Col>
                    <Col md={12}>
                    <span className={balloonStyles.Address}>
                        <FontAwesomeIcon
                            icon={faMapMarkerAlt}/> {drive.addressData.expandedAddress}
                    </span>
                    </Col>
                    {drive.cleanerTrimName ? <Col md={12}>
                    <span className={balloonStyles.Cleaner}>
                        Клинер - {drive.cleanerTrimName}
                    </span>
                    </Col> : null}
                    <Col md={12}>
                        <Row>
                            <Col md={8}>
                            <span className={balloonStyles.User}>
                                <FontAwesomeIcon icon={faUserAlt}/>
                                <a target="_blank"
                                   rel="noreferrer"
                                   href={axios.defaults.baseURL + '/admin/client/view?id=' + drive.order.client.id}>
                                    &nbsp;{drive.order.client.name}
                                </a>
                            </span>
                            </Col>
                            <Col md={1}>
                                <div>
                                    <FontAwesomeIcon onClick={(e) => {
                                        setCommentIsOpen(true)
                                    }}
                                                     className={balloonStyles.ActionIcon}
                                                     icon={faCommentDots}/>
                                    {drive.order.clientComment || newComment ?
                                        <span
                                            onClick={(e) => {
                                                setCommentIsOpen(true)
                                            }}
                                            className={balloonStyles.CommentCounter}>1</span> : null}
                                </div>
                            </Col>
                            <Col md={1}>
                                <FontAwesomeIcon className={balloonStyles.ActionIcon}
                                                 onClick={(e) => {
                                                     if (typeof driveHasInRoute === "undefined") {
                                                         onAddToRoute(drive)
                                                     }
                                                 }}
                                                 icon={faPlusCircle}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {commentIsOpen ? commentForm : null}
            </div>
        </div>
    );
}

export default CleaningContent;