import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import styles from './Employee.module.css'
import {useSelector} from "react-redux";
import {selectZones} from "../object/cleaningMapObjectSlice";
import _ from 'lodash'
import {Button} from "react-bootstrap";
import RouteBody from "../route/RouteBody";

const EmployeeBody = ({employee, isActive, mapRef}) => {

    const zones = useSelector(selectZones);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    return (
        <div>
            {employee.schedule.map((item, key) => (
                <div className={styles.WorkTime} key={key}>
                    <FontAwesomeIcon icon={faClock}/> {item.interval}
                    <a style={{'marginLeft': '5px'}}
                       onClick={(e) => {
                            e.stopPropagation();
                            window.open(baseUrl + '/cleaning/schedule/edit?date=' + employee.date + "&cleanerId=" + employee.id + '&goback=' + encodeURIComponent(window.location.href), '_blank');
                       }}
                       target="_blank"
                        rel="noreferrer"
                        >
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </a>
                    <div className="clearfix"></div>
                    {item.zones.slice(0, isActive ? zones.length : 4).map((zone, zoneKey) => {
                        const zoneData = _.find(zones, {zoneId: zone.zoneId})
                        if (typeof zoneData !== "undefined") {
                            return (
                                <Button key={zoneKey} size={"sm"}
                                    style={{
                                        "backgroundColor": zoneData.color,
                                        "pointerEvents": 'none'
                                    }}>
                                    {zoneData.name}
                                </Button>
                            )
                        }
                        return null;
                    })}
                    {!isActive ?
                        <span className={styles.CountZones}>{item.zones.slice(3, -1).length > 0 ? "+" + item.zones.slice(3, -1).length : null}</span>
                        : null}
                </div>
            ))}
            <div className="clearfix"></div>
            {isActive ? <RouteBody employee={employee} mapRef={mapRef}/> : null}
        </div>
    );
}

export default EmployeeBody;