import styles from './Panel.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendar,
    faBars,
    faHashtag,
    faMessage,
    faRefresh
} from '@fortawesome/free-solid-svg-icons'
import {Col, Row} from "react-bootstrap";
import {TextField} from "@material-ui/core";
import CitySelect from "../city/CitySelect";
import {useEffect, useState} from "react";
import classNames from "classnames";
import DrivesWithoutZone from "./content/DrivesWithoutZone";
import UndistributedDrives from "./content/UndistributedDrives";
import Transportations from "./content/Transportations";
import NotificationsHistory from "./content/NotificationsHistory";
import DistributionLog from "./content/DistributionLog";
import axios from "axios";
import {
    refreshFiltersValue,
    toggleIsRefreshedState,
    modifyFilterValue,
} from "../filter/filterSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectMenuIsOpen, toggleMenuState} from "./centralPanelSlice";
import {useCity} from "../../../app/utils/use";
import {selectDrivesInRoutes} from "../route/routeSlice";
import _ from "lodash";
import {courierIsLate} from "../utils/time";
import EmployeePosition from "../employee/EmployeePosition";
import AddressSearchInput from './AddressSearchInput';

const CentralPanel = ({ onSearchOrder, onOpenRightPanel, employees, onRenderMapComponent, addAddressSearchResultPlacemarkFunc, removeAddressSearchResultPlacemarkFunc }) => {

    const SHOW_DRIVES_MODE_DEFAULT = 'default';
    const SHOW_DRIVES_MODE_ALL = 'all';

    const dispatch = useDispatch();
    const menuState = useSelector(selectMenuIsOpen);
    const [countDrivesWithoutZone, setCountDrivesWithoutZone] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [intervalId, setIntervalId] = useState(null);
    const [currentDrivesShowMode, setCurrentDrivesShowMode] = useState(SHOW_DRIVES_MODE_DEFAULT);

    const drivesInRoutes = useSelector(selectDrivesInRoutes);
    let countCouriersWithLateDrives = 0;
    employees.forEach((employee) => {
        const filteredDrivesInRoutes = _.filter(drivesInRoutes, {courier: employee.id});
        const lateDrives = _.filter(filteredDrivesInRoutes, (driveInRoute) => courierIsLate(driveInRoute));
        if (lateDrives.length) {
            countCouriersWithLateDrives++;
        }
    })

    const cityId = useCity();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            onSearchOrder(e.target.value)
        }
    }

    const findDrivesWithoutZone = () => {
        axios.get('/lsystem/api/v1/without-zone-drives').then((response) => {
            setCountDrivesWithoutZone(response.data.length)
        })
    }

    useEffect(() => {
        setSearchValue('')
    }, [cityId]);


    useEffect(() => {
        clearInterval(intervalId);
        setIntervalId(setInterval(() => {
            findDrivesWithoutZone()
        }, 5000))
        findDrivesWithoutZone();
    }, [])

    /**
     * сбрасывает состояние карты к первоначальному состоянию
     */
    const refreshFilters = () => {
        setCurrentDrivesShowMode(SHOW_DRIVES_MODE_DEFAULT);
        dispatch(refreshFiltersValue());
        dispatch(toggleIsRefreshedState(true));
    }

    const showAllDrives = () => {
        setCurrentDrivesShowMode(SHOW_DRIVES_MODE_ALL);
        dispatch(refreshFiltersValue());
        dispatch(modifyFilterValue({
            key: 'default', value: false
        }));
        dispatch(toggleIsRefreshedState(true));
    }

    const lateDrivesBlock = () => {
        if (countCouriersWithLateDrives > 0) {
            return (
                <Col md={12}>
                    <span className={styles.CountCouriersWithLateDrives}>
                          Опаздывает: {countCouriersWithLateDrives}
                    </span>
                </Col>
            )
        }
        return null;
    }

    return (
        <div className={styles.CentralPanel}>
            <div className={styles.CentralPanelBody}>
                <AddressSearchInput
                    addAddressSearchResultPlacemarkFunc={addAddressSearchResultPlacemarkFunc}
                    removeAddressSearchResultPlacemarkFunc={removeAddressSearchResultPlacemarkFunc}
                ></AddressSearchInput>
                <Row>
                    <Col className={styles.CentralPanelIconPointer} onClick={(e) => dispatch(toggleMenuState(!menuState))} md={2}>
                        <FontAwesomeIcon icon={faBars}/>
                        {
                            countDrivesWithoutZone > 0 ?
                            <FontAwesomeIcon
                                className={classNames({[styles.HasNotificationIcon]: true, [styles.HasNotificationMenuIcon]: true})}
                               icon={faMessage}/> : null
                        }
                    </Col>
                    <Col className={styles.CentralPanelIconPointer} md={2}>
                        <a href={baseUrl + "/admin/couriers/schedule?sort=region"} target="_blank" title="Расписание курьеров в регионе">
                            <FontAwesomeIcon icon={faCalendar}/>
                        </a>
                    </Col>
                    <Col md={2} className={styles.CentralPanelIconPointer}>
                        <FontAwesomeIcon onClick={() => {
                                    if (currentDrivesShowMode === SHOW_DRIVES_MODE_DEFAULT) {
                                        showAllDrives();
                                    } else {
                                        refreshFilters();
                                    }
                                }
                            } icon={faRefresh} title={currentDrivesShowMode === SHOW_DRIVES_MODE_DEFAULT ? "Показать все выезды" : "Показать только нераспределённые выезды"} />
                    </Col>
                    <Col md={6}>
                        <Row>
                            <Col className={styles.CentralPanelIcon} md={1} title="Найти на карте заказ. Введите номер и нажмите Enter">
                                <FontAwesomeIcon icon={faHashtag}/>
                            </Col>
                            <Col md={9}>
                                <TextField
                                    value={searchValue}
                                    onKeyUp={handleKeyPress}
                                    onInput={(e) => setSearchValue(e.target.value)}
                                    size="small" className={styles.CentralPanelSearchInput}/>
                            </Col>
                        </Row>
                    </Col>
                    {lateDrivesBlock()}
                </Row>
                <div className={classNames({[styles.CentralPanelDropDownMenu]: true, [styles.Active]: menuState})}>
                    <ul>
                        <li onClick={(e) => onOpenRightPanel(<UndistributedDrives/>, '18%')}>
                            Нераспределенные выезды
                        </li>
                        <li onClick={(e) => onOpenRightPanel(<DrivesWithoutZone/>, '18%')}>
                            <span>
                                 Выезды без зоны
                                {countDrivesWithoutZone > 0 ? <FontAwesomeIcon className={styles.HasNotificationIcon} icon={faMessage}/> : null}
                            </span>
                        </li>
                        <li onClick={(e) => onOpenRightPanel(<Transportations/>, '18%')}>
                            Перевозки
                        </li>
                        <li onClick={(e) => onOpenRightPanel(<NotificationsHistory/>, '25%')}>
                            История уведомлений
                        </li>
                        <li onClick={(e) => onOpenRightPanel(<DistributionLog/>, '25%')}>
                            Лог распределения
                        </li>
                        <li onClick={(e) => onRenderMapComponent(<EmployeePosition employees={employees}/>)}>
                            <strong>Показать курьеров</strong>
                        </li>
                    </ul>
                </div>
            </div>
            <CitySelect/>
        </div>
    )
}

export default CentralPanel;