import styles from "./ZoneEditor.module.css";
import columnStyles from "./../../table/column/Column.module.css";
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faClose,
    faEdit,
    faEye,
    faEyeSlash
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import EditableTextColumn from "../../table/column/EditableTextColumn";
import EditableSelectColumn from "../../table/column/EditableSelectColumn";
import EditableColorColumn from "../../table/column/EditableColorColumn";
import _ from "lodash";
import classNames from "classnames";

const ZonesTable = ({activeTab, maps, service, onDelete, onDraftsLoaded, onStartEdit}) => {
    const [currentMap, setCurrentMap] = useState(activeTab);
    const [zonesTable, setZonesTable] = useState(null);
    const [zones, setZones] = useState([]);
    const [drafts, setDrafts] = useState([]);

    useEffect(() => {
        setCurrentMap(activeTab)
    }, [activeTab])

    useEffect(() => {
        if (currentMap !== 0) {
            loadZones();
        }
    }, [currentMap]);

    const loadZones = () => {
        const params = {
            params: {
                mapIds: currentMap,
                serviceId: service.id,
                isActive: [true, false]
            }
        };
        axios.get('/geo/api/v1/zone/search-by-maps', params).then((responseZones) => {
            const zonesInResponse = responseZones.data.models;
            setZones(zonesInResponse);
            const zoneIds = _.map(zonesInResponse, 'zoneId');
            const params = {
                zoneIds: zoneIds,
                serviceId: service.id
            };
            if (zoneIds.length) {
                axios.post('/geo/api/v1/zone-draft/search', params).then((responseDrafts) => {
                    let formattedDrafts = [];
                    _.each(responseDrafts.data, zoneDraft => {
                        let zone = _.find(zonesInResponse, {zoneId: zoneDraft.zoneId})
                        if (typeof zone !== "undefined") {
                            formattedDrafts.push(Object.assign(zoneDraft, {
                                name: zone.name,
                                color: zone.color,
                                parentZoneId: zone.parentZoneId,
                                isVisible: zone.isVisible
                            }))
                        }
                    })
                    setDrafts(formattedDrafts)
                    onDraftsLoaded({drafts: formattedDrafts, currentMapId: currentMap});
                })
            } else {
                setDrafts([])
                onDraftsLoaded({drafts: [], currentMapId: currentMap})
            }
        })
    }

    useEffect(() => {
        setZonesTable(<BootstrapTable keyField='zoneId' data={zones} columns={zonesTableColumns} />)
    }, [drafts])

    const updateZone = (zone) => {
        axios.post('/geo/api/v1/zone/update', zone).then((response) => {
            loadZones()
        }).catch((error) => {
            alert(error.response.data)
        })
    }

    const zonesTableColumns = [
        {
            text: "ID",
            dataField: "zoneId",
            style: () => {
                return {
                    width: '30px'
                }
            },
        },
        {
            text: "Зона",
            dataField: "name",
            style: () => {
                return {
                    width: '200px',
                    verticalAlign: 'middle'
                }
            },
            formatter: (cell, row) => {
                return <EditableTextColumn
                    onSave={(e) => {
                        row.name = e;
                        updateZone(row)
                    }}
                    defaultValue={row.name}/>
            }
        },
        {
            text: "Родительская зона",
            dataField: "parentZoneId",
            style: () => {
                return {
                    width: '150px'
                }
            },
            formatter: (cell, row) => {
                return <EditableSelectColumn
                    options={zones}
                    onSave={(e) => {
                        row.parentZoneId = parseInt(e);
                        updateZone(row)
                    }}
                    defaultValue={row.parentZoneId}/>
            }
        },
        {
            text: "Группа зон",
            dataField: "groupName",
            style: () => {
                return {
                    width: '200px',
                    verticalAlign: 'middle'
                }
            },
            formatter: (cell, row) => {
                return <EditableTextColumn
                    onSave={(e) => {
                        row.groupName = e;
                        updateZone(row)
                    }}
                    defaultValue={row.groupName}/>
            }
        },
        {
            text: "Цвет",
            dataField: "color",
            style: () => {
                return {
                    width: '30px',
                    verticalAlign: 'middle'
                }
            },
            formatter: (cell, row) => {
                return <EditableColorColumn
                    onSave={(e) => {
                        row.color = e;
                        updateZone(row)
                    }}
                    defaultValue={row.color}/>
            }
        },
        {
            text: "",
            dataField: "isVisible",
            style: () => {
                return {
                    width: '30px',
                    verticalAlign: 'middle'
                }
            },
            formatter: (cell, row) => {
                return <FontAwesomeIcon
                    className={styles.ActionIcon}
                    onClick={(e) => {
                        row.isVisible = !row.isVisible;
                        updateZone(row)
                    }}
                    icon={row.isVisible ? faEye : faEyeSlash}/>
            }
        },
        {
            text: "",
            dataField: "isPublished",
            style: () => {
                return {
                    verticalAlign: 'middle',
                    width: '100px',
                }
            },
            formatter: (cell, row) => {
                let has = false;
                const draftsByZone = _.filter(drafts, {zoneId: row.zoneId})
                draftsByZone.forEach((draft) => {
                    if (draft.isPublished === false) {
                        has = true;
                    }
                })
                return (
                    <Container className={columnStyles.EditableTextColumnContainer}>
                        <Row className={columnStyles.EditableTextColumnRow}>
                            <Col md={1} className={columnStyles.EditableTextColumnIcon}>
                                <FontAwesomeIcon onClick={(e) => onStartEdit(row)}
                                                 className={columnStyles.Icon} icon={faEdit}/>
                            </Col>
                            <Col md={11} className={columnStyles.EditableTextColumnValue}>
                                <div className={classNames({[styles.DraftIsPublished]: !has, [styles.DraftIsNotPublished]: has})}>
                                    {has ? "Изменения не опубликованы" : "Изменения опубликованы"}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )
            }
        },
        {
            text: "",
            dataField: "delete",
            style: () => {
                return {
                    width: '30px',
                    verticalAlign: 'middle'
                }
            },
            formatter: (cell, row) => {
                return <FontAwesomeIcon
                    className={styles.CloseIcon}
                    onClick={(e) => {
                        // eslint-disable-next-line no-restricted-globals
                        if (confirm("Удалить зону?")) {
                            axios.post('/geo/api/v1/zone/delete', {zoneId: row.zoneId}).then((response) => {
                                onDelete(row.zoneId)
                            })
                        }
                    }}
                    icon={faClose}/>
            }
        }
    ]


    return (
        <div>
            <div className={styles.Label}><label>Рабочие зоны</label></div>
            <Tabs activeKey={currentMap}
                  onSelect={(e) => {
                      setCurrentMap(parseInt(e))
                  }} className="mb-3">
                {maps.map((map) => (
                    <Tab title={map.name} key={map.mapId} eventKey={map.mapId}>
                        {zonesTable}
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
}

export default ZonesTable;