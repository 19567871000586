import balloonStyles from "../../balloon/Balloon.module.css";
import styles from "./Content.module.css"
import classNames from "classnames";
import {Button} from "react-bootstrap";
import {addToRoute, selectDrivesInRoutes} from "../../route/routeSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveItem} from "../../../employee/employeeSlice";
import _ from "lodash";

function TransportationContent({drive, onOpenTransportation}) {
    const dispatch = useDispatch();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const activeEmployee = useSelector(selectActiveItem);
    const drivesInRoutes = useSelector(selectDrivesInRoutes);
    const driveHasInRoute = _.find(drivesInRoutes, (item) => item.drive.id === drive.id)

    const btnTemplate = activeEmployee === null && drive.courierId === null ?
        <Button variant={"primary"} size={"sm"}>Выберите курьера</Button> :
        typeof driveHasInRoute !== "undefined" ? null :
            <Button variant={"success"} size={"sm"}
                    onClick={(e) => {
                        dispatch(addToRoute({courier: activeEmployee, drive: drive}))
                    }}>
                Добавить в маршрут
            </Button>

    return (
        <div className={classNames({[balloonStyles.BalloonBody]: true, [styles.ContentBody]: true})}>
            <span className={classNames({'label': true, [balloonStyles.LabelInBalloon]: true, [drive.label]: true})}>
                {drive.type}
            </span>
            <br/>
            <strong>Заказ</strong>:&nbsp;
            <a href={baseUrl + '/drycleaning/' + drive.orderNumber} target="_blank" rel="noreferrer">
                {drive.orderNumber}
            </a>
            <br/>
            <strong>Статус выезда</strong>: <span className={classNames({'label': true, [drive.statusLabel]: true})}>{drive.status}</span><br/>
            <strong>Номер выезда</strong>: {drive.id} <br/>
            <strong>Дата выезда</strong>: {drive.date} <br/>
            <strong>Курьер</strong>: {drive.courierName} <br/>
            <strong>Адрес</strong>: {drive.address} <br/>
            <strong>C фабрики</strong>: {drive.transportation.startWayPoint.name} <br/>
            <strong>На фабрику</strong>: {drive.transportation.finishWayPoint.name} <br/>
            <strong>№ транспортировки</strong>: <a onClick={(e) => onOpenTransportation(drive)} href="#">{drive.transportation.id}</a><br/><br/>
            {btnTemplate}
        </div>
    );
}

export default TransportationContent;