import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchDrives, fetchWaypoints, fetchZones} from "./mapObjectAPI";
import _ from "lodash";

const initialState = {
    drives: [],
    waypoints: [],
    zones: [],
    geoObjectsInInfoWindow: [],
    loadDrivesInProcess: false
};

export const loadDrivesAsync = createAsyncThunk(
    'drycleaning/drives',
    async (params= {}) => {
        const response = await fetchDrives(params);
        return response.data;
    }
);

export const loadWaypointsAsync = createAsyncThunk(
    'drycleaning/waypoints',
    async (params= {}) => {
        const response = await fetchWaypoints(params);
        return response.data;
    }
);

export const loadZonesAsync = createAsyncThunk(
    'drycleaning/zones',
    async (params= {}) => {
        const response = await fetchZones(params);
        return response.data;
    }
);

export const drycleaningMapObjectSlice = createSlice({
    name: 'list',
    initialState,
    reducers: {
        clearDrives(state) {
            state.drives = [];
        },
        clearWaypoints(state) {
            state.waypoints = [];
        },
        clearZones(state) {
            state.zones = [];
        },
        loadGeoObjectsInInfo(state, action) {
            state.geoObjectsInInfoWindow = action.payload;
        },
        removeDrive(state, action) {
            state.drives = _.without(state.drives, _.find(state.drives, (drive) => (
                drive.id === action.payload.id
            )));
            state.geoObjectsInInfoWindow = _.without(state.geoObjectsInInfoWindow, _.find(state.geoObjectsInInfoWindow, (geoObject) => (
                geoObject.id === action.payload.id && !geoObject.isTransportation && !geoObject.isWaypoint
            )));
        },
        updateDrive(state, action) {
            const driveInState = _.find(state.drives, (drive) => (
                drive.id === action.payload.drive.id
            ))
            const driveInInfoWindow = _.find(state.geoObjectsInInfoWindow, (geoObject) => (
                geoObject.id === action.payload.drive.id && !geoObject.isWaypoint
            ))

            if (typeof driveInState !== "undefined") {
                const date = action.payload.attributes.date;
                if (typeof date !== "undefined" && date !== driveInState.date) {
                    state.drives = _.without(state.drives, driveInState);
                    state.geoObjectsInInfoWindow = _.without(state.geoObjectsInInfoWindow, driveInInfoWindow);
                }
                _.each(action.payload.attributes, (value, key) => {
                    driveInState[key] = value;
                });
            }

            if (typeof driveInInfoWindow !== "undefined") {
                _.each(action.payload.attributes, (value, key) => {
                    driveInInfoWindow[key] = value;
                })
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadDrivesAsync.pending, (state, action) => {
                state.loadDrivesInProcess = true;
            });
        builder
            .addCase(loadDrivesAsync.rejected, (state, action) => {
                state.loadDrivesInProcess = false;
            });
        builder
            .addCase(loadDrivesAsync.fulfilled, (state, action) => {
                state.loadDrivesInProcess = false;
                state.drives = action.payload;
            });
        builder
            .addCase(loadWaypointsAsync.fulfilled, (state, action) => {
                state.waypoints = action.payload;
            });
        builder
            .addCase(loadZonesAsync.fulfilled, (state, action) => {
                state.zones = action.payload;
            });
    },
});

export const { clearWaypoints, clearZones, clearDrives, loadGeoObjectsInInfo, updateDrive, removeDrive} = drycleaningMapObjectSlice.actions;

export const selectDrives = (state) => state.drycleaningMapObject.drives;
export const selectWaypoints = (state) => state.drycleaningMapObject.waypoints;
export const selectZones = (state) => state.drycleaningMapObject.zones;
export const selectLoadDrivesInProcess = (state) => state.drycleaningMapObject.loadDrivesInProcess;
export const selectGeoObjectsInInfoWindow = (state) => state.drycleaningMapObject.geoObjectsInInfoWindow;

export default drycleaningMapObjectSlice.reducer;