import RouteBody from "../route/RouteBody";
import styles from './Employee.module.css'
import {useSelector} from "react-redux";
import {selectDrivesInRoutes} from "../route/routeSlice";
import _ from "lodash";
import classNames from "classnames";
import {courierIsLate} from "../utils/time";

const EmployeeBody = ({employee, isActive, mapRef}) => {

    const drivesInRoutes = useSelector(selectDrivesInRoutes);
    const filteredDrivesInRoutes = _.filter(drivesInRoutes, {courier: employee.id})
    const lateDrives = _.filter(filteredDrivesInRoutes, (driveInRoute) => courierIsLate(driveInRoute))
    const completedDrives = _.filter(filteredDrivesInRoutes, (driveInRoute) => {
        return driveInRoute.drive.allowedChange === false;
    })

    return (
        <div className={classNames({
            [styles.RouteShortInfo]: true
        })}>
            <div>
                <div className={styles.WorkTime}>
                    <strong>{employee.schedule}:</strong>&nbsp;
                    {employee.workTime.time_from} - {employee.workTime.time_to}
                </div>
                <div className={styles.CountDrives}>
                    <strong>Выезды:</strong>&nbsp;
                    {completedDrives.length} / {filteredDrivesInRoutes.length}
                </div>
                <div className="clearfix"/>
                <div className={styles.RouteConfirm}>
                    <strong>Маршрут:</strong>&nbsp;
                    <span className={classNames({
                        [styles.RouteIsConfirmed]: filteredDrivesInRoutes.length && filteredDrivesInRoutes[0].confirmed,
                        [styles.RouteIsNotConfirmed]: filteredDrivesInRoutes.length && !filteredDrivesInRoutes[0].confirmed
                    })}>
                        {!filteredDrivesInRoutes.length ? 'отсутствует' : filteredDrivesInRoutes[0]?.confirmed ? 'принят' : 'не принят'}
                    </span>
                </div>
                <div className={classNames({
                    [styles.LateDrivesWrap]: true,
                    [styles.HasLateDrives]: lateDrives.length > 0
                })}>
                    {filteredDrivesInRoutes.length ? lateDrives.length > 0 ? 'Опаздывает: ' + lateDrives.length : 'Не опаздывает' : null}
                </div>
            </div>
            {isActive ? <RouteBody mapRef={mapRef} employee={employee}/> : null}
        </div>
    )
}

export default EmployeeBody;