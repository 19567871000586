import React from 'react';
import axios from 'axios';
import { Input } from "@material-ui/core";

import styles from './AddressInput.module.css'

import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";

import { useCity } from "../../../app/utils/use";
import {
    CITY_LIST
} from "../../../app/utils/const";
import {
    findCityCenter
} from "../../../app/utils/latLng";

import jsonp from 'jsonp';

const YandexSuggestInput = ({ addressSearchInputIsShown, addAddressSearchResultPlacemarkFunc, removeAddressSearchResultPlacemarkFunc }) => {

    const SUGGEST_SERVER = 'https://suggest-maps.yandex.ru/suggest-geo';

    const SUGGEST_DEFAULT_PARAMS = [
        'lang=ru-RU',
        'search_type=all',
        'v=9',
        'fullpath=1',
        'format=json',
        'results=10',
    ];

    const [addressSearchString, setAddressSearchString] = useState("");
    const [addressInputValue, setAddressInputValue] = useState("");
    const [suggestItems, setSuggestItems] = useState([]);
    const [currentCityCenter, setCityCenter] = useState({});

    const [addressGeocodeData, setAddressGeocodeData] = useState({});

    const currentCityId = useCity();

    useEffect(() => {
        setCityCenter(findCityCenter(CITY_LIST, currentCityId));
        setSuggestItems([]);
        setAddressInputValue("");
        setAddressSearchString("");
    }, [currentCityId]);

    useEffect(() => {
        const timeOutId = setTimeout(() => suggestAddress(addressSearchString), 500);
        return () => clearTimeout(timeOutId);
    }, [addressSearchString]);

    useEffect(() => {
        if (addressSearchInputIsShown === false) {
            setSuggestItems([]);
            setAddressGeocodeData({});
            setAddressInputValue("");
            setAddressSearchString("");
        }
    }, [addressSearchInputIsShown]);

    useEffect(() => {

        if (addressGeocodeData && addressGeocodeData.lat && addressGeocodeData.lng ) {
            addAddressSearchResultPlacemarkFunc({ lat: addressGeocodeData.lat, lng: addressGeocodeData.lng, title: addressInputValue } );
        } else {
            removeAddressSearchResultPlacemarkFunc();
        }
    }, [addressGeocodeData]);

    const addressInputOnChangeHandle = (e) => {
        let searchVal = e.target.value;
        setAddressInputValue(searchVal);
        setAddressSearchString(searchVal);
    }

    const suggestAddress = (searchVal) => {
        if (Object.keys(addressGeocodeData).length > 0) {
            setAddressGeocodeData({});
        }
        if (suggestItems.length > 0) {
            setSuggestItems([]);
        }


        if (searchVal.length > 2) {
            if (!currentCityCenter || !currentCityCenter.lng || !currentCityCenter.lat) {
                alert('Проблема определения города. Поиск по адресу невозможен');
                return false;
            }

            let params = SUGGEST_DEFAULT_PARAMS.slice(0);
            params.push('part=' + searchVal);
            params.push('ll=' + ([currentCityCenter.lng, currentCityCenter.lat]).join(','));

            jsonp(SUGGEST_SERVER + '?' + params.join('&'), null, (err, data) => {
                setSuggestResults(data.results);
            });
        }
    }

    const setSuggestResults = (results) => {
        let resultsFiltered = results.filter((result) => {
            if (result.type !== "toponym") {
                return false;
            }
            // дистанция не более 70 километров
            if (result.distance.value >  70000) {
                return false;
            }
            return true;
        });
        setSuggestItems(resultsFiltered);
    }

    const setSelectedSuggest = (selectedSuggest) => {
        setAddressInputValue(selectedSuggest.text);
        // запрос за координатами для маркера
        geocodeAddressAsync(selectedSuggest.text);

        setSuggestItems([]);
    }

    const geocodeAddressAsync = (addressString) => {
        if (addressString && addressString.length > 0) {
            axios.get('/google-api-cache/api/site/v1/geocode/lat-lng', { params: { address: addressString } }).then((response) => {
                if (response.status !== 200) {
                    console.error(response);
                    alert('Ошибка ответа сервера при запросе координат адреса');
                    return false;
                }
                response = response.data;
                if (response.success === true && response.data.lat && response.data.lng) {
                    setAddressGeocodeData({ lat: response.data.lat, lng: response.data.lng });
                } else {
                    console.error(response);
                    alert('Ошибка ответа сервера при запросе координат адреса');
                }
            }).catch((error) => {
                console.error(error);
                alert('Ошибка ответа сервера при запросе координат адреса');
            })
        }
    }

    if (addressSearchInputIsShown === false) {
        return "";
    }

    return (
    <div className={styles.AddressInputContainer}>
        <Input
            className={styles.AddressInput}
            value={addressInputValue}
            onChange={(e) => { addressInputOnChangeHandle(e) }}
        />

        {
            (suggestItems && suggestItems.length > 0)
                ?
                <SuggestItemsList
                    suggestItems={suggestItems}
                    setSelectedAddressFunction={(e) => setSelectedSuggest(e)}
                />
                : ""
        }
    </div>)
}

function SuggestItemsList(props) {

    const suggestItems = props.suggestItems.map((suggestItem, i) => {
        return (
            <li
                className={styles.SuggestItem}
                key={i}
                onClick={() => props.setSelectedAddressFunction(suggestItem)}
            >
                {suggestItem.text}
            </li>
        )
    });

    return (
        <div className={styles.SuggestItemsContainer}>
            <ul className={styles.SuggestItemsList}>
                {suggestItems}
            </ul>
        </div>
    );
}

export default YandexSuggestInput;