import DrivesCounter from "./DrivesCounter";

function DrivesWithoutZone() {
    return (
        <DrivesCounter
            apiUrl={'/lsystem/api/v1/without-zone-drives'}
            header={'Выезды без зоны'}
        />
    )
}

export default DrivesWithoutZone;