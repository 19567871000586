import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import balloonStyles from "../../balloon/Balloon.module.css";
import styles from "./Content.module.css"

function WaypointContent(props) {
    const waypoint = props.waypoint;
    const regions = Object.keys(waypoint.regions).map((id) => (
       <span
           style={{backgroundColor: waypoint.regions[id].color, marginRight: '5px'}}
           key={id}
           className={classNames({'label': true, 'label-primary': true})}>
           {waypoint.regions[id].name}
       </span>
    ));
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    return (
        <div className={classNames({[balloonStyles.BalloonBody]: true, [styles.ContentBody]: true})}>
            <span className={classNames({'label': true, 'label-success': true})}>
                {waypoint.name}
            </span>
            <a href={baseUrl + '/waypoint/dry-cleaning/view/' + waypoint.id}
               className={balloonStyles.EditIcon} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faPencilAlt}/>
            </a>
            <br/>
            <strong>Режим работы</strong>: {waypoint.time} <br/>
            <strong>Адрес</strong>: {waypoint.address} <br/>
            <strong>Телефон</strong>: {waypoint.phone} <br/>
            <strong>Контактное лицо</strong>: {waypoint.contactPersonName} <br/>
            {regions}
        </div>
    );
}

export default WaypointContent;