import classNames from "classnames";
import styles from './Balloon.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'

function WaypointBalloon(props) {
    const waypoint = props.waypoint;
    return (
        <div className={styles.BalloonBody}>
            <span className={classNames({'label': true, 'label-success': true})}>
                {waypoint.name}
            </span>
            <a href={'/waypoint/dry-cleaning/view/' + waypoint.id} className={styles.EditIcon} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faPencilAlt}/>
            </a>
            <br/>
            Режим работы - {waypoint.time} <br/>
        </div>
    );
}

export default WaypointBalloon;